import * as React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useAuthentication} from "common/Authentication";

const Help = ({helpHref}) => {
    const {t} = useTranslation();
    const {isLoggedIn} = useAuthentication();

    // Default Fallback ist der Key, wir setzen den Alternativ-Key deshalb jeweils auf den Leerstring
    const telephoneNumber = t('Index.contact.telefon.number.number', '');
    const email = t('Index.contact.email.to', '');

    return (
        <section className={"mt-lg-auto"}>
            <Container className={"px-3 px-lg-4 py-3"}>
                <Row>
                    <Col>
                        <Row className={"py-3 justify-content-xl-evenly footer-tile"}>
                            <Col xs={12} md={6} lg={6} xl={"auto"} className={"text-xl-center py-2 py-md-3"}>
                                <h2>
                                    <i className={"fal fa-fw fa-lg fa-question-circle"}/>
                                    &nbsp;
                                    {t('Index.help.contact')}
                                </h2>
                            </Col>
                            {telephoneNumber && telephoneNumber.trim() &&
                                <Col xs={12} md={6} lg={6} xl={"auto"} className={"text-xl-center py-2 py-md-3"}>
                                    <a href={'tel:' + telephoneNumber}>
                                        <i className={"fal fa-fw fa-lg fa-phone-alt"}/>
                                        &nbsp;
                                        {telephoneNumber}
                                    </a>
                                </Col>
                            }
                            {email && email.trim() &&
                                <Col xs={12} md={6} lg={6} xl={"auto"} className={"text-xl-center py-2 py-md-3"}>
                                    <a href={'mailto:' + email}>
                                        <i className={"fal fa-fw fa-lg fa-envelope"}/>
                                        &nbsp;
                                        {email}
                                    </a>
                                </Col>
                            }
                            {isLoggedIn &&
                                <Col xs={12} md={6} lg={6} xl={"auto"} className={"text-xl-center py-2 py-md-3"}>
                                    <a href={helpHref}>
                                        <i className={"fal fa-fw fa-lg fa-angle-double-right"}/>
                                        &nbsp;
                                        {t('Index.contact.help.text')}
                                    </a>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Help;