import React from "react";
import {Button, Card, Image} from "react-bootstrap";
import {Link} from "react-router-dom";


/**
 * Bootstrap-Card-Komponente wird in einem Anchor-Tag (Link) eingebettet. Es besteht aus einem Text, Button und Bild.
 *
 * @param {String} link - Router-Link
 * @param {String} cardTextClassName - CSS-Klasse für den Text
 * @param {String} cardText - Text
 * @param {String} buttonTitle - Button-Label
 * @param {String} cardImage - URL des Bilds
 * @return {JSX.Element}
 * @constructor
 */
const NavigationButtonCard = ({link, cardTextClassName, cardText, buttonTitle, cardImage}) => {
    return (
        <Card>
            <Card.Body>
                <Card.Text className={cardTextClassName}>
                    {cardText}
                    <Button to={link} as={Link} variant={"primary"} className={"stretched-link"}>
                        {buttonTitle}
                    </Button>
                </Card.Text>
            </Card.Body>
            <Image src={cardImage}/>
        </Card>
    );
};

export default NavigationButtonCard;