import * as React from 'react';
import {useEffect, useState} from "react";
import Api from "common/Api";
import {AlertApiError, LinksAndNewsSection, LoadingIndicator, TemplateAndPublicationSection} from "components"

/**
 * Startseite
 *
 * @param {Link} homePageLink
 * @returns {JSX.Element}
 * @constructor
 */
const HomePage = ({homePageLink}) => {
    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [homePageModel, setHomePageModel] = useState(null);

    useEffect(() => {
        setIsLoading(true);

        if (homePageLink) {
            Api.get(homePageLink)
                .then(model => {
                    setHomePageModel(model);
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }
    }, [homePageLink]);

    if (apiError) {
        return <AlertApiError apiError={apiError}/>
    }

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    const templateModel = {
        templateSearchLink: homePageModel?.getLink('template-search'),
        recentlyUsedTemplateLink: homePageModel?.getLink('recently-used-template'),
        homepageTemplatesImageFile: homePageModel?.get('systemSettingsHomepageImagesModel') ? homePageModel?.get('systemSettingsHomepageImagesModel').homepageTemplatesImageFile : null
    };

    const publicationModel = {
        publicationSearchLink: homePageModel?.getLink('publication-search'),
        recentlyUsedPublicationLink: homePageModel?.getLink('recently-used-publication'),
        homepagePublicationsImageFile: homePageModel?.get('systemSettingsHomepageImagesModel') ? homePageModel?.get('systemSettingsHomepageImagesModel').homepagePublicationsImageFile : null
    };

    const linksModel = {
        links: {
            newsletterLink: homePageModel?.getLink('news-letter'),
            editorialLink: homePageModel?.getLink('editorial'),
            visitingCardLink: homePageModel?.getLink('visiting-card'),
            templatesEventDetailsLink: homePageModel?.getLink('templates-event-details'),
            imagePoolLink: homePageModel?.getLink('image-pool'),
            spreadPublicationSearchLink: homePageModel?.getLink('spread-publication-search'),
        },
        systemSettingsHomepageImagesModel: homePageModel?.get('systemSettingsHomepageImagesModel'),
        unreadSpreadPublicationsCount: homePageModel?.get('unreadSpreadPublicationsCount')
    }

    const newsModel = {
        newsLink: homePageModel?.getLink('news'),
        lastNewsLink: homePageModel?.getLink('last-news'),
        homepageNewsImageFile: homePageModel?.get('systemSettingsHomepageImagesModel') ? homePageModel?.get('systemSettingsHomepageImagesModel').homepageNewsImageFile : null
    };

    return (
        <>
            <TemplateAndPublicationSection templateModel={templateModel}
                                           publicationModel={publicationModel}
            />
            <LinksAndNewsSection newsModel={newsModel}
                                 linksModel={linksModel}
            />
        </>
    );
};

export default HomePage;