import React from "react";
import {Form, Nav} from "react-bootstrap";
import FilterItem from "./FilterItem";

/**
 * Filtergruppe welche mehrere Filtereinträge mit einem Titel darstellt.
 *
 * @param {String} title - Titel
 * @param {String} titleClassName - CSS Klasse für den Titel (optional)
 * @param {Array<{label: String, status: String}>} items - Einträge nach denen gefiltert werden kann
 * @param {Function} onChange - Callback, erhält als Parameter alle Einträge mit Status
 * @returns {JSX.Element}
 * @constructor
 */
const FilterGroup = ({title, titleClassName, items, onChange}) => {

    const handleChange = (index, status) => {
        const newItems = [...items];
        newItems[index].status = status;

        onChange(newItems);
    };

    return (
        <Form.Group className={"mb-3"}>
            <Form.Floating htmlFor={"filterOptions"} className={titleClassName}>
                {title}
            </Form.Floating>
            <Form.Floating
                id={"filterOptions"}
            >
                <Nav as={"ul"} className={"flex-column filter"}>
                    {Array.isArray(items) && items.map((item, index) =>
                        <FilterItem
                            key={index}
                            label={item.label}
                            status={item.status}
                            disabled={item.disabled}
                            onChange={status => handleChange(index, status)}
                        />
                    )}
                </Nav>
            </Form.Floating>
        </Form.Group>
    );
};

export default FilterGroup;