import Sort from "common/Sort";
import {
    AlertApiError,
    SortSelect,
    TemplateSearchAndFilter,
    TemplateSearchResultPage,
    useRepresentationModel
} from "components";
import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

const TemplateSearch = () => {
    const {representationModel, isLoading, apiError} = useRepresentationModel();
    const [templateFilters, setTemplateFilters] = useState({
        searchText: null,
        contentTypes: [],
        designIds: [],
        pubTypeIds: []
    });
    const [sortKey, setSortKey] = useState(Sort.TEMPLATE_DEFAULT);

    if (isLoading) {
        return null; // Kinder-Komponenten zeigen schon eine LoadingIndikator
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const templatesSearchPageModel = {
        templateSearchPageLink: representationModel?.getLink('get-template-search-page')
    }

    const templateSearchFiltersLink = representationModel?.getLink('get-template-search-filters');

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"result-list"}>
                            <Col xs={12} lg={4} xl={3} className={"filter-col"}>
                                <TemplateSearchAndFilter
                                    onChange={setTemplateFilters}
                                    templateSearchFiltersLink={templateSearchFiltersLink}
                                />
                            </Col>
                            <Col xs={12} lg={8} xl={9} className={"result-col"}>
                                <SortSelect
                                    options={Sort.sortKeys()}
                                    value={sortKey}
                                    onChange={setSortKey}
                                />
                                <TemplateSearchResultPage
                                    templatesSearchPageModel={templatesSearchPageModel}
                                    templateFilters={templateFilters}
                                    sort={Sort.sortValueOf(sortKey)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TemplateSearch;