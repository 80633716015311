/**
 * Implementiert ein Modell, um Umgang mit der Ansichtsmodus der verteilten Publikationen zu vereinfachen.
 */
export default class SpreadPublicationViewMode {

    /**
     * Ansichtsmodus.
     *
     * @type {{MARK_READ: string, DEFAULT: string}}
     */
    static MODE = {
        DEFAULT: 'DEFAULT',
        MARK_READ: 'MARK_READ'
    }
}