import React, {useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SearchForm} from "components";

/**
 * Dialog zur Auswahl einer Organisation
 *
 * @param {Array<{key: Number, label: String, internalNumber: String,  canBeDeselected: boolean}>} organizations - zur Auswahl stehende Organisationen
 * @param {?Object} initialOrganization - initial ausgewählte Organisation
 * @param {Function} onSelect - Callback, erhält als Parameter die ausgewählte Organisation
 * @param {Function} onClose - Callback, ohne Parameter
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationSelectDialog = ({organizations, initialOrganization, onSelect, onClose}) => {
    const {t} = useTranslation();

    const [searchText, setSearchText] = useState("");
    const [currentOrganization, setCurrentOrganization] = useState(initialOrganization);

    const organizationNameContainsSearchText = (organization) =>
        organization.label != null &&
        organization.label.toLowerCase().includes(searchText.toLowerCase());

    const toOrganizationRow = (organization) =>
        <tr
            key={organization.key}
            className={organization === currentOrganization ? 'active' : null}
            onClick={() => setCurrentOrganization(organization)}
        >
            <td>{organization.label}</td>
            <td>{organization.internalNumber}</td>
        </tr>;

    return (
        <Modal className={"organization"} show={true} centered={true} scrollable={true} fullscreen={"lg-down"} size={"lg"} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title as={"h1"}>{t('organization.select.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchForm
                    onChange={setSearchText}
                    searchText={searchText}
                    searchFieldPlaceHolder={t('organization.select.search.placeholder')}
                />
                <Table className="table-hover organization selectable single-select mt-4" id="organizationList">
                    <thead>
                        <tr>
                            <th>{t('organization.select.table.organization')}</th>
                            <th>{t('organization.select.table.internal.number')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(organizations) && organizations
                            .filter(organizationNameContainsSearchText)
                            .map(toOrganizationRow)
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} disabled={currentOrganization === null} onClick={() => onSelect(currentOrganization)}>
                    {t('organization.select.button.select')}
                </Button>
                <Button variant={"secondary"} onClick={() => onClose()}>
                    {t('organization.select.button.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrganizationSelectDialog;