/**
 * Modell für Seitenabschnitt.
 */
export default class PageRequest {

    /**
     * Seitennummer.
     *
     * @type {number}
     */
    page = 0;

    /**
     * Anzahl der Einträge auf der Seite.
     *
     * @type {number}
     */
    size = 9;

    /**
     * Sortierung.
     *
     * @type {object}
     */
    sort = null;

    constructor(currentPage, pageSize, sort) {
        this.page = currentPage > 0 ? currentPage - 1 : 0; // Die Seiten aus dem Backend sind 0-basiert.
        this.size = pageSize;
        this.sort = this._toSortQueryParam(sort);
    }

    toModel() {
        return {
            page: this.page,
            size: this.size,
            sort: this.sort
        };
    }

    _toSortQueryParam(sort) {
        if (sort) {
            return Object.entries(sort)
                .reduce((str, [p, val]) => {
                    return `${str}${p},${val.direction}&`;
                }, '')
                .slice(0, -1); // Letzte &-Zeichen abschneiden
        } else {
            return null;
        }
    }
}