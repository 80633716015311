import React from "react";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";

/**
 * Implementiert eine Komponente mit einem Link-Button um die Toolbox verlassen zu können
 *
 * @param {Function} exitButtonClicked - Callback zum Verlassen der aktuellen Toolbox
 * @returns {JSX.Element}
 * @constructor
 */
const ToolboxExit = ({exitButtonClicked}) => {
    const {t} = useTranslation();
    return (
        <Col xs={"auto"}>
            <button type={"button"} className={"btn btn-link mb-4 px-0"} onClick={exitButtonClicked}>
                <i className={"far fa-angle-left me-2"}></i>{t('exit.toolbox')}
            </button>
        </Col>
    )
}
export default ToolboxExit;