import React, {useEffect, useState} from "react";
import {default as BootstrapPagination} from 'react-bootstrap/Pagination';

const Pagination = (props) => {

    const [paginationItems, setPaginationItems] = useState([]);

    useEffect(() => {
        let totalPages = parseInt(props.totalPages);
        let currentPage = parseInt(props.currentPage);

        let paginationItem = [];
        if (totalPages > 1) {
            if (totalPages <= 9) {
                let i = 1;
                while (i <= totalPages) {
                    paginationItem.push(i);
                    i++;
                }
            } else {
                if (currentPage <= 5) {
                    paginationItem = [
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        "",
                        totalPages
                    ];
                } else if (totalPages - currentPage <= 4) {
                    paginationItem = [
                        1,
                        "",
                        totalPages - 7,
                        totalPages - 6,
                        totalPages - 5,
                        totalPages - 4,
                        totalPages - 3,
                        totalPages - 2,
                        totalPages - 1,
                        totalPages
                    ];
                } else {
                    paginationItem = [
                        1,
                        "",
                        currentPage - 3,
                        currentPage - 2,
                        currentPage - 1,
                        currentPage,
                        currentPage + 1,
                        currentPage + 2,
                        currentPage + 3,
                        "",
                        totalPages
                    ];
                }
            }
        }
        setPaginationItems(paginationItem);
    }, [props.currentPage, props.totalPages]);

    const onFirst = props.currentPage === 1
        ? () => {
        }
        : () => {
            props.onPageChange(1);
        };

    const onPrevious = props.currentPage === 1
        ? () => {
        }
        : () => {
            props.onPageChange(props.currentPage - 1);
        };

    const onPage = (paginationItem) => props.currentPage === paginationItem
        ? () => {
        }
        : () => {
            props.onPageChange(paginationItem);
        };

    const onNext = (paginationItem) => props.currentPage === paginationItem
        ? () => {
        }
        : () => {
            props.onPageChange(props.currentPage + 1);
        };

    const onLast = (paginationItem) => props.currentPage === paginationItem
        ? () => {
        }
        : () => {
            props.onPageChange(paginationItem);
        };

    return (
        <BootstrapPagination className={"justify-content-center"}>
            {paginationItems.map((item, index) => {
                const toReturn = [];

                if (index === 0) {
                    toReturn.push(<BootstrapPagination.First key={"firstpage"} onClick={onFirst}/>);
                    toReturn.push(<BootstrapPagination.Prev key={"prevpage"} onClick={onPrevious}/>);
                }

                if (item === "") {
                    toReturn.push(<BootstrapPagination.Ellipsis key={index}/>);
                } else {
                    toReturn.push(
                        <BootstrapPagination.Item
                            key={index}
                            active={props.currentPage === item}
                            onClick={onPage(item)}
                        >
                            {item}
                        </BootstrapPagination.Item>
                    );
                }

                if (index === paginationItems.length - 1) {
                    toReturn.push(<BootstrapPagination.Next key={"nextpage"} onClick={onNext(item)}/>);
                    toReturn.push(<BootstrapPagination.Last key={"lastpage"} onClick={onLast(item)}/>);
                }

                return toReturn;
            })}
        </BootstrapPagination>
    );
};

export default Pagination;