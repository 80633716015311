/**
 * Implementiert ein Modell, um Umgang mit der Ansichtsmodus der Publikationen zu vereinfachen
 */
export default class PublicationViewMode {

    /**
     * Ansichtsmodus
     *
     * @type {{DEFAULT: string, DOWNLOAD: string, ARCHIVE: string, DELETE: string}}
     */
    static MODE = {
        DEFAULT: 'DEFAULT',
        DOWNLOAD: 'DOWNLOAD',
        ARCHIVE: 'ARCHIVE',
        DELETE: 'DELETE'
    }
}