import * as React from 'react';

import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet-async";


const MetaData = ({metaDataModel}) => {
    const {t} = useTranslation();

    return (
     <Helmet>
         {metaDataModel.projectFontCssRelativeUrl &&
            <link rel={"stylesheet"} type={"text/css"} href={window.location.origin + metaDataModel.projectFontCssRelativeUrl} />
         }
         {metaDataModel.projectIconFontCssRelativeUrl &&
             <link rel={"stylesheet"} type={"text/css"} href={window.location.origin + metaDataModel.projectIconFontCssRelativeUrl} />
         }
         <title>{t('application.title')}</title>
     </Helmet>
    );
};

export default MetaData;