import {
    AlertApiError,
    LoadingIndicator,
    NoResults,
    PublicationSearchTileView,
    SpreadPublicationListView,
    ToolboxExit
} from "components";
import {Button, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Api from "common/Api";
import Modal from 'react-bootstrap/Modal';

/**
 * Modus zum Markieren der verteilten Publikationen als benutzt.
 *
 * @param spreadPublicationSearchPageRepresentationModels - Darstellungsmodelle der verteilten Publikationen
 * @param {View} view - Ansicht
 * @param spreadPublicationsMarkAsReadLink - Link, um ausgewählte verteilte Publikationen, als benutzt zu markieren
 * @param {Function} reloadSpreadPublications - Callback zum Verlassen der Aktualisierung der Liste
 * @param {Function} exitModeButtonClicked - Callback zum Verlassen dem aktuellen Ansichtsmodus
 * @returns {JSX.Element}
 * @constructor
 */
const SpreadPublicationMarkReadViewMode = ({
                                               spreadPublicationSearchPageRepresentationModels,
                                               view,
                                               spreadPublicationsMarkAsReadLink,
                                               reloadSpreadPublications,
                                               exitModeButtonClicked
                                           }) => {
    const {t} = useTranslation();

    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedSpreadPublicationIds, setSelectedSpreadPublicationIds] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const _selectSpreadPublication = (spreadPublicationSearchPageRepresentationModel) => {
        let selection = [...selectedSpreadPublicationIds];

        const index = selection.indexOf(spreadPublicationSearchPageRepresentationModel.get('spreadPublicationId'));
        if (index >= 0) {
            selection = [
                ...selectedSpreadPublicationIds.slice(0, index),
                ...selectedSpreadPublicationIds.slice(index + 1)
            ];
        } else {
            selection.push(spreadPublicationSearchPageRepresentationModel.get('spreadPublicationId'));
        }

        setSelectedSpreadPublicationIds(selection);
    };

    const _deselectAllSpreadPublications = () => {
        setSelectedSpreadPublicationIds([]);
    };

    const _selectAllSpreadPublications = () => {
        const selection = [];

        spreadPublicationSearchPageRepresentationModels.forEach(spreadPublicationSearchPageRepresentationModel => {
            selection.push(spreadPublicationSearchPageRepresentationModel.get('spreadPublicationId'));
        });

        setSelectedSpreadPublicationIds(selection);
    };

    const _markReadSpreadPublications = () => {
        if (spreadPublicationsMarkAsReadLink) {
            const spreadPublicationsMarkReadSpecification = {
                spreadPublicationIds: selectedSpreadPublicationIds
            };

            setIsLoading(true);

            Api.post(spreadPublicationsMarkAsReadLink, spreadPublicationsMarkReadSpecification)
                .then(() => {
                    reloadSpreadPublications();
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }
    };

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    return (
        <Row className={"px-4 py-5"}>
            <ToolboxExit exitButtonClicked={exitModeButtonClicked}/>
            {(!Array.isArray(spreadPublicationSearchPageRepresentationModels) || spreadPublicationSearchPageRepresentationModels.length === 0) ?
                <NoResults/> :
                <Col xs={12}>
                    {view.isTileView() ?
                        <PublicationSearchTileView
                            spreadPublicationSearchPageRepresentationModels={spreadPublicationSearchPageRepresentationModels}
                        /> :
                        <>
                            <p>
                                {t('spread.publication.search.view.list.mode.mark.read.text')}
                            </p>
                            <SpreadPublicationListView
                                spreadPublicationSearchPageRepresentationModels={spreadPublicationSearchPageRepresentationModels}
                                listView={view}
                                selectedSpreadPublicationIds={selectedSpreadPublicationIds}
                                handleSelect={_selectSpreadPublication}
                            />
                        </>
                    }
                </Col>
            }
            <Col xs={12}>
                <Row className={"g-0"}>
                    <ToolboxExit exitButtonClicked={exitModeButtonClicked}/>
                    {Array.isArray(spreadPublicationSearchPageRepresentationModels) && spreadPublicationSearchPageRepresentationModels.length > 0 &&
                        <Col className={"text-end"}>
                            <Button
                                variant={"primary"}
                                className={"mb-4"}
                                onClick={() => _deselectAllSpreadPublications()}
                            >
                                {t('button.deselect.all')}
                            </Button>
                            &nbsp;
                            <Button
                                variant={"primary"}
                                className={"mb-4"}
                                onClick={() => _selectAllSpreadPublications()}
                            >
                                {t('button.select.all')}
                            </Button>
                            &nbsp;
                            <Button
                                variant={"primary"}
                                className={"mb-4"}
                                onClick={() => handleShow()}
                            >
                                {t('button.mark.read')}
                            </Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {t('spread.publication.search.view.list.mode.mark.read.modal.confirm.title')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {t('spread.publication.search.view.list.mode.mark.read.modal.confirm.body')}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        {t('button.close')}
                                    </Button>
                                    <Button variant="primary" onClick={_markReadSpreadPublications}>
                                        {t('button.confirm')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
export default SpreadPublicationMarkReadViewMode;