import Api from "common/Api";
import Constants from "common/Constants";
import PageRequest from "common/PageRequest";
import {
    AlertApiError,
    LoadingIndicator,
    Pagination,
    PublicationSearchArchiveViewMode,
    PublicationSearchDefaultViewMode,
    PublicationSearchDeleteViewMode,
    PublicationSearchDownloadViewMode
} from "components";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import PublicationViewMode from "common/publication/PublicationViewMode";

const PublicationSearchPageView = ({
                                       publicationSearchPageModel,
                                       publicationFilters,
                                       sort,
                                       pageViewModel,
                                       exitModeButtonClicked
                                   }) => {
    const {pageSize, publicationViewMode, view} = pageViewModel;

    const [pageRepresentationModel, setPageRepresentationModel] = useState(null);
    const [currentPage, setCurrentPage] = useState(Constants.FIRST_PAGE_NUMBER); // Initial wird die erste Seite geladen

    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const loadPublications = () => {
        const queryParams = {};
        Object.assign(queryParams, publicationFilters);
        const pageRequest = new PageRequest(currentPage, pageSize, sort);
        Object.assign(queryParams, pageRequest.toModel());

        const publicationSearchPageLink = publicationSearchPageModel.publicationSearchPageLink.withQueryParams(queryParams);

        if (publicationSearchPageLink) {
            setIsLoading(true);
            Api.get(publicationSearchPageLink)
                .then(representationModel => setPageRepresentationModel(representationModel))
                .catch(apiError => setApiError(apiError))
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        setCurrentPage(Constants.FIRST_PAGE_NUMBER)
    }, [publicationFilters, sort]);

    useEffect(() => {
        loadPublications();
    }, [currentPage, pageSize, publicationFilters, publicationSearchPageModel.publicationSearchPageLink, sort]);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const searchResults = pageRepresentationModel?.getEmbeddedRepresentationModelsByName("publicationSearchPageRepresentationModels");
    const pageModel = pageRepresentationModel?.get('page');

    return (
        <>
            {(() => {
                switch (publicationViewMode) {
                    case PublicationViewMode.MODE.ARCHIVE:
                        return <PublicationSearchArchiveViewMode
                            publicationSearchPageRepresentationModels={searchResults}
                            view={view}
                            publicationsArchiveLink={publicationSearchPageModel.publicationsArchiveLink}
                            reloadPublications={loadPublications}
                            exitModeButtonClicked={exitModeButtonClicked}
                        />;
                    case PublicationViewMode.MODE.DELETE:
                        return <PublicationSearchDeleteViewMode
                            publicationSearchPageRepresentationModels={searchResults}
                            view={view}
                            publicationsDeleteLink={publicationSearchPageModel.publicationsDeleteLink}
                            reloadPublications={loadPublications}
                            exitModeButtonClicked={exitModeButtonClicked}
                        />;
                    case PublicationViewMode.MODE.DOWNLOAD:
                        return <PublicationSearchDownloadViewMode
                            publicationSearchPageRepresentationModels={searchResults}
                            view={view}
                            exitModeButtonClicked={exitModeButtonClicked}
                        />;
                    default:
                        return <PublicationSearchDefaultViewMode
                            publicationSearchPageRepresentationModels={searchResults}
                            view={view}
                        />;
                }
            })()}

            <Row className={"px-4"}>
                <Col>
                    <Pagination currentPage={currentPage}
                                totalPages={pageModel?.totalPages}
                                pageSize={pageSize}
                                onPageChange={page => setCurrentPage(page)}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PublicationSearchPageView;