import Api from "common/Api";
import Constants from "common/Constants";
import PageRequest from "common/PageRequest";
import {AlertApiError, LoadingIndicator, NoResults, Pagination, TemplateSearchResultPageCard} from "components";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";

const TemplateSearchResultPage = ({templatesSearchPageModel, templateFilters, sort}) => {

    const pageSize = Constants.DEFAULT_PAGE_SIZE;

    const [pageRepresentationModel, setPageRepresentationModel] = useState(null);
    const [currentPage, setCurrentPage] = useState(Constants.FIRST_PAGE_NUMBER); // Initial wird die erste Seite geladen

    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setCurrentPage(Constants.FIRST_PAGE_NUMBER)

    }, [templateFilters, sort]);

    useEffect(() => {
        const queryParams = {};
        Object.assign(queryParams, templateFilters);
        const pageRequest = new PageRequest(currentPage, pageSize, sort);
        Object.assign(queryParams, pageRequest.toModel())

        const templateSearchPageLink = templatesSearchPageModel.templateSearchPageLink.withQueryParams(queryParams);

        if (templateSearchPageLink) {
            setIsLoading(true);
            Api.get(templateSearchPageLink)
                .then(pagedRepresentationModel => {
                    setPageRepresentationModel(pagedRepresentationModel);
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }

    }, [currentPage, pageSize, sort, templateFilters, templatesSearchPageModel.templateSearchPageLink]);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const searchResults = pageRepresentationModel?.getEmbeddedRepresentationModelsByName("templateSearchPageRepresentationModels");
    const pageModel = pageRepresentationModel?.get('page');

    if (!Array.isArray(searchResults) || searchResults.length === 0) {
        return (
            <NoResults/>
        );
    }

    return (
        <>
            <Row xs={{cols: 1}} md={{cols: 2}} xl={{cols: 3}} className={"g-5 px-4 py-5"}>
                {searchResults.map(searchResult => (
                    <TemplateSearchResultPageCard
                        key={searchResult.get('templateId').toString()}
                        templateSearchPageRepresentationModel={searchResult}
                    />
                ))}
            </Row>
            <Row className={"px-4"}>
                <Col>
                    <Pagination currentPage={currentPage}
                                totalPages={pageModel?.totalPages}
                                pageSize={pageSize}
                                onPageChange={page => setCurrentPage(page)}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TemplateSearchResultPage;