import {HeaderNavbar} from "components";
import * as React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import UserContext from "common/user/UserContext";

const Header = ({headerModel}) => {

    return (
        <>
            <header>
                <Container>
                    <div className={"header-image"}>
                        <div className={"header-logo"}>
                            <Link to={"/"}>
                                <Image src={headerModel.logoImageFile} alt={""}/>
                            </Link>
                        </div>
                        <div className={"app-name d-none d-lg-block"}>
                            <Image src={headerModel.brandImageFile} alt={""}/>
                        </div>
                    </div>
                </Container>
            </header>
            <UserContext.Consumer>
                {currentUser => (
                    <HeaderNavbar
                        headerModel={headerModel}
                        currentUser={currentUser}
                    />
                )}
            </UserContext.Consumer>

            {(headerModel.maintenanceWarning || headerModel.maintenanceActive) &&
                <section>
                    <Container className={"px-3 px-lg-4 py-0"}>
                        <Row>
                            <Col>
                                <div className={"alert " + (headerModel.maintenanceActive ?
                                    "alert-danger" :
                                    headerModel.maintenanceWarning ? "alert-warning" : "")}
                                     role={"alert"}
                                >
                                    {headerModel.maintenanceMessage}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    );
};

export default Header;