import {
    AlertApiError,
    LoadingIndicator,
    SpreadPublicationInfo,
    useRepresentationModel
} from "components";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const SpreadPublicationDetails = () => {
    const {representationModel, isLoading, apiError} = useRepresentationModel();

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return <AlertApiError apiError={apiError}/>;
    }

    const productDetails = {
        designName: representationModel?.get('designName'),
        pubTypeName: representationModel?.get('pubTypeName'),
        paperSizeName: representationModel?.get('paperSizeName'),
        colorName: representationModel?.get('colorName'),
        contentType: representationModel?.get('contentType'),
        language: representationModel?.get('publicationLanguage')
    }

    const spreadPublicationData = {
        spreadPublicationId: representationModel?.get('spreadPublicationId'),
        publicationName: representationModel?.get('publicationName'),
        description: representationModel?.get('templateDescription'),
        rootContainerSectionPageCountMin: representationModel?.get('rootContainerSectionPageCountMin'),
        rootContainerSectionPageCountMax: representationModel?.get('rootContainerSectionPageCountMax'),
        bestPracticePdfLink: representationModel?.getLink('best-practice'),
        publicationThumbnailLink: representationModel?.getLink('publication-thumbnail'),
        printingPriceLink: representationModel?.getLink('printing-price'),
        publicationCreateLink: representationModel?.getLink('create-from-spread-publication'),
        productDetails: productDetails
    }

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"template-detail"}>
                            <Col>
                                <Row className={"template-name"}>
                                    <Col>
                                        <h1>{spreadPublicationData.publicationName}</h1>
                                    </Col>
                                </Row>
                                <SpreadPublicationInfo spreadPublicationData={spreadPublicationData}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default SpreadPublicationDetails;