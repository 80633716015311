import React from "react";
import {Nav} from "react-bootstrap";

/**
 * Ein Filtereintrag, welcher in einer FilterGroup benutzt wird.
 *
 * @param {String} label - angezeigter Name
 * @param {String} status - Status (Default ist inaktiv)
 * @param {Boolean} disabled - Darstellung (Default ist false)
 * @param {Function} onChange Callback welcher bei Änderungen den neuen Filterstatus zurückgibt
 * @returns {JSX.Element}
 * @constructor
 */
const FilterItem = ({label, status = "inactive", disabled = false, onChange}) => {

    const toggle = () => {
        switch (status) {
            case 'active':
                onChange('inactive');
                break;
            case 'inactive':
                onChange('active');
                break;
            case 'disabled':
                // wenn der Filter gesperrt ist den Status nicht ändern
                break;
            default:
                throw new Error("Unbekannter Status: " + status);
        }
    };

    return (
        <Nav.Item
            as={"li"}
            bsPrefix={`nav-item ${status + (disabled ? ' disabled' : '')}`}
            onClick={disabled ? null : toggle}
        >
            {label}
        </Nav.Item>
    );
};

export default FilterItem;