import Api from "common/Api";
import {AlertApiError, LoadingIndicator} from "components";
import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

/**
 * Formularfeld mit Dropdown zur Auswahl einer Organisation
 *
 * @param {String} organizationsLink - Link zur Ermittlung der verfügbaren Organisationen
 * @param {Function} onChange - Callback der bei Änderungen der Auswahl getriggert wird
 * @returns {JSX.Element}
 */
const OrganizationSelect = ({organizationsLink, onChange}) => {
    const {t} = useTranslation();

    const [organizationModels, setOrganizationModels] = useState([]);
    const [organizationId, setOrganizationId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        if (organizationsLink) {
            setIsLoading(true);
            Api.get(organizationsLink)
                .then(organizationRepresentationModel => {
                    const organizationModels = [];

                    const organizationRepresentationModels = organizationRepresentationModel
                            .getEmbeddedRepresentationModelsByName("organizationRepresentationModels");

                    organizationRepresentationModels.forEach(organization => {
                        organizationModels.push({
                            organizationId: organization.get('organizationId'),
                            organizationName: organization.get('organizationName')
                        });
                    });
                    setOrganizationModels(organizationModels);

                    if (Array.isArray(organizationModels) && organizationModels.length > 0) {
                        setOrganizationId(organizationModels[0].organizationId);
                        onChange(organizationModels[0].organizationId);
                    }
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }

    }, [organizationsLink]);

    const _handleChange = (event) => {
        setOrganizationId(event.target.value);
        onChange(event.target.value);
    }

    if ((!Array.isArray(organizationModels) || organizationModels.length === 0)) {
        return null;
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <>
            {apiError &&
                <AlertApiError apiError={apiError}/>
            }

            {organizationModels &&
                <Form.Group className={"mb-3"}>
                    <Form.Label htmlFor={"organization"}>
                        {t('template.details.dynamic.template.form.organization.label')}
                    </Form.Label>
                    <Form.Select
                        id={"organization"}
                        name={"organizationId"}
                        value={organizationId.toString()}
                        onChange={_handleChange}
                    >
                        {organizationModels.map(organization => (
                            <option key={organization.organizationId} value={organization.organizationId}>
                                {organization.organizationName}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            }
        </>
    );
};

export default OrganizationSelect;