import Constants from "./Constants";

/**
 * Implementiert ein Modell, um Umgang mit der Hateoas-Links zu vereinfachen
 */
export default class Link {
    url;
    pathParams;
    queryParams;

    constructor(link) {
        // @see https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
        this.url = new URL(link);
    }

    /**
     * Liefert den URL-Pfad
     *
     * @return {String}
     */
    getLinkPath() {
        return this.url.pathname;
    }

    /**
     * Liefert den URL-Pfad fürs Routing.
     * <p>
     *     Von dem eigentlichen URL-Pad wird der Präfix {Constants.API_PATH_PREFIX} entfernt und {Constants.HTML_SUFFIX} hinzugefügt.
     * </p>
     *
     * @return {String}
     */
    getLinkPathForRouting() {
        let linkPathForRouting = this.url.pathname.replace(Constants.API_PATH_PREFIX, '');
        return linkPathForRouting + Constants.HTML_SUFFIX;
    }

    /**
     * Liefert die komplette URL als String
     *
     * @return {String}
     */
    getHref() {
        return this.url.href;
    }

    /**
     * Fügt zu diesem Modell die Pfadparametern
     * <p>
     *     Die Pfad-Templates werden durch diesen Parametern nach Aufruf von folgenden Funktionen ersetzt:
     *     <ul>
     *         <li>toString()</li>
     *         <li>toPathAndQueryString()</li>
     *     </ul>
     * </p>
     *
     * @param params Pfadparameter
     * @return {Link}
     */
    withPathParam(params) {
        this.pathParams = params;
        return this;
    }

    /**
     * Fügt zu diesem Modell die Suchparametern
     * <p>
     *     Die Such-Templates werden durch diesen Parametern nach Aufruf von folgenden Funktionen ersetzt:
     *     <ul>
     *         <li>toString()</li>
     *         <li>toPathAndQueryString()</li>
     *     </ul>
     * </p>
     *
     * @param params Suchparameter
     * @return {Link}
     */
    withQueryParams(params) {
        this.queryParams = params;
        return this;
    }

    /**
     * Liefert die vollständige URL
     * <p>
     *     Die Pfad- und Such-Templates werden entsprechend den Parametern ersetzt
     * </p>
     *
     * @return {String}
     */
    toString() {
        let href = this.getHref();
        href = this._replacePathParams(href);
        href = this._replaceQueryParams(href);

        href = this._removeEmptyParams(href);

        return href;
    }

    /**
     * Liefert den vollständigen URL-Pfad und Query
     * <p>
     *     Die Pfad- und Such-Templates werden entsprechend den Parametern ersetzt
     * </p>
     *
     * @return {String}
     */
    toPathAndQueryString() {
        let href = this.toString();
        const url = new URL(href);
        return url.pathname + url.search;
    }

    _replacePathParams(href) {
        return this._replaceTemplates(href, this.pathParams);
    }

    _replaceQueryParams(href) {
        return this._replaceTemplates(href, this.queryParams);
    }

    _replaceTemplates(href, params) {
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                let value = params[key];
                if (Array.isArray(value)) {
                    if (value.length === 0) {
                        href = href.replace('{' + key + '}', '');
                    } else {
                        href = href.replace('{' + key + '}', value.toString());
                    }
                } else if (value) {
                    href = href.replace('{' + key + '}', value);
                } else {
                    href = href.replace('{' + key + '}', '');
                }
            }
        }
        return href;
    }

    _removeEmptyParams(href) {
        href = href.replace(/{(.|\n)*?}/g, '');

        return href;
    }
}