import axios from "axios";
import {createContext, useContext, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ApiError from "./ApiError";

const loginRequest = (username, password) => {
    return axios
        .post("/login", new URLSearchParams({username, password}))
        .catch(() => {
            throw ApiError.unexpectedError();
        });
};

const singleSignOnRequest = () => {
    // Request mittels unsichtbarer Form senden. Nach dem Submit findet ein automatischer Redirect statt.
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/oauth2/authorization/azure';
    form.style = 'display: none'

    document.body.appendChild(form);
    form.submit();
};

const logoutRequest = (logoutUrl) => {
    return axios.post(logoutUrl);
};

const AuthenticationContext = createContext({
    isLoggedIn: false,
    errorMessage: null,
    onLogin: () => { /*initial no-op*/
    },
    onSingleSignOn: () => { /*initial no-op*/
    },
    onLogout: () => { /*initial no-op*/
    }
});

const AuthenticationProvider = ({children, initialLoginStatus, maintenanceActive, onLogInStatusChange}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(initialLoginStatus);
    const [errorMessage, setErrorMessage] = useState(null);

    const redirectToOrigin = (redirect) => {
        // Wenn die Anmeldung erfolgreich ist, wird weitergeleitet ({@link AuthenticationResultHandler#onAuthenticationSuccess(...)})
        if (redirect && redirect !== "/") {
            window.location.replace(redirect);
        }
        const origin = location.state?.from?.pathname || '/index.html';
        navigate(origin);
    };

    const handleLogin = async (username, password, enableSubmitButtonCallback) => {
        const response = await loginRequest(username, password);
        const {success, redirect, expired, disabled, authenticationFail} = response.data;

        if (success) {
            await onLogInStatusChange();
            setIsLoggedIn(true);

            redirectToOrigin(redirect);
        } else if (disabled) {
            setErrorMessage(t('login.expired.disabled'));
        } else if (maintenanceActive) {
            setErrorMessage(t('login.maintenance.warning'));
        } else if (expired) {
            setErrorMessage(t('login.expired.notice'));
        } else if (authenticationFail) {
            setErrorMessage(t('login.not.available.notice'));
        } else {
            setErrorMessage(t('login.wrong.password'));
        }

        enableSubmitButtonCallback();
    };

    const handleSingleSignOn = async (enableSubmitButtonCallback) => {
        await singleSignOnRequest();

        // Beim Ein-Klick-Login findet im Erfolgsfall ein Redirect zur Startseite statt,
        // dabei wird der Login-Status automatisch aktualisiert

        enableSubmitButtonCallback();
    };

    const handleLogout = async (logoutLink) => {
        const logoutUrl = logoutLink.toPathAndQueryString();

        const response = await logoutRequest(logoutUrl);
        const {redirect} = response.data;

        window.location.replace(redirect || '/login.html');
        await onLogInStatusChange();
    };

    const value = {
        isLoggedIn,
        errorMessage,
        onLogin: handleLogin,
        onSingleSignOn: handleSingleSignOn,
        onLogout: handleLogout
    };

    return (
        <AuthenticationContext.Provider value={value}>
            {children}
        </AuthenticationContext.Provider>
    );
};

const useAuthentication = () => {
    return useContext(AuthenticationContext);
};

export {AuthenticationProvider, useAuthentication};