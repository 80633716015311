import * as React from 'react';
import {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import LoginForm from "./components/LoginForm";
import ResetPasswordForm from "./components/ResetPasswordForm";

/**
 * Loginseite
 *
 * @param {Boolean} supportsSingleSignOn - Ein-Klick Login anzeigen?
 * @returns {JSX.Element}
 * @constructor
 */
const LoginPage = ({supportsSingleSignOn}) => {
    const [showPasswordReset, setShowPasswordReset] = useState(false);

    const renderForm = () => {
        if (showPasswordReset) {
            return (
                <ResetPasswordForm
                    onCancel={() => setShowPasswordReset(false)}
                />
            );
        } else {
            return (
                <LoginForm
                    supportsSingleSignOn={supportsSingleSignOn}
                    onPasswordReset={() => setShowPasswordReset(true)}
                />
            );
        }
    };

    return (
        <section className="main-content my-lg-auto">
            <Container className="px-3 px-lg-4 py-3 py-lg-3">
                <Row className="justify-content-center">
                    <Col xs={12} lg={6} className={"login-box"}>
                        <Row className="g-0">
                            <Col className="p-4">
                                {renderForm()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default LoginPage;