import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useAuthentication} from "common/Authentication";
import {AlertApiError} from "components";

/**
 * Form für Login
 *
 * @param {Boolean} supportsSingleSignOn - Ein-Klick Login anzeigen?
 * @param {Function} onPasswordReset - Callback der bei Klick auf "Passwort vergessen" getriggert wird
 * @returns {JSX.Element}
 * @constructor
 */
const LoginForm = ({supportsSingleSignOn, onPasswordReset}) => {
    const {t} = useTranslation();

    const [fields, setFields] = useState({
        username: '',
        password: ''
    });
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [apiError, setApiError] = useState(null);

    const validateValue = (value) => {
        if (!value) {
            return t('error.field.required');
        }

        return null;
    };

    const validateForm = () => {
        const errorMessages = {};
        let isValid = true;

        for (const [key, value] of Object.entries(fields)) {
            const errorMessage = validateValue(value);
            if (errorMessage) {
                errorMessages[key] = errorMessage;
                isValid = false;
            }
        }

        setValidationErrors({...errorMessages});
        return isValid;
    };

    const changeField = (event) => {
        setFields(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };
    const handleResetPassword = (event) => {
        event.preventDefault();
        onPasswordReset();
    }

    const {errorMessage, onLogin, onSingleSignOn} = useAuthentication();
    const handleLogin = (event) => {
        event.preventDefault();

        const isValid = validateForm();

        if (isValid) {
            setIsSubmitDisabled(true);
            onLogin(fields.username, fields.password, () => setIsSubmitDisabled(false))
                .catch(apiError => setApiError(apiError));
        }
    };
    const handleSingleSignOn = () => {
        setIsSubmitDisabled(true);
        onSingleSignOn(() => setIsSubmitDisabled(false));
    }

    return (
        <>
            {apiError &&
                <AlertApiError apiError={apiError}/>
            }

            <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder={t('login.username')}
                        name="username"
                        value={fields.username}
                        onChange={changeField}
                        isInvalid={!!validationErrors.username || !!errorMessage}
                    />
                    <Form.Control.Feedback type={"invalid"}>
                        {/* allgemeine Fehlermeldungen werden am Benutzernamen angezeigt */}
                        {!!validationErrors.username ? validationErrors.username : errorMessage}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="password"
                        placeholder={t('login.password')}
                        name="password"
                        value={fields.password}
                        onChange={changeField}
                        isInvalid={!!validationErrors.password}
                    />
                    <Form.Control.Feedback type={"invalid"}>
                        {validationErrors.password}
                    </Form.Control.Feedback>
                    <Form.Text>
                        <a href={"#"} onClick={handleResetPassword}>{t('login.forgotten.password.short')}</a>
                    </Form.Text>
                </Form.Group>
                <Button
                    variant={supportsSingleSignOn ? "link" : "primary"}
                    type={"submit"}
                    className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0 float-none float-md-start float-lg-none float-xl-start"
                    disabled={isSubmitDisabled}
                >
                    {t('login.login')}
                </Button>
                {supportsSingleSignOn &&
                    <Button
                        variant={"primary"}
                        type={"button"}
                        className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0 float-none float-md-end float-lg-none float-xl-end"
                        onClick={handleSingleSignOn}
                        disabled={isSubmitDisabled}
                    >
                        {t('login.sso.button.long')}
                    </Button>
                }
            </Form>
        </>
    );
};

export default LoginForm;