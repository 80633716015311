import Api from "common/Api";
import React, {useState} from "react";
import {NavDropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {AlertApiError} from "../../index";

/**
 * Dropdown zur Auswahl einer Sprache
 *
 * @param {any} languageSelectModel
 * @returns {JSX.Element}
 */
const LanguageSelect = ({languageSelectModel}) => {
    const {t, i18n} = useTranslation();
    const [apiError, setApiError] = useState(null);

    const switchLanguage = (language) => {
        Api.post(languageSelectModel.switchLocaleLink, language)
            .then(() => i18n.changeLanguage(language))
            .catch(apiError => setApiError(apiError));
    };

    // wenn keine Sprachen zur Verfügung stehen nichts anzeigen
    if (!Array.isArray(languageSelectModel.languages) || languageSelectModel.languages.length === 0) {
        return null;
    }


    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const dropdownTitle = (
        <>
            <i className="fas fa-lg fa-globe fa-fw"/>&nbsp;{
                t(`Locale.${languageSelectModel.languages.includes(i18n.language) ? i18n.language : languageSelectModel.selectedLanguage}`)
            }
        </>
    );
    return (
        <NavDropdown title={dropdownTitle} className={"lang-list"}>
            {languageSelectModel.languages.map(language =>
                <NavDropdown.Item
                    key={language}
                    onClick={() => switchLanguage(language)}
                >
                    {t(`Locale.${language}`)}
                </NavDropdown.Item>
            )}
        </NavDropdown>
    );
};

export default LanguageSelect;