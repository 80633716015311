import {useEffect, useState} from 'react';
import Api from "common/Api";

const useRepresentationModel = () => {
    const [representationModel, setRepresentationModel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        loadRepresentationModel();
    }, []);

    const loadRepresentationModel = () => {
        Api.getRepresentationModel()
            .then(response => {
                setRepresentationModel(response);
                setIsLoading(false);
            })
            .catch(error => {
                setApiError(error);
                setIsLoading(false);
            });
    };

    const reloadRepresentationModel = () => {
        setApiError(null);
        setIsLoading(true);
        loadRepresentationModel();
    };

    return {representationModel, reloadRepresentationModel, isLoading, apiError};
};

export {useRepresentationModel};