import Api from "common/Api";
import React, {useEffect, useState} from 'react';
import UserContext from "./UserContext";
import {useAuthentication} from "../Authentication";
import {useTranslation} from "react-i18next";

/**
 * Implementiert ein Provider zum {@link UserContext}
 *
 * @param children Kind-Komponente
 * @param currentUserLink Link zum Anwender-Api
 * @returns {JSX.Element}
 * @constructor
 */
const UserProvider = ({children, currentUserLink}) => {
    const {isLoggedIn} = useAuthentication();
    const [currentUser, setCurrentUser] = useState(null);
    const {i18n} = useTranslation();

    useEffect(() => {
        if (isLoggedIn && currentUserLink) {
            Api.get(currentUserLink)
                .then(userRepresentationModel => {
                        const user = {
                            userId: userRepresentationModel.get('userId'),
                            login: userRepresentationModel.get('login'),
                            roleName: userRepresentationModel.get('roleName'),
                            userOrganizationId: userRepresentationModel.get('userOrganizationId'),
                            preferredLocale: userRepresentationModel.get('preferredLocale')
                        };
                        setCurrentUser(user);
                        return userRepresentationModel;
                    }
                )
                .then(userRepresentationModel => {
                        if (userRepresentationModel) {
                            // Wird die Anzeigesprache vor dem Login geändert, so kann es für den Anwender, der diese Änderung gemacht hat, nicht persistiert werden.
                            // Daher wird hier geprüft, ob die ausgewählte Sprache die bevorzugte Sprache des Anwenders entspricht. Ist es nicht der Fall, so wird es aktualisiert
                            if (i18n.language !== userRepresentationModel.get('preferredLocale')) {
                                const changePreferredLocaleLink = userRepresentationModel.getLink('change-preferred-locale');
                                if (changePreferredLocaleLink) {
                                    Api.post(changePreferredLocaleLink, i18n.language)
                                        .then(userRepresentationModel => {
                                            setCurrentUser((prevState => ({
                                                    ...prevState,
                                                    preferredLocale: userRepresentationModel.get('preferredLocale')
                                                })
                                            ));
                                        })
                                        .catch(apiError => {
                                            console.log('UserProvider-Error by change preferred locale: ', apiError);
                                            setCurrentUser((prevState => ({
                                                    ...prevState,
                                                    preferredLocale: null
                                                })
                                            ));
                                        });
                                }
                            }
                        }
                    }
                )
                .catch(apiError => {
                        console.log('UserProvider-Error: ', apiError);
                        setCurrentUser({});
                    }
                );
        } else {
            setCurrentUser({});
        }
    }, [isLoggedIn, currentUserLink, i18n.language]);


    return <UserContext.Provider value={currentUser}>
        {children}
    </UserContext.Provider>
}

export default UserProvider;
