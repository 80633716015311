import React from "react";
import {Card, Col, Image} from "react-bootstrap";
import {Link} from "react-router-dom";

const TemplateSearchResultPageCard = ({templateSearchPageRepresentationModel}) => {

    return (
        <Col className={"tile template"}>
            <Link to={templateSearchPageRepresentationModel.getLinkPathForRouting('template-details')}>
                <Card>
                    <Card.Body bsPrefix={"card-body-image"}>
                        <Image
                            fluid
                            src={templateSearchPageRepresentationModel.getLinkPath('template-thumbnail')}
                            className={"image-shadow"}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {templateSearchPageRepresentationModel.get('templateName')}
                    </Card.Footer>
                </Card>
            </Link>
        </Col>
    );
};

export default TemplateSearchResultPageCard;