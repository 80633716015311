import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SelectField} from "components";

/**
 * Dropdown zur Auswahl einer Sortierung
 *
 * Die Anzeige erfolgt rechtsbündig. Die Breite des Dropdowns ist abhängig vom Breakpoint.
 *
 * @param {{options: String[], value: String, onChange: Function}} props - Props für SelectField
 * @returns {JSX.Element}
 */
const SortSelect = (props) => {
    const {t} = useTranslation();

    return (
        <Row className={"justify-content-end px-4"}>
            <Col xs={12} lg={6} xl={5}>
                <Form>
                    <SelectField
                        id="sortBy"
                        title={t('sort.title')}
                        {...props}
                    />
                </Form>
            </Col>
        </Row>
    );
};

export default SortSelect;