import React from "react";
import {Card, Image} from "react-bootstrap";

/**
 *
 * Bootstrap-Card-Komponente mit einem Text und Bild.
 *
 * @param {String} cardTextClassName - CSS-Klasse für den Text
 * @param {String} cardText - Text
 * @param {String} cardImage - URL des Bilds
 * @return {JSX.Element}
 * @constructor
 */
const NavigationCard = ({cardTextClassName, cardText, cardImage}) => {
    return (
        <Card className={"variable-image"}>
            <Card.Body>
                <Card.Text className={cardTextClassName}>{cardText}</Card.Text>
            </Card.Body>
            <Image fluid src={cardImage} className={"image-shadow"}/>
        </Card>
    );
};

export default NavigationCard;