import React from "react";
import {Col, Row} from "react-bootstrap";
import {FilterGroup, OrganizationFilterGroup, SearchForm} from "components";

/**
 * View Komponente für Suche und Filter
 *
 * Ein Filter wird nur angezeigt, wenn für den jeweiligen Filter mehrere Filterwerte vorhanden sind.
 *
 * @param {Object} search - Parameter für die Suche
 * @param {?Object} organization - Parameter für Organisationsfilter (optional)
 * @param {Object[]} filterGroups - Parameter für sonstige Filtergruppen
 * @return {JSX.Element}
 * @constructor
 */
const SearchAndFilterView = ({search, organization, filterGroups}) => {
    return (
        <Row>
            <Col xs={12} className={"mb-5"}>
                <SearchForm
                    searchFieldPlaceHolder={search.placeHolderText}
                    searchText={search.searchText}
                    onChange={search.onChange}
                    onSubmit={search.onSubmit}
                />
            </Col>

            {organization && Array.isArray(organization.items) && organization.items.length > 1 &&
                <Col xs={12} md={6} lg={12}>
                    <OrganizationFilterGroup
                        organizations={organization.items}
                        selectedOrganization={organization.selectedItem}
                        onChange={organization.onChange}
                    />
                </Col>
            }

            {filterGroups
                .filter(filterGroup => Array.isArray(filterGroup.items) && filterGroup.items.length > 1)
                .map(filterGroup => (
                    <Col key={filterGroup.title} xs={12} md={6} lg={12}>
                        <FilterGroup
                            title={filterGroup.title}
                            titleClassName={"h3"}
                            items={filterGroup.items}
                            onChange={filterGroup.onChange}
                        />
                    </Col>
                ))
            }
        </Row>
    );
};

export default SearchAndFilterView;