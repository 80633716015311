import React from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const NoResults = () => {

    const {t} = useTranslation();

    return (
        <Row className={"px-4"}>
            <Col>
                <div className='d-flex justify-content-center align-items-center'>
                    {t('result.page.no.results')}
                </div>
            </Col>
        </Row>
    );
};

export default NoResults;