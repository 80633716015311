import React, {useState} from "react";
import {Form, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {OrganizationSelectDialog} from "components";
import OrganizationFilterItem from "./OrganizationFilterItem";

/**
 * Filtergruppe in welcher nach einer Organisation gefiltert werden kann.
 * Die Auswahl erfolgt hierbei in einem Dialog, in dem aus allen zur Verfügung stehenden Organisationen
 * eine ausgewählt werden kann.
 * Per Klick auf den Organisationsnamen kann die Organisation wieder abgewählt werden.
 *
 * @param {Array<{key: Number, label: String, internalNumber: String, canBeDeselected: boolean}>} organizations - Organisationen nach denen gefiltert werden kann
 * @param {{key: Number, label: String, internalNumber: String, canBeDeselected: boolean}} selectedOrganization - Ausgewählte Organisation
 * @param {Function} onChange - Callback, erhält als die ID der ausgewählten Organisation (null, wenn keine ausgewählt ist)
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationFilterGroup = ({organizations, selectedOrganization, onChange}) => {
    const {t} = useTranslation();

    const [showDialog, setShowDialog] = useState(false);

    const handleOrganizationSelect = (organization) => {
        setShowDialog(false);
        onChange(organization?.key);
    };

    return (
        <>
            {showDialog &&
                <OrganizationSelectDialog
                    organizations={organizations}
                    initialOrganization={selectedOrganization}
                    onSelect={handleOrganizationSelect}
                    onClose={() => setShowDialog(false)}
                />
            }
            <Form.Group className={"mb-3"}>
                <Form.Floating htmlFor={"filterOptions"} className={"h3"}>
                    {t('publication.search.filter.group.organization.title')}
                    <button
                        className={"btn far fa-window-alt float-end"}
                        onClick={() => setShowDialog(true)}
                    />
                </Form.Floating>
                <Form.Floating id={"filterOptions"}>
                    <Nav
                        as={"ul"}
                        className={"flex-column filter"}
                        onClick={() => selectedOrganization.canBeDeselected ? handleOrganizationSelect(null): null}
                    >
                        <OrganizationFilterItem organization={selectedOrganization}/>
                    </Nav>
                </Form.Floating>
            </Form.Group>
        </>
    );
};

export default OrganizationFilterGroup;