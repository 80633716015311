import Api from "common/Api";
import {AlertApiError, LoadingIndicator, PublicationInfo} from "components";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

class PublicationDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            publicationDetailsRepresentationModel: null,
            publicationName: null,
            isLoading: true,
            apiError: null,
        };
    }

    componentDidMount() {
        Api.getRepresentationModel()
            .then(publicationDetailsRepresentationModel =>
                this.setState({
                    publicationDetailsRepresentationModel: publicationDetailsRepresentationModel,
                    publicationName: publicationDetailsRepresentationModel?.get('publicationName'),
                    isLoading: false
                })
            )
            .catch(apiError =>
                this.setState({
                    apiError: apiError,
                    isLoading: false
                })
            );
    }

    render() {
        const {publicationDetailsRepresentationModel, publicationName, isLoading, apiError} = this.state;

        if (isLoading) {
            return <LoadingIndicator/>;
        }

        if (apiError) {
            return (<AlertApiError apiError={apiError}/>);
        }

        const publicationDetails = {
            publicationId: publicationDetailsRepresentationModel?.get('publicationId'),
            publicationCreateDate: publicationDetailsRepresentationModel?.get('publicationCreateDate'),
            publicationDeepChangeDate: publicationDetailsRepresentationModel?.get('publicationDeepChangeDate'),
            publicationOrganizationName: publicationDetailsRepresentationModel?.get('publicationOrganizationName'),
            publicationOwnerFullName: publicationDetailsRepresentationModel?.get('publicationOwnerFullName'),
            templateName: publicationDetailsRepresentationModel?.get('templateName'),
            designName: publicationDetailsRepresentationModel?.get('designName'),
            pubTypeName: publicationDetailsRepresentationModel?.get('pubTypeName'),
            paperSizeName: publicationDetailsRepresentationModel?.get('paperSizeName'),
            language: publicationDetailsRepresentationModel?.get('publicationLanguage')
        }

        const publicationDownloads = {
            generatePreviewPdfLink: publicationDetailsRepresentationModel?.getLink('generate-preview-pdf'),
            generateWebPdfLink: publicationDetailsRepresentationModel?.getLink('generate-web-pdf'),
            generateBarrierFreePdfLink: publicationDetailsRepresentationModel?.getLink('generate-barrier-free-pdf'),
            generatePrintPdfLink: publicationDetailsRepresentationModel?.getLink('generate-print-pdf'),
            generateOfficePdfLink: publicationDetailsRepresentationModel?.getLink('generate-office-pdf'),
            generateIDMLArchiveLink: publicationDetailsRepresentationModel?.getLink('generate-idml-archive'),
            generateJPEGLink: publicationDetailsRepresentationModel?.getLink('generate-jpeg')
        }

        const publicationData = {
            publicationName: publicationName,
            publicationDescription: publicationDetailsRepresentationModel?.get('publicationDescription'),
            publicationStatus: publicationDetailsRepresentationModel?.get('publicationStatus'),
            publicationThumbnailLink: publicationDetailsRepresentationModel?.getLink('publication-thumbnail'),
            testPublication: publicationDetailsRepresentationModel?.get('testPublication'),
            templateObsolete: publicationDetailsRepresentationModel?.get('templateObsolete'),
            editPublicationHref: publicationDetailsRepresentationModel?.getHref('edit-publication'),
            updatePublicationMetadataLink: publicationDetailsRepresentationModel?.getLink('update-publication-metadata'),
            publicationDetails: publicationDetails,
            publicationDownloads: publicationDownloads
        }

        const handleMetadataChange = metadata => {
            this.setState({
                publicationName: metadata.publicationName
            });
        };

        return (
            <section>
                <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                    <Row>
                        <Col>
                            <Row className={"template-detail"}>
                                <Col>
                                    <Row className={"template-name"}>
                                        <Col>
                                            <h1>{publicationName}</h1>
                                        </Col>
                                    </Row>
                                    <PublicationInfo
                                        publicationData={publicationData}
                                        onMetadataChange={handleMetadataChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default PublicationDetails;