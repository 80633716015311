/**
 * Implementiert ein Modell um Validierungsfehler zu beschreiben.
 */
export default class ApiValidationError {

    /**
     * Namen des betroffenen Objekts
     *
     * @type {string}
     */
    objectName;

    /**
     * Betroffene Feld des Objekts
     *
     * @type {string}
     */
    field;

    /**
     * Abgelehnte Feldwert
     *
     * @type {any}
     */
    rejectedValue;

    /**
     * Fehlermeldung
     *
     * @type {string}
     */
    message;


    constructor(validationError) {
        this.objectName = validationError.objectName;
        this.field = validationError.field;
        this.rejectedValue = validationError.rejectedValue;
        this.message = validationError.message;
    }
}