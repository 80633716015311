/**
 * Werte für Auswahl der Newsbeiträge (Aktuell/Archiv)
 *
 * @type {{ACTUAL: string, ARCHIVE: string}}
 */
const NewsPostState = {
    ACTUAL: 'ACTUAL',
    ARCHIVE: 'ARCHIVE'
}

export default NewsPostState;