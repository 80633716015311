import {
    AlertApiError,
    LoadingIndicator,
    NoResults,
    PublicationSearchListView,
    PublicationSearchTileView,
    ToolboxExit
} from "components";
import {Button, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Api from "common/Api";
import Modal from 'react-bootstrap/Modal';

/**
 * Archivierungsmodus von Publikationen.
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodelle der Publikation-Suchseite
 * @param {View} view - Ansicht
 * @param publicationsArchiveLink - Link, um ausgewählte Publikationen zu archivieren
 * @param {Function} reloadPublications - Callback zum Verlassen der Aktualisierung der Liste
 * @param {Function} exitModeButtonClicked - Callback zum Verlassen dem aktuellen Ansichtsmodus
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchArchiveViewMode = ({
                                              publicationSearchPageRepresentationModels,
                                              view,
                                              publicationsArchiveLink,
                                              reloadPublications,
                                              exitModeButtonClicked
                                          }) => {
    const {t} = useTranslation();

    const [selectedPublicationIds, setSelectedPublicationIds] = useState([]);

    const [show, setShow] = useState(false);

    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const _selectPublication = (publicationSearchPageRepresentationModel) => {
        let selection = [...selectedPublicationIds];

        const index = selection.indexOf(publicationSearchPageRepresentationModel.get('publicationId'));
        if (index >= 0) {
            selection = [
                ...selectedPublicationIds.slice(0, index),
                ...selectedPublicationIds.slice(index + 1)
            ];
        } else {
            selection.push(publicationSearchPageRepresentationModel.get('publicationId'));
        }

        setSelectedPublicationIds(selection);
    };

    const _deselectAllPublications = () => {
        setSelectedPublicationIds([]);
    };

    const _selectAllPublications = () => {
        const selection = [];

        publicationSearchPageRepresentationModels.forEach(publicationSearchPageRepresentationModel => {
            selection.push(publicationSearchPageRepresentationModel.get('publicationId'));
        });

        setSelectedPublicationIds(selection);
    };

    const _archivePublications = () => {
        if (publicationsArchiveLink) {
            const publicationsArchiveSpecification = {
                publicationIds: selectedPublicationIds
            };

            setIsLoading(true);

            Api.put(publicationsArchiveLink, publicationsArchiveSpecification)
                .then(() => {
                    reloadPublications();
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }
    };

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    return (
        <Row className={"px-4 py-5"}>
            <ToolboxExit exitButtonClicked={exitModeButtonClicked}/>
            {(!Array.isArray(publicationSearchPageRepresentationModels) || publicationSearchPageRepresentationModels.length === 0) ?
                <NoResults/> :
                <Col xs={12}>
                    {view.isTileView() ?
                        <PublicationSearchTileView
                            publicationSearchPageRepresentationModels={publicationSearchPageRepresentationModels}
                        /> :
                        <>
                            <p>
                                {t('publication.search.view.list.mode.archive.text')}
                            </p>
                            <PublicationSearchListView
                                publicationSearchPageRepresentationModels={publicationSearchPageRepresentationModels}
                                listView={view}
                                selectedPublicationIds={selectedPublicationIds}
                                handleSelect={_selectPublication}
                            />
                        </>
                    }
                </Col>
            }
            <Col xs={12}>
                <Row className={"g-0"}>
                    <ToolboxExit exitButtonClicked={exitModeButtonClicked}/>
                    {Array.isArray(publicationSearchPageRepresentationModels) && publicationSearchPageRepresentationModels.length > 0 &&
                        <Col className={"text-end"}>
                            <Button
                                variant={"primary"}
                                className={"mb-4"}
                                onClick={() => _deselectAllPublications()}
                            >
                                {t('button.deselect.all')}
                            </Button>
                            &nbsp;
                            <Button
                                variant={"primary"}
                                className={"mb-4"}
                                onClick={() => _selectAllPublications()}
                            >
                                {t('button.select.all')}
                            </Button>
                            &nbsp;
                            <Button
                                variant={"primary"}
                                className={"mb-4"}
                                onClick={() => handleShow()}
                            >
                                {t('button.archive')}
                            </Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {t('publication.search.view.list.mode.archive.modal.confirm.title')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {t('publication.search.view.list.mode.archive.modal.confirm.body')}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        {t('button.close')}
                                    </Button>
                                    <Button variant="primary" onClick={_archivePublications}>
                                        {t('button.confirm')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
export default PublicationSearchArchiveViewMode;