import Sort from "common/Sort";
import {
    AlertApiError,
    PublicationSearchAndFilter,
    PublicationSearchPageView,
    PublicationSearchToolbox,
    SortSelect,
    useRepresentationModel
} from "components";
import React, {useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Constants from "common/Constants";
import PublicationAuthor from "common/publication/PublicationAuthor";
import PublicationViewMode from "common/publication/PublicationViewMode";
import View from "common/View";
import {useTranslation} from "react-i18next";

const PublicationSearch = ({currentUser}) => {

    const {t} = useTranslation();

    const INITIAL_FILTER_STATE = {
        searchText: null,
        organizationId: currentUser?.userOrganizationId,
        authors: [PublicationAuthor.OWN],
        locales: [],
        pubTypeIds: [],
        statuses: []
    };

    const INITIAL_PAGE_VIEW_STATE = {
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        view: View.DEFAULT_TILE_VIEW,
        publicationViewMode: PublicationViewMode.MODE.DEFAULT
    };

    const publicationSearchAndFilterRef = useRef();
    const publicationSearchToolboxRef = useRef();

    const {representationModel, isLoading, apiError} = useRepresentationModel();
    const [publicationFilters, setPublicationFilters] = useState(INITIAL_FILTER_STATE);
    const [sortKey, setSortKey] = useState(Sort.PUBLICATION_DEFAULT);
    const [pageViewModel, setPageViewModel] = useState(INITIAL_PAGE_VIEW_STATE);

    if (isLoading) {
        return null; // Kinder-Komponenten zeigen schon eine LoadingIndikator
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const publicationFilterModel = {
        initialAuthor: PublicationAuthor.OWN,
        searchFieldPlaceHolder: t('publication.search.field.place.holder')
    };

    const publicationSearchPageModel = {
        publicationSearchPageLink: representationModel?.getLink('get-publication-search-page'),
        publicationsArchiveLink: representationModel?.getLink('archive-publications'),
        publicationsDeleteLink: representationModel?.getLink('remove-publications'),
    }

    const publicationSearchFiltersLink = representationModel?.getLink('get-publication-search-filters');

    const clearStatusFilters = prevPublicationViewMode => {
        if (prevPublicationViewMode === PublicationViewMode.MODE.ARCHIVE ||
            prevPublicationViewMode === PublicationViewMode.MODE.DELETE) {
            publicationSearchAndFilterRef.current.clearStatusFilters();
        }
    };

    const onModusSelected = (mode) => {
        const prevPublicationViewMode = pageViewModel.publicationViewMode;

        setPageViewModel(prevState => ({
            ...prevState,
            pageSize: mode.pageSize,
            view: mode.view,
            publicationViewMode: mode.publicationViewMode
        }));

        switch (mode.publicationViewMode) {
            case PublicationViewMode.MODE.ARCHIVE:
                publicationSearchAndFilterRef.current.toReleaseState();
                break;
            case PublicationViewMode.MODE.DELETE:
                publicationSearchAndFilterRef.current.toEditState();
                break;
            case PublicationViewMode.MODE.DOWNLOAD:
                clearStatusFilters(prevPublicationViewMode);
                break;
            default:
                throw new Error("Unbekannter ViewMode: " + mode.publicationViewMode);
        }
    };

    const exitModeButtonClicked = () => {
        clearStatusFilters(pageViewModel.publicationViewMode);

        setPageViewModel(INITIAL_PAGE_VIEW_STATE);
        publicationSearchToolboxRef.current.exitToolBox();
    }

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"result-list"}>
                            <Col xs={12} lg={4} xl={3} className={"filter-col"}>
                                <PublicationSearchAndFilter
                                    ref={publicationSearchAndFilterRef}
                                    filterModel={publicationFilterModel}
                                    publicationSearchFiltersLink={publicationSearchFiltersLink}
                                    onChange={setPublicationFilters}
                                    currentUser={currentUser}
                                />
                                {/*TODO wer darf welche Modus sehen?*/}
                                <PublicationSearchToolbox
                                    ref={publicationSearchToolboxRef}
                                    onModusSelected={onModusSelected}
                                />
                            </Col>
                            <Col xs={12} lg={8} xl={9} className={"result-col"}>
                                <SortSelect
                                    options={Sort.sortKeys()}
                                    value={sortKey}
                                    onChange={setSortKey}
                                />
                                <PublicationSearchPageView
                                    publicationSearchPageModel={publicationSearchPageModel}
                                    publicationFilters={publicationFilters}
                                    sort={Sort.sortValueOf(sortKey)}
                                    pageViewModel={pageViewModel}
                                    exitModeButtonClicked={exitModeButtonClicked}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default PublicationSearch;