import React, {useRef, useState} from "react";
import Sort from "common/Sort";
import {Col, Container, Row} from "react-bootstrap";
import {
    AlertApiError,
    SortSelect,
    SpreadPublicationPageView,
    SpreadPublicationSearchAndFilter,
    SpreadPublicationSearchToolbox,
    useRepresentationModel
} from "components";
import Constants from "common/Constants";
import View from "common/View";
import SpreadPublicationViewMode from "common/publication/SpreadPublicationViewMode";

const SpreadPublicationSearch = () => {

    const INITIAL_FILTER_STATE = {
        searchText: null,
        pubTypeIds: []
    }

    const INITIAL_PAGE_VIEW_STATE = {
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        view: View.DEFAULT_TILE_VIEW,
        spreadPublicationViewMode: SpreadPublicationViewMode.MODE.DEFAULT
    };

    const spreadPublicationSearchAndFilterRef = useRef();
    const spreadPublicationSearchToolboxRef = useRef();

    const {representationModel, isLoading, apiError} = useRepresentationModel();
    const [spreadPublicationFilters, setSpreadPublicationFilters] = useState(INITIAL_FILTER_STATE);
    const [sortKey, setSortKey] = useState(Sort.PUBLICATION_DEFAULT);

    const [pageViewModel, setPageViewModel] = useState(INITIAL_PAGE_VIEW_STATE);

    if (isLoading) {
        return null; // Kinder-Komponenten zeigen schon eine LoadingIndikator
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const spreadPublicationSearchPageModel = {
        spreadPublicationSearchPageLink: representationModel?.getLink('get-spread-publication-search-page'),
        spreadPublicationsMarkAsReadLink: representationModel?.getLink('mark-spread-publications-as-read')
    }

    const spreadPublicationSearchFiltersLink = representationModel?.getLink('get-spread-publication-search-filters');

    const clearFilters = prevPublicationViewMode => {
        if (prevPublicationViewMode === SpreadPublicationViewMode.MODE.MARK_READ) {
            spreadPublicationSearchAndFilterRef.current.clearAllFilters();
        }
    };

    const onModusSelected = (mode) => {
        const prevSpreadPublicationViewMode = pageViewModel.spreadPublicationViewMode;

        setPageViewModel(prevState => ({
            ...prevState,
            pageSize: mode.pageSize,
            view: mode.view,
            spreadPublicationViewMode: mode.spreadPublicationViewMode
        }));

        switch (mode.spreadPublicationViewMode) {
            case SpreadPublicationViewMode.MODE.MARK_READ:
                clearFilters(prevSpreadPublicationViewMode);
                break;
            default:
                throw new Error("Unbekannter ViewMode: " + mode.spreadPublicationViewMode);
        }
    };

    const exitModeButtonClicked = () => {
        clearFilters(pageViewModel.spreadPublicationViewMode);

        setPageViewModel(INITIAL_PAGE_VIEW_STATE);
        spreadPublicationSearchToolboxRef.current.exitToolBox();
    }

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"result-list"}>
                            <Col xs={12} lg={4} xl={3} className={"filter-col"}>
                                <SpreadPublicationSearchAndFilter
                                    ref={spreadPublicationSearchAndFilterRef}
                                    onChange={setSpreadPublicationFilters}
                                    searchFiltersLink={spreadPublicationSearchFiltersLink}
                                />
                                <SpreadPublicationSearchToolbox
                                    ref={spreadPublicationSearchToolboxRef}
                                    onModusSelected={onModusSelected}
                                />
                            </Col>
                            <Col xs={12} lg={8} xl={9} className={"result-col"}>
                                <SortSelect
                                    options={Sort.sortKeys()}
                                    value={sortKey}
                                    onChange={setSortKey}
                                />
                                <SpreadPublicationPageView
                                    spreadPublicationSearchPageModel={spreadPublicationSearchPageModel}
                                    spreadPublicationFilters={spreadPublicationFilters}
                                    sort={Sort.sortValueOf(sortKey)}
                                    pageViewModel={pageViewModel}
                                    exitModeButtonClicked={exitModeButtonClicked}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default SpreadPublicationSearch;