import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";

/**
 * Formularfelder um Werte für das Erstellen einer dynamischen Anzeige auszuwählen.
 *
 * @param {{nettoWidth: Number}} templateDynamicMetaData - initiale Werte
 * @param {Function} onDynamicMetaDataChange - Callback der bei Änderungen getriggert wird
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateDynamicMetaData = ({templateDynamicMetaData, onDynamicMetaDataChange}) => {

    const {t} = useTranslation();

    const [formValues, setFormValues] = useState({
        publicationWidth: templateDynamicMetaData?.nettoWidth
    });
    const [formValidationErrors, setFormValidationErrors] = useState({});

    const _validateFieldValue = (field, value) => {
        if (field === 'publicationWidth' && (value < templateDynamicMetaData.nettoWidth || value > 1000000)) {
            return <Trans
                i18nKey={"template.details.dynamic.template.form.validation.publication.min.width.range"}
                values={{min: templateDynamicMetaData.nettoWidth, max: 1000000}}
            />
        }
        return null;
    }

    const _handleChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        });

        const validation = _validateFieldValue(event.target.name, event.target.value);

        setFormValidationErrors({
            ...formValidationErrors,
            [event.target.name]: validation
        });

        onDynamicMetaDataChange({
            hasError: validation != null,
            form: {
                ...formValues,
                [event.target.name]: event.target.value
            }
        });
    }

    return (
        <Form.Group className={"mb-3"}>
            <Form.Label htmlFor={"publicationWidth"}>
                {t('template.details.dynamic.template.form.publication.width.label')}
            </Form.Label>
            <Form.Control
                type={"text"}
                id={"publicationWidth"}
                name={"publicationWidth"}
                className={"size-100"}
                placeholder={t('template.details.dynamic.template.form.publication.width.placeholder')}
                onChange={event => _handleChange(event)}
                value={formValues.publicationWidth}
                isInvalid={!!formValidationErrors.publicationWidth}
            />
            <Form.Control.Feedback type={"invalid"}>
                {formValidationErrors.publicationWidth}
            </Form.Control.Feedback>
            <Form.Text>
                <Trans
                    i18nKey={"template.details.dynamic.template.form.publication.min.width"}
                    values={{minWidth: templateDynamicMetaData.nettoWidth}}
                />
            </Form.Text>
        </Form.Group>
    );
};

export default TemplateDynamicMetaData;