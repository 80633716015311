import ApiValidationError from "./ApiValidationError";

/**
 * Implementiert ein Modell um alle Fehler zu beschreiben.
 */
export default class ApiError {
    /**
     * Fehlermeldung
     *
     * @type {string}
     */
    message;

    /**
     * HttpStatus
     *
     * @type {number}
     */
    status;

    /**
     * Validierungsfehler
     *
     * @type {[ApiValidationError]}
     */
    apiValidationErrors = [];

    /**
     * Gibt an, ob es als Warnung behandelt werden soll
     *
     * @type {boolean}
     */
    warning;


    constructor(data, status) {
        this.message = data.message;
        this.warning = data.warning;
        this.status = status;

        if (data.apiValidationErrors) {
            for (let validationError of data.apiValidationErrors) {
                this.apiValidationErrors.push(new ApiValidationError(validationError));
            }
        }
    }

    static unexpectedError() {
        const message = 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
        return new ApiError({message: message, warning: false}, 0);
    }

    /**
     * Liefert die Fehlermeldung
     *
     * @return {string}
     */
    getMessage() {
        return this.message;
    }

    /**
     * Liefert den HttpStatus
     *
     * @return {number}
     */
    getStatus() {
        return this.status;
    }

    isWarning() {
        return this.warning;
    }

    /**
     * Liefert alle Validierungsfehler zu dem übergebenen Feld
     *
     * @param field Feld
     * @return {ApiValidationError[]}
     */
    getFieldError(field) {
        return this.apiValidationErrors.filter((apiValidationError) => apiValidationError.field === field);
    }

    /**
     * Überprüft ob zu dem übergebenen Feld mind. einer Validierungsfehler existiert
     *
     * @param field Feld
     * @return {boolean}
     */
    hasFieldError(field) {
        return this.getFieldError(field).length > 0;
    }
}