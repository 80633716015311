import Api from "common/Api";
import Constants from "common/Constants";
import PageRequest from "common/PageRequest";
import {
    AlertApiError,
    LoadingIndicator,
    NewsResultPageCard,
    NoResults,
    Pagination
} from "components";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const NewsResultPage = ({newsPageModel, newsFilters}) => {

    const pageSize = Constants.DEFAULT_PAGE_SIZE;

    const [pageRepresentationModel, setPagedRepresentationModel] = useState(null);
    const [currentPage, setCurrentPage] = useState(Constants.FIRST_PAGE_NUMBER);  // Initial wird die erste Seite geladen

    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setCurrentPage(Constants.FIRST_PAGE_NUMBER)
    }, [newsFilters]);

    useEffect(() => {
        const queryParams = {};
        Object.assign(queryParams, newsFilters);
        const pageRequest = new PageRequest(currentPage, pageSize, {validFrom: {direction: 'DESC'}});
        Object.assign(queryParams, pageRequest.toModel())

        const newsPageLink = newsPageModel.newsPageLink.withQueryParams(queryParams);
        if (newsPageLink) {
            setIsLoading(true);
            Api.get(newsPageLink)
                .then(pagedRepresentationModel => {
                    setPagedRepresentationModel(pagedRepresentationModel);
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }
    }, [currentPage, pageSize, newsFilters, newsPageModel.newsPageLink]);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const searchResults = pageRepresentationModel?.getEmbeddedRepresentationModelsByName("newsPageRepresentationModels");
    const pageModel = pageRepresentationModel?.get('page');

    if (!Array.isArray(searchResults) || searchResults.length === 0) {
        return (
            <NoResults/>
        );
    }

    return (
        <>
            {searchResults.map(searchResult => (
                <Link key={searchResult.get('newsId')} to={searchResult.getLinkPathForRouting('news-details')}>
                    <NewsResultPageCard newsPageRepresentationModel={searchResult}/>
                </Link>
            ))}

            <Row className={"px-4"}>
                <Col>
                    <Pagination currentPage={currentPage}
                                totalPages={pageModel?.totalPages}
                                pageSize={pageSize}
                                onPageChange={page => setCurrentPage(page)}/>
                </Col>
            </Row>
        </>
    );
}

export default NewsResultPage;