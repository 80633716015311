import {Card, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React from "react";

/**
 * Toolbox
 *
 * @param {Array<{key: String, title: String, iconCssClass: String}>} items - Toolbox-Einträge
 * @param {Function} toolboxItemSelected Callback um Toolbox-Eintag auszuwählen
 * @param {String} activeKey Die Key des aktiven Toolbox-Eintrags
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbox = ({items, activeKey = null, toolboxItemSelected}) => {
    const {t} = useTranslation();

    return (
        <Card className={`toolbox ${activeKey ? 'active' : ''}`}>
            <Card.Header className="h3">
                <i className="fas fa-cog me-2"></i>{t('tool.box')}
            </Card.Header>
            <Card.Body>
                <Nav as={"ul"} className={"flex-column filter"}>
                    {Array.isArray(items) && items.map((item) =>
                        <Nav.Item
                            key={item.key}
                            as={"li"}
                            bsPrefix={`nav-item ${activeKey === item.key ? 'active' : ''}`}
                            onClick={() => toolboxItemSelected(item)}>
                            <i className={`fas ${item.iconCssClass} me-2`}></i>{item.title}
                        </Nav.Item>
                    )}
                </Nav>
            </Card.Body>
        </Card>
    );
}
export default Toolbox;