import React from "react";
import {Button, FormControl, InputGroup} from "react-bootstrap";

const SearchField = ({id, searchText, placeholder, onChange}) => {
    return (
        <InputGroup className={"search"}>
            <FormControl id={id} type={"text"} placeholder={placeholder} value={searchText} onChange={onChange}/>
            <Button variant={"primary"} type={"submit"}><i className={"fal fa-search"}/></Button>
        </InputGroup>
    );
};

export default SearchField;