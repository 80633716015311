import {AlertApiError, NewsResultPage, NewsSearchAndFilter, useRepresentationModel} from "components";
import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import NewsPostState from "common/news/NewsPostState";

const News = () => {
    const {representationModel, isLoading, apiError} = useRepresentationModel();

    const initialNewsPostState = NewsPostState.ACTUAL;
    const [newsFilters, setNewsFilters] = useState({
        searchText: null,
        newsPostStates: [initialNewsPostState]
    });

    if (isLoading) {
        return null;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const newsSearchFiltersLink = representationModel?.getLink('get-news-search-filters');

    const newsPageModel = {
        newsPageLink: representationModel?.getLink('get-news-page'),
    }

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"result-list"}>
                            <Col xs={12} lg={4} xl={3} className={"filter-col"}>
                                <NewsSearchAndFilter
                                    newsSearchFiltersLink={newsSearchFiltersLink}
                                    initialNewsPostState={initialNewsPostState}
                                    onChange={setNewsFilters}
                                />
                            </Col>
                            <Col xs={12} lg={8} xl={9} className={"result-col"}>
                                <NewsResultPage
                                    newsPageModel={newsPageModel}
                                    newsFilters={newsFilters}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default News;