import * as React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import RenewPasswordForm from "./components/RenewPasswordForm"

/**
 * Seite zum Aktualisieren des Passworts
 *
 * @returns {JSX.Element}
 * @constructor
 */
const RenewPasswordPage = () => {
    const [searchParams] = useSearchParams();

    return (
        <section className="main-content my-lg-auto">
            <Container className="px-3 px-lg-4 py-3 py-lg-3">
                <Row className="justify-content-center">
                    <Col xs={12} lg={6} className={"login-box"}>
                        <Row className="g-0">
                            <Col className="p-4">
                                <RenewPasswordForm token={searchParams.get('tk')}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default RenewPasswordPage;