import React from "react";
import {FormSelect, InputGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";

/**
 * Dropdown mit Titel.
 *
 * @param {String} id - ID
 * @param {String} title - Titel
 * @param {String[]} options - Werte die zur Auswahl stehen
 * (werden auch als Keys zur lokalisierten Anzeige genutzt,
 * es muss für jeden Wert ein message.properties Eintrag vorhanden sein)
 * @param {String} value - ausgewählter Wert
 * @param {Function} onChange - Callback der bei Änderungen der Auswahl getriggert wird
 * @returns {JSX.Element}
 * @constructor
 */
const SelectField = ({id, title, options, value, onChange}) => {
    const {t} = useTranslation();

    return (
        <InputGroup className={"default-label mb-3"}>
            <InputGroup.Text>{title}</InputGroup.Text>
            <FormSelect
                id={id}
                value={value}
                onChange={event => onChange(event.target.value)}
            >
                {Array.isArray(options) && options.map(option =>
                    <option key={option} value={option}>{t(option)}</option>
                )}
            </FormSelect>
        </InputGroup>
    );
};

export default SelectField;