export default class UnitConstants {

    /**
     * Millimeter pro Zoll.
     */
    static MM_PER_INCH = 25.4;

    /**
     * Points pro Zoll.
     */
    static POINT_PER_INCH = 72;

    /**
     * Millimeter pro ein Punkt
     */
    static MM_PER_POINT = UnitConstants.MM_PER_INCH / UnitConstants.POINT_PER_INCH;

    /**
     * Punkten in einem Millimeter.
     */
    static POINT_PER_MM = UnitConstants.POINT_PER_INCH / UnitConstants.MM_PER_INCH;

    // TODO Wenn die Oberfläche mehrsprachig wird, muss hier Locale auch dynamisch gesetzt werden
    static DATE_TIME_FORMAT = new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    static DATE_FORMAT = new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    // TODO Wenn die Oberfläche mehrsprachig wird, muss hier Locale auch dynamisch gesetzt werden
    static NUMBER_FORMAT = new Intl.NumberFormat('de-DE', {
        minimumIntegerDigits: 2
    });
}