const overrideCssStyles = (indexRepresentationModel) => {

    // Hintergrundbild
    const backgroundImageFile = indexRepresentationModel?.get('backgroundImageFile');
    if (backgroundImageFile) {
        const bodyTag = document.getElementsByTagName('body')[0];
        bodyTag?.style.setProperty(
            "background-image", "url('" + backgroundImageFile + "')"
        )
    }

    // Header-Bild
    const headerImageTag = document.getElementsByClassName('header-image')[0];

    const headerImageFile = indexRepresentationModel?.get('headerImageFile');
    if (headerImageFile) {
        headerImageTag?.style.setProperty("background-image", "url('" + headerImageFile + "')");
    }

    // mandanten-spezifische Layout Einstellungen
    const systemSettingsCustomLayout = indexRepresentationModel?.get('systemSettingsCustomLayout');
    if (systemSettingsCustomLayout) {
        const headerLogoRightAligned = systemSettingsCustomLayout.headerLogoRightAligned;
        if (headerLogoRightAligned) {
            headerImageTag?.style.setProperty("flex-direction", "row-reverse");
            headerImageTag?.style.setProperty("justify-content", "space-between");

            const appNameTag = document.getElementsByClassName('app-name')[0];
            appNameTag?.style.setProperty("margin-left", "0");
        }

        const noRoundedHeader = systemSettingsCustomLayout.noRoundedHeader;
        if (noRoundedHeader) {
            headerImageTag?.style.setProperty("border-top-left-radius", "0");
            headerImageTag?.style.setProperty("padding-left", "0.5rem");
        }
    }

    // Farben
    const systemSettingsColorModel = indexRepresentationModel?.get('systemSettingsColorModel');
    if (systemSettingsColorModel) {
        const systemBackgroundColor = systemSettingsColorModel.systemBackgroundColor;
        if (systemBackgroundColor) {
            document.documentElement.style.setProperty('--system-background-color', systemBackgroundColor);
        }

        const systemTextColor = systemSettingsColorModel.systemTextColor;
        if (systemTextColor) {
            document.documentElement.style.setProperty('--system-text-color', systemTextColor);
        }

        const systemHoverFocusTextColor = systemSettingsColorModel.systemHoverFocusTextColor;
        if (systemHoverFocusTextColor) {
            document.documentElement.style.setProperty('--system-hover-focus-text-color', systemHoverFocusTextColor);
        }

        const systemDisabledTextColor = systemSettingsColorModel.systemDisabledTextColor;
        if (systemDisabledTextColor) {
            document.documentElement.style.setProperty('--system-disabled-text-color', systemDisabledTextColor);
        }

        const systemBorderColor = systemSettingsColorModel.systemBorderColor;
        if (systemBorderColor) {
            document.documentElement.style.setProperty('--system-border-color', systemBorderColor);
        }
    }

    // Schrift-Einstellungen
    const systemSettingsFontModel = indexRepresentationModel?.get('systemSettingsFontModel');
    if (systemSettingsFontModel && systemSettingsFontModel.fontFamily) {
        document.documentElement.style.setProperty('--system-font-family', "'" + systemSettingsFontModel.fontFamily + "', 'Open Sans', Arial, Helvetica, sans-serif");
    }
}

export default overrideCssStyles;