import * as React from 'react';

import {Spinner} from "react-bootstrap";

/**
 * Ladeanzeige zum Einbetten in einer Seite um zu zeigen, dass noch Seitenelemente geladen werden
 *
 * @return {JSX.Element}
 */
const LoadingIndicator = () => {
    return (
        <div className={"d-flex justify-content-center align-items-center"}>
            <Spinner animation={"border"} role={"status"} style={{ width: '5rem', height: '5rem' }} variant={"primary"}>
                <span className={"visually-hidden"}>Loading...</span>
            </Spinner>
        </div>
    );
};

export default LoadingIndicator;