import React from "react";
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

/**
 *
 * Link-Kachel mit Link zur Nachrichtenübersicht.
 *
 * @param {String} image - URL das Hintergrundbilds
 * @param {String} link - Router-Link
 * @return {JSX.Element}
 * @constructor
 */
const NewsOverviewLinkCard = ({image, link}) => {
    const {t} = useTranslation();

    return (
        <Card className={"card-bg"} style={{backgroundImage: `url(${image})`}}>
            <Card.Body>
                <Card.Text className={"h1"}>
                    {t('homepage.publication.news.title')}
                    <Button to={link} as={Link} variant={"primary"} className={"stretched-link"}>
                        {t('homepage.publication.news.button.label')}
                    </Button>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default NewsOverviewLinkCard;