import Api from "common/Api";
import {AlertApiError, LoadingIndicator} from "components";
import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const TemplatePublicationMetaData = ({templatePublicationMetaData, onTemplatePublicationMetaDataChange}) => {

    const {t, i18n} = useTranslation();

    const [contentLanguageModels, setContentLanguageModels] = useState(null);
    const [defaultContentLanguage, setDefaultContentLanguage] = useState(null);

    const [formValues, setFormValues] = useState({
        publicationName: templatePublicationMetaData.publicationName,
        contentLang: null
    })

    const [formValidationErrors, setFormValidationErrors] = useState({})

    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        if (templatePublicationMetaData.contentLanguagesLink) {
            setIsLoading(true);
            Api.get(templatePublicationMetaData.contentLanguagesLink)
                .then(contentLanguageRepresentationModel => {
                    const contentLanguageModels = [];
                    let defaultLanguage = null;

                    const contentLanguageRepresentationModels = contentLanguageRepresentationModel.getEmbeddedRepresentationModelsByName("contentLanguageRepresentationModels");
                    contentLanguageRepresentationModels.forEach(contentLanguageModel => {
                        const contentLang = contentLanguageModel.get('contentLang');

                        contentLanguageModels.push({
                            contentLang: contentLang,
                            displayName: contentLanguageModel.get('displayName')
                        });

                        if (contentLang === i18n.language) {
                            defaultLanguage = contentLang;
                        }
                    });

                    if (defaultLanguage == null && contentLanguageModels.length > 0) {
                        defaultLanguage = contentLanguageModels[0].contentLang;
                    }

                    setContentLanguageModels(contentLanguageModels);
                    setDefaultContentLanguage(defaultLanguage);

                    if (Array.isArray(contentLanguageModels) && contentLanguageModels.length > 0) {
                        setFormValues(prevState => ({
                            ...prevState,
                            contentLang: defaultLanguage
                        }));
                        onTemplatePublicationMetaDataChange({
                            form: {
                                contentLang: defaultLanguage
                            }
                        });
                    }
                    setIsLoading(false);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                });
        }

    }, [templatePublicationMetaData.contentLanguagesLink]);

    const _validateFieldValue = (field, value) => {
        if (field === 'publicationName' && (!value || !value.trim())) {
            return t('template.details.template.publication.metadata.publication.title.validation.message');
        }
        return null;
    }

    const _handleChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        });

        const validation = _validateFieldValue(event.target.name, event.target.value);

        setFormValidationErrors({
            ...formValidationErrors,
            [event.target.name]: validation
        });

        onTemplatePublicationMetaDataChange({
            hasError: validation != null,
            form: {
                ...formValues,
                [event.target.name]: event.target.value
            }
        });
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <>
            {apiError &&
                <AlertApiError apiError={apiError}/>
            }

            <Form.Group className={"mb-3"}>
                <Form.Label htmlFor={"publicationName"}>
                    {t('template.details.template.publication.metadata.publication.label')}
                </Form.Label>
                <Form.Control
                    type={"text"}
                    id={"publicationName"}
                    name={"publicationName"}
                    placeholder={t('template.details.template.publication.metadata.publication.title.placeholder')}
                    onChange={event => {
                        _handleChange(event)
                    }}
                    value={formValues.publicationName}
                    isInvalid={!!formValidationErrors.publicationName}
                />
                <Form.Control.Feedback type={"invalid"}>
                    {formValidationErrors.publicationName}
                </Form.Control.Feedback>
            </Form.Group>

            {contentLanguageModels && contentLanguageModels.length > 1 &&
                <Form.Group className={"mb-3"}>
                    <Form.Label htmlFor={"contentLanguage"}>
                        {t('template.details.template.publication.metadata.publication.content.language.label')}
                    </Form.Label>
                    <Form.Select
                        id={"contentLanguage"}
                        name={"contentLang"}
                        defaultValue={defaultContentLanguage}
                        onChange={event => _handleChange(event)}
                    >
                        {contentLanguageModels.map(contentLanguageModel => (
                            <option key={contentLanguageModel.contentLang} value={contentLanguageModel.contentLang}>
                                {contentLanguageModel.displayName}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            }
        </>
    );
}

export default TemplatePublicationMetaData;