import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Sort from "common/Sort";
import {
    AlertApiError,
    PublicationSearchAndFilter,
    PublicationSearchPageView,
    SortSelect,
    useRepresentationModel
} from "components";
import Constants from "common/Constants";
import PublicationAuthor from "common/publication/PublicationAuthor";
import PublicationViewMode from "common/publication/PublicationViewMode";
import UserContext from "common/user/UserContext";
import View from "common/View";
import {useTranslation} from "react-i18next";

const MyTasks = () => {

    const {t} = useTranslation();

    const INITIAL_FILTER_STATE = {
        searchText: null,
        authors: [PublicationAuthor.OTHERS],
        pubTypeIds: [],
        statuses: []
    };

    const INITIAL_PAGE_VIEW_STATE = {
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        view: View.DEFAULT_TILE_VIEW,
        publicationViewMode: PublicationViewMode.MODE.DEFAULT
    };

    const {representationModel, isLoading, apiError} = useRepresentationModel();
    const [publicationFilters, setPublicationFilters] = useState(INITIAL_FILTER_STATE);
    const [sortKey, setSortKey] = useState(Sort.PUBLICATION_DEFAULT);
    const pageViewModel = INITIAL_PAGE_VIEW_STATE;

    if (isLoading) {
        return null; // Kinder-Komponenten zeigen schon eine LoadingIndikator
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const myTasksFilterModel = {
        initialAuthor: PublicationAuthor.OTHERS,
        searchFieldPlaceHolder: t('my.tasks.search.field.place.holder')
    };

    const myTasksPageModel = {
        publicationSearchPageLink: representationModel?.getLink('get-my-tasks-page'),
    };

    const publicationFiltersLink = representationModel?.getLink('get-my-tasks-filters');

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"result-list"}>
                            <Col xs={12} lg={4} xl={3} className={"filter-col"}>
                                <UserContext.Consumer>
                                    {(user) => (
                                        <PublicationSearchAndFilter
                                            filterModel={myTasksFilterModel}
                                            publicationSearchFiltersLink={publicationFiltersLink}
                                            onChange={setPublicationFilters}
                                            currentUser={user}
                                        />
                                    )}
                                </UserContext.Consumer>
                            </Col>
                            <Col xs={12} lg={8} xl={9} className={"result-col"}>
                                <SortSelect
                                    options={Sort.sortKeys()}
                                    value={sortKey}
                                    onChange={setSortKey}
                                />
                                <PublicationSearchPageView
                                    publicationSearchPageModel={myTasksPageModel}
                                    publicationFilters={publicationFilters}
                                    sort={Sort.sortValueOf(sortKey)}
                                    pageViewModel={pageViewModel}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default MyTasks;