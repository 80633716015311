import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Accordion, Alert, Card, Col, Form, Row, Table} from "react-bootstrap";
import Api from "common/Api";
import {AlertApiError, LoadingIndicator} from "components";

const TemplatePrintingPriceCalculator = ({accordionEventKey, templatePrintPrice}) => {
    const {t} = useTranslation();

    const [printingPriceLinkQuery, setPrintingPriceLinkQuery] = useState({
        numberOfPages: templatePrintPrice.rootContainerSectionPageCountMin
    });
    const [taxForPublicationOrders, setTaxForPublicationOrders] = useState(null);
    const [templatePrintingPrices, setTemplatePrintingPrices] = useState(null);
    const [printingPricesAvailable, setPrintingPricesAvailable] = useState(false);
    const [printingPricesAvailableForPageCount, setPrintingPricesAvailableForPageCount] = useState({
        available: false,
        pageCount: ''
    });
    const [minimumEdition, setMinimumEdition] = useState(1);

    const [formValues, setFormValues] = useState({
        pageCount: printingPriceLinkQuery.numberOfPages,
        edition: minimumEdition
    });
    const [formValidationErrors, setFormValidationErrors] = useState({});

    const [price, setPrice] = useState({
        costsNet: 0,
        grossPrice: 0
    });
    const [calculated, setCalculated] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setCalculated(false);

        const printingPriceLink = templatePrintPrice.printingPriceLink.withQueryParams(printingPriceLinkQuery);

        Api.get(printingPriceLink)
            .then(templatePrintingPriceRepresentationModel => {
                setTaxForPublicationOrders(templatePrintingPriceRepresentationModel.get('taxForPublicationOrders'));
                setPrintingPricesAvailable(templatePrintingPriceRepresentationModel.get('existPrinterPriceRegardlessOfPageCount'));

                const templatePrintingPrices = templatePrintingPriceRepresentationModel.get('templatePrintingPrices');
                setTemplatePrintingPrices(templatePrintingPrices);

                const priceAvailable = Array.isArray(templatePrintingPrices) && templatePrintingPrices.length > 0
                setPrintingPricesAvailableForPageCount(prevState => ({
                    ...prevState,
                    available: priceAvailable,
                    pageCount: printingPriceLinkQuery.numberOfPages
                }));

                const minEdition = priceAvailable ? templatePrintingPrices[0].quantityMinimum : 1;
                setMinimumEdition(minEdition);

                setFormValues(prevState => ({
                    ...prevState,
                    edition: prevState.edition < minEdition ? minEdition : prevState.edition
                }));

                setIsLoading(false);

            })
            .catch(apiError => {
                setApiError(apiError);
                setIsLoading(false);
            });
    }, [printingPriceLinkQuery, templatePrintPrice.printingPriceLink]);


    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const _calculate = () => {
        const price = {};
        let pricePerUnit = 0;
        if (printingPricesAvailable) {
            for (let i = templatePrintingPrices.length - 1; i >= 0; i--) {
                let templatePrintingPrice = templatePrintingPrices[i];
                if (templatePrintingPrice.quantityMinimum <= formValues.edition) {
                    pricePerUnit = templatePrintingPrice.pricePerUnit;
                    break;
                }
            }
        }

        const calculatedCostsValue = formValues.edition * pricePerUnit;
        price.costsNet = (calculatedCostsValue !== 0 ? Math.round(calculatedCostsValue * 100) / 100 : 0).toLocaleString();
        price.grossPrice = (calculatedCostsValue !== 0 ? Math.round((calculatedCostsValue * 1.07) * 100) / 100 : 0).toLocaleString();
        return price;
    }

    if (!calculated) {
        setPrice(_calculate());
        setCalculated(true);
    }

    const _handleChange = (event) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value
        });

        setFormValidationErrors(_findFormValidationErrors(event));

        setPrice(prevState => ({
            ...prevState,
            costsNet: 0,
            grossPrice: 0
        }));
    }

    const _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();

            const newErrors = _findFormValidationErrors(event);

            if (Object.keys(newErrors).length > 0) {
                setFormValidationErrors(newErrors);
            } else {
                if (event.target.name === "pageCount") {
                    setPrintingPriceLinkQuery(prevState => ({
                        ...prevState,
                        numberOfPages: formValues.pageCount
                    }));
                }
                if (event.target.name === "edition") {
                    setPrice(_calculate());
                }
            }
        }
    }

    const _findFormValidationErrors = (event) => {
        const newErrors = {};

        if (event.target.name === "pageCount") {
            const pageCount = event.target.value;
            if (!pageCount ||
                pageCount < templatePrintPrice.rootContainerSectionPageCountMin ||
                pageCount > templatePrintPrice.rootContainerSectionPageCountMax) {
                newErrors.pageCount = templatePrintPrice.rootContainerSectionPageCountMin === templatePrintPrice.rootContainerSectionPageCountMax ?
                    <Trans i18nKey={"template.details.printing.costs.page.count.validation.message"}
                           values={{number: templatePrintPrice.rootContainerSectionPageCountMin}}/>
                    :
                    <Trans i18nKey={"template.details.printing.costs.page.count.range.validation.message"}
                           values={{min: templatePrintPrice.rootContainerSectionPageCountMin, max: templatePrintPrice.rootContainerSectionPageCountMax}}/>;
            }
        }

        if (event.target.name === "edition") {
            const edition = event.target.value;
            if (!edition || edition < minimumEdition) {
                newErrors.edition = <Trans i18nKey={"template.details.printing.costs.edition.validation.message"}
                                           values={{minEdition: minimumEdition}}/>;
            }
        }

        return newErrors;
    }

    return (
        <Accordion.Item eventKey={accordionEventKey}>
            <Accordion.Header>
                {t('template.details.printing.costs')}
            </Accordion.Header>
            <Accordion.Body>
                {printingPricesAvailable ? (
                    <Alert variant={"warning"}>
                        <Trans i18nKey={"template.details.printing.costs.disclaimer"}
                               values={{tax: taxForPublicationOrders}}
                        />
                    </Alert>
                ) : (
                    <Alert variant={"warning"}>
                        {t('template.details.printing.costs.no.price.available')}
                    </Alert>
                )}

                {printingPricesAvailable &&
                <Row className={"justify-content-between"}>
                    <Col xs={12} md={12} lg={6}>
                        {printingPricesAvailableForPageCount.available &&
                        <Table className={"text-right-list"}>
                            <thead>
                            <tr>
                                <th>
                                    {t('template.details.printing.costs.edition.from')}
                                </th>
                                <th>
                                    {t('template.details.printing.costs.price.per.piece')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {templatePrintingPrices.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        {item.quantityMinimum}
                                    </td>
                                    <td>
                                        {item.pricePerUnit.toLocaleString()} &euro;
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        }
                    </Col>
                    <Col xs={12} md={12} lg={4} className={"print-costs"}>
                        <Card>
                            <Card.Header>
                                <p className={"h2"}>
                                    {t('template.details.printing.costs.calculator')}
                                </p>
                            </Card.Header>
                            <Card.Body>
                                {!printingPricesAvailableForPageCount.available &&
                                <Alert variant={"warning"}>
                                    <Trans i18nKey={"template.details.printing.costs.no.price.available.for.page.count"}
                                           values={{pageCount: printingPricesAvailableForPageCount.pageCount}}
                                    />
                                </Alert>
                                }
                                <Form>
                                    <Form.Group className={"mb-3"}>
                                        <Form.Label htmlFor={"pageCount"}>
                                            {t('template.details.printing.costs.page.count')}
                                        </Form.Label>
                                        <Form.Control type={"number"}
                                                      id={"pageCount"}
                                                      name={"pageCount"}
                                                      placeholder={t('template.details.printing.costs.page.count')}
                                                      value={formValues.pageCount}
                                                      onChange={event => _handleChange(event)}
                                                      onKeyPress={event => _handleKeyPress(event)}
                                                      isInvalid={!!formValidationErrors.pageCount}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            {formValidationErrors.pageCount}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className={"mb-3"}>
                                        <Form.Label htmlFor={"edition"}>
                                            {t('template.details.printing.costs.edition')}
                                        </Form.Label>
                                        <Form.Control type={"number"}
                                                      id={"edition"}
                                                      name={"edition"}
                                                      placeholder={t('template.details.printing.costs.edition')}
                                                      value={formValues.edition}
                                                      onChange={event => _handleChange(event)}
                                                      onKeyPress={event => _handleKeyPress(event)}
                                                      isInvalid={!!formValidationErrors.edition}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            {formValidationErrors.edition}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className={"price"}>
                                        <p>
                                            {price.costsNet} &euro; ({t('template.details.printing.costs.net')})
                                        </p>
                                        <p className={"gross-price"}>
                                            {price.grossPrice} &euro; ({t('template.details.printing.costs.gross')})
                                        </p>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
            </Accordion.Body>
        </Accordion.Item>
    );

};

export default TemplatePrintingPriceCalculator;