import Api from "common/Api";
import {activeFilterValues, deactivatedFilters} from "common/FilterUtils";
import {AlertApiError, SearchAndFilterView} from "components";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";

const _SpreadPublicationSearchAndFilter = ({onChange, searchFiltersLink}, ref) => {
    const {t} = useTranslation();

    const [apiError, setApiError] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState({
        pubTypeFilters: []
    });

    useImperativeHandle(ref, () => ({
        clearAllFilters() {
            const newFilters = {
                ...filters,
                pubTypeFilters: deactivatedFilters(filters.pubTypeFilters)
            };
            setFilters(newFilters);
            handleFiltersChange(searchText, newFilters);
        }
    }));

    useEffect(() => {
        if (searchFiltersLink) {
            Api.get(searchFiltersLink)
                .then(searchFilters => {
                    setFilters(prevState => ({
                        ...prevState,
                        pubTypeFilters: searchFilters.get("pubTypes")
                    }));
                })
                .catch(apiError => setApiError(apiError));
        }

    }, [searchFiltersLink]);

    const handleFiltersChange = (searchText, filters) => {
        onChange({
            searchText: searchText,
            pubTypeIds: activeFilterValues(filters.pubTypeFilters)
        });
    };

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
    };

    const handleSearchTextSubmitted = (searchText) => {
        setSearchText(searchText);
        handleFiltersChange(searchText, filters);
    };

    const handlePubTypeFiltersChange = (pubTypeFilters) => {
        const newFilters = {
            ...filters,
            pubTypeFilters: pubTypeFilters
        };
        setFilters(newFilters);
        handleFiltersChange(searchText, newFilters);
    };

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const search = {
        placeHolderText: t('spread.publication.search.field.place.holder'),
        searchText: searchText,
        onChange: handleSearchTextChange,
        onSubmit: handleSearchTextSubmitted
    };

    const filterGroups = [
        {
            title: t('spread.publication.search.filter.group.pubtype.title'),
            items: filters.pubTypeFilters,
            onChange: handlePubTypeFiltersChange
        }
    ];

    return (
        <SearchAndFilterView
            search={search}
            filterGroups={filterGroups}
        />
    );
};

const SpreadPublicationSearchAndFilter = forwardRef(_SpreadPublicationSearchAndFilter);

export default SpreadPublicationSearchAndFilter;