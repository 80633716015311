import Api from "common/Api";
import {useAuthentication} from "common/Authentication";
import {AlertApiError, LanguageSelect} from "components";
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Container, Nav, Navbar, NavbarBrand} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';

const HeaderNavbar = ({headerModel, currentUser}) => {
    const {t} = useTranslation();
    const [guiLanguageRepresentationModel, setGuiLanguageRepresentationModel] = useState(null);
    const [logoutInfoRepresentationModel, setLogoutInfoRepresentationModel] = useState(null);
    const [apiError, setApiError] = useState(null);

    const {isLoggedIn, onLogout} = useAuthentication();

    useEffect(() => {
        Api.get(headerModel.guiLanguageLink)
            .then(guiLanguageRepresentationModel => setGuiLanguageRepresentationModel(guiLanguageRepresentationModel))
            .catch(apiError => setApiError(apiError));

        if (isLoggedIn) {
            Api.get(headerModel.logoutInfoLink)
                .then(logoutInfoRepresentationModel => setLogoutInfoRepresentationModel(logoutInfoRepresentationModel))
                .catch(apiError => setApiError(apiError));
        }
    }, [isLoggedIn, headerModel.currentUserLink, headerModel.guiLanguageLink, headerModel.logoutInfoLink]);

    const userDisplayName =
        <>
            {currentUser?.login}
            {!headerModel.hideUserRole && <>&nbsp;({currentUser?.roleName})</>}
        </>;
    const logoutLink = logoutInfoRepresentationModel?.getLink('logout');
    const logoutDisplayName = logoutInfoRepresentationModel?.get('logoutDisplayName');
    const myTasksLink = headerModel.myTasksLink?.getLinkPathForRouting();
    const administrationHref = headerModel.administrationLink?.getHref();
    const myDataHref = headerModel.myDataLink?.getHref();

    const languageSelectModel = {
        selectedLanguage: guiLanguageRepresentationModel?.get('selectedLanguage'),
        languages: guiLanguageRepresentationModel?.get('languages'),
        switchLocaleLink: headerModel.switchLocaleLink
    };

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const supportsMultipleLanguages = (Array.isArray(languageSelectModel.languages) && languageSelectModel.languages.length > 1);

    return (
        <Container>
            <Navbar expand={"lg"} className={"navbar-genera"}>
                <Container fluid className={"p-0"}>
                    <NavbarBrand className={"d-lg-none"}>
                        {t('navigation.brand.mobile')}
                    </NavbarBrand>
                    <Navbar.Toggle aria-controls={"navbarNav"} aria-label={"Toggle navigation"}/>
                    <Navbar.Collapse id={"navbarNav"}>
                        <Nav as={"ul"}>
                            {isLoggedIn && (
                                <>
                                    <Nav.Item as={"li"} bsPrefix={"nav-item no-link"}>
                                        {myDataHref &&
                                            <Nav.Link href={myDataHref}>
                                                <i className={"fas fa-lg fa-user fa-fw"}/>&nbsp;{userDisplayName}
                                            </Nav.Link>
                                        }
                                        {!myDataHref &&
                                            <span>
                                                <i className={"fas fa-lg fa-user fa-fw"}/>&nbsp;{userDisplayName}
                                           </span>
                                        }
                                    </Nav.Item>
                                    {myTasksLink &&
                                        <Nav.Item as={"li"}>
                                            <Nav.Link as={Link} to={myTasksLink} className={"news-indicator"}>
                                                <i className={"fas fa-lg fa-tasks fa-fw"}/>&nbsp;{t('Index.my.task')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    }
                                    {administrationHref &&
                                        <Nav.Item as={"li"}>
                                            <Nav.Link href={administrationHref}>
                                                <i className={"fas fa-lg fa-cogs fa-fw"}/>&nbsp;{t('administration')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    }
                                    <Nav.Item as={"li"}>
                                        <Nav.Link onClick={() => onLogout(logoutLink)}>
                                            <i className={"fas fa-lg fa-sign-out-alt fa-fw"}/>&nbsp;{logoutDisplayName}
                                        </Nav.Link>
                                    </Nav.Item>
                                </>
                            )}
                            {supportsMultipleLanguages &&
                                <Nav.Item as={"li"} bsPrefix={"nav-item lang-item"}>
                                    <LanguageSelect languageSelectModel={languageSelectModel}/>
                                </Nav.Item>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Container>
    );
};

export default HeaderNavbar;