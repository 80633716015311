import {LinksCol, LinksSection, NewsCol, NewsSection} from "components";
import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";

/**
 * Sektion mit Links und News.
 * Gibt es nur einen Link, so wird es mit der News zusammen in einer Sektion dargestellt.
 *
 * @param linksModel - Beinhaltet Links und weitere Daten zum Anzeigen der Link-Kachel
 * @param newsModel - Beinhaltet Links zu News
 * @return {JSX.Element}
 * @constructor
 */
const LinksAndNewsSection = ({linksModel, newsModel}) => {
    const countOfExistLinks = () => {
        let count = 0;
        for (const link in linksModel.links) {
            if (linksModel.links[link]) {
                count++;
            }
        }
        return count;
    }

    if (countOfExistLinks() === 0) {
        return (
            <NewsSection>
                <NewsCol newsModel={newsModel}/>
            </NewsSection>
        );
    } else if (countOfExistLinks() === 1) {
        return (
            <section>
                <Container className={"px-3 px-lg-4 py-3"}>
                    <Row xs={{cols: 1}} md={{cols: 2}} lg={{cols: 2}} xl={{cols: 2}} className={"g-4 g-md-4"}>
                        <Col>
                            <LinksCol linksModel={linksModel}/>
                        </Col>
                        <Col>
                            <NewsCol newsModel={newsModel}
                                     onSameSectionWithLinks={true}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    } else {
        return (
            <>
                <LinksSection>
                    <LinksCol linksModel={linksModel}/>
                </LinksSection>
                <NewsSection>
                    <NewsCol newsModel={newsModel}/>
                </NewsSection>
            </>
        );
    }
}

export default LinksAndNewsSection;