import Api from "common/Api";
import {AlertApiError, NavigationButtonCard, NavigationCard} from "components";
import {AllTemplateImg} from "image";
import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

/**
 * Sektion mit Links zu Template-Übersicht und zuletzt verwendeten Templates.
 *
 * @param {Object} templateModel - Links zur Template-Übersicht-Api und zuletzt verwendeten Templates-Api
 * @return {JSX.Element}
 * @constructor
 */
const Templates = ({templateModel}) => {
    const {t} = useTranslation();
    const [recentlyUsedTemplateRepresentationModel, setRecentlyUsedTemplateRepresentationModel] = useState(null);
    const [apiError, setApiError] = useState(null);
    const templateSearchPath = templateModel.templateSearchLink.getLinkPathForRouting();
    const recentlyUsedTemplateLink = templateModel.recentlyUsedTemplateLink.withQueryParams({maxResult: 1});

    useEffect(() => {
        Api.get(recentlyUsedTemplateLink)
            .then(recentlyUsedTemplateRepresentationModel => setRecentlyUsedTemplateRepresentationModel(recentlyUsedTemplateRepresentationModel))
            .catch(apiError => setApiError(apiError));
    }, [recentlyUsedTemplateLink]);

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const templates = recentlyUsedTemplateRepresentationModel?.getEmbeddedRepresentationModelsByName("recentlyUsedTemplateRepresentationModels");

    return (
        <Row xs={{cols: 1}} md={{cols: 2}} className={"g-0"}>
            <Col>
                <NavigationButtonCard
                    link={templateSearchPath}
                    cardTextClassName={"h1"}
                    cardText={t('homepage.template.overview.title')}
                    cardImage={templateModel.homepageTemplatesImageFile || AllTemplateImg}
                    buttonTitle={t('homepage.template.overview.button.label')}
                />
            </Col>
            {Array.isArray(templates) && templates.map(template => (
                <Col key={template.get('templateId')} className={"d-none d-md-block"}>
                    <Link to={template.getLinkPathForRouting('template-details')}>
                        <NavigationCard
                            cardTextClassName={"h2 line-clamp"}
                            cardText={template.get('templateName')}
                            cardImage={template.getLinkPath('template-thumbnail')}
                        />
                    </Link>
                </Col>
            ))}
        </Row>
    );
};

export default Templates;