import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const NewsResultPageCard = ({newsPageRepresentationModel}) => {

    const {t} = useTranslation();

    return (
        <Row className={"g-3 px-4 pt-4 pb-5"}>
            <Col xs={12}>
                <Card className={"news-item"}>
                    <Row className={"g-0"}>
                        <Col xs={2} md={1} className={"p-2 news-date"}>
                            <p className={"m-0"}>
                                {newsPageRepresentationModel.get('newsValidFrom').dayOfMonth}
                                {t('day.ordinal.chars')}
                                <br/>
                                {t('month.' + newsPageRepresentationModel.get('newsValidFrom').month)}
                                <br/>
                                {newsPageRepresentationModel.get('newsValidFrom').year}
                            </p>
                        </Col>
                        <Col xs={10} md={11} className={"p-2"}>
                            <Card.Body>
                                <h1 className={"card-title"}>
                                    {newsPageRepresentationModel.get('newsHead')}
                                </h1>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={12} className={"news-content"}>
                <p className={"m-0"} dangerouslySetInnerHTML={{__html: newsPageRepresentationModel.get('newsText')}}>
                </p>
            </Col>
        </Row>
    );
}

export default NewsResultPageCard;