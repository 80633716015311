import PublicationStatus from "common/publication/PublicationStatus";
import React from "react";
import {Card, Col, Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

/**
 * Implementiert eine Kachel einer Kachelansicht
 *
 * @param publicationSearchPageRepresentationModel - Darstellungsmodell der Publikation-Suchseite
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchTileViewCard = ({publicationSearchPageRepresentationModel}) => {
    const {t} = useTranslation();
    const status = publicationSearchPageRepresentationModel.get('status');
    const publicationStatus = PublicationStatus.getPublicationStatus(status);

    return (
        <Col className={"tile template"}>
            <Link to={publicationSearchPageRepresentationModel.getLinkPathForRouting('publication-details')}>
                <Card>
                    <Card.Body bsPrefix={"card-body-image"}>
                        {publicationStatus && publicationStatus.cssClassName() &&
                            <span className={publicationStatus.cssClassName()}>
                                {t(publicationStatus.messageKey())}
                            </span>
                        }
                        <Image
                            fluid
                            src={publicationSearchPageRepresentationModel.getLinkPath('publication-thumbnail')}
                            className={"image-shadow"}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {publicationSearchPageRepresentationModel.get('publicationName')}
                    </Card.Footer>
                </Card>
            </Link>
        </Col>
    );
}
export default PublicationSearchTileViewCard;