import Api from "common/Api";
import {AlertApiError, NavigationButtonCard, NavigationCard} from "components";
import {AllPublicationImg} from "image";
import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

/**
 * Sektion mit Links zu Publikation-Übersicht und zuletzt verwendeten Publikationen.
 *
 * @param {Object} publicationModel - Links zur Publikation-Übersicht-Api und zuletzt verwendeten Publikation-Api
 * @return {JSX.Element}
 * @constructor
 */
const Publications = ({publicationModel}) => {
    const {t} = useTranslation();
    const [recentlyUsedPublicationRepresentationModel, setRecentlyUsedPublicationRepresentationModel] = useState(null);
    const [apiError, setApiError] = useState(null);
    const publicationSearchPath = publicationModel.publicationSearchLink.getLinkPathForRouting();
    const recentlyUsedPublicationLink = publicationModel.recentlyUsedPublicationLink.withQueryParams({maxResult: 1});

    useEffect(() => {
        Api.get(recentlyUsedPublicationLink)
            .then(recentlyUsedPublicationRepresentationModel => setRecentlyUsedPublicationRepresentationModel(recentlyUsedPublicationRepresentationModel))
            .catch(apiError => setApiError(apiError));
    }, [recentlyUsedPublicationLink]);

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const publications = recentlyUsedPublicationRepresentationModel?.getEmbeddedRepresentationModelsByName("recentlyUsedPublicationRepresentationModels");

    return (
        <Row xs={{cols: 1}} md={{cols: 2}} className={"g-0"}>
            <Col>
                <NavigationButtonCard
                    link={publicationSearchPath}
                    cardTextClassName={"h1"}
                    cardText={t('homepage.publication.overview.title')}
                    cardImage={publicationModel.homepagePublicationsImageFile || AllPublicationImg}
                    buttonTitle={t('homepage.publication.overview.button.label')}
                />
            </Col>
            {Array.isArray(publications) && publications.map(publication => (
                <Col key={publication.get('publicationId')} className={"d-none d-md-block"}>
                    <Link to={publication.getLinkPathForRouting('publication-details')}>
                        <NavigationCard
                            cardTextClassName={"h2 line-clamp"}
                            cardText={publication.get('publicationName')}
                            cardImage={publication.getLinkPath('publication-thumbnail')}/>
                    </Link>
                </Col>
            ))}
        </Row>
    );
};

export default Publications;