import UnitConstants from "common/UnitConstants";
import React from "react";
import {Accordion, Col, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const PublicationGeneralInformation = ({accordionEventKey, publicationDetails}) => {
    const {t} = useTranslation();

    return (
        <Accordion.Item eventKey={accordionEventKey}>
            <Accordion.Header>{t('publication.details.label')}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col>
                        <Table>
                            <tbody>
                            <tr>
                                <th>{t('publication.details.publication.id')}</th>
                                <td>{publicationDetails.publicationId}</td>
                            </tr>
                            <tr>
                                <th>{t('angelegt.am')}</th>
                                <td>{UnitConstants.DATE_TIME_FORMAT.format(publicationDetails.publicationCreateDate)}</td>
                            </tr>

                            <tr>
                                <th>{t('geaendert.am')}</th>
                                <td>{UnitConstants.DATE_TIME_FORMAT.format(publicationDetails.publicationDeepChangeDate)}</td>
                            </tr>
                            <tr>
                                <th>{t('organisatiion')}</th>
                                <td>{publicationDetails.publicationOrganizationName}</td>
                            </tr>
                            <tr>
                                <th>{t('autor')}</th>
                                <td>{publicationDetails.publicationOwnerFullName}</td>
                            </tr>
                            <tr>
                                <th>{t('publication.details.template.name')}</th>
                                <td>{publicationDetails.templateName}</td>
                            </tr>
                            <tr>
                                <th>{t('template.design')}</th>
                                <td>{publicationDetails.designName}</td>
                            </tr>
                            <tr>
                                <th>{t('template.pubType')}</th>
                                <td>{publicationDetails.pubTypeName}</td>
                            </tr>
                            <tr>
                                <th>{t('template.paperSize')}</th>
                                <td>{publicationDetails.paperSizeName}</td>
                            </tr>
                            <tr>
                                <th>{t('sprache')}</th>
                                <td>{publicationDetails.language}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default PublicationGeneralInformation;