import axios from "axios";
import ApiError from "./ApiError";

/**
 * "Password vergessen" Request um eine Passwortänderung anzustoßen. Es wird keine Rückmeldung gegeben, um dem Nutzer
 * keine Rückschlüsse zu erlauben, ob der Nutzername existiert.
 * (Bei erfolgreicher Bearbeitung wird eine E-Mail mit einem Link zum Ändern des Passworts verschickt)
 *
 * @param {String} username - Login dessen Passwort geändert werden soll
 */
const forgotPassword = (username) => {
    axios.get('/renewPassword/forgotPasswordLink.json', {
        params: {login: username}
    });
};

/**
 * Request um das Passwort zu ändern.
 *
 * @param {String} token - Token, mit dem die Passwortänderung authentifiziert wird
 * @param {String} newPassword - das neue Passwort
 * @returns {Promise<boolean>} Statusmeldung als Promise (true bei Erfolg, sonst false)
 */
const setNewPassword = (token, newPassword) => {
    return axios
        .post('/renewPassword/setNewPassword.json', null, {
            params: {
                token: token,
                newPassword: newPassword
            }
        })
        // nutzt serverseitig RdsResponse, deshalb das doppelte "response"
        .then(response => response.data.response.status === 0)
        .catch(() => {
            throw ApiError.unexpectedError();
        });
};

export {forgotPassword, setNewPassword};