import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Toolbox} from "components";
import Constants from "common/Constants";
import View from "common/View";
import SpreadPublicationViewMode from "common/publication/SpreadPublicationViewMode";

/**
 * Toolbox für verteilte Publikationen.
 *
 * @param onModusSelected - Callback um Modus zu aktivieren
 * @param ref - Reference
 * @return {JSX.Element}
 * @constructor
 */
const _SpreadPublicationSearchToolbox = ({onModusSelected}, ref) => {

    const {t} = useTranslation();
    const [activeModus, setActiveModus] = useState(null);

    useImperativeHandle(ref, () => ({
        exitToolBox() {
            setActiveModus(null);
        }
    }));

    const toolboxModus = [
        {
            title: t('spread.publication.search.tool.box.mark.read'),
            iconCssClass: 'fa-check',
            key: SpreadPublicationViewMode.MODE.MARK_READ
        }
    ];

    const toolboxModusSelected = (modus) => {
        setActiveModus(modus);

        switch (modus.key) {
            case SpreadPublicationViewMode.MODE.MARK_READ:
                onModusSelected({
                    pageSize: Constants.INTEGER_MAX_VALUE,
                    view: new View(View.TYPE.LIST, View.SELECTION_TYPE.MULTI),
                    spreadPublicationViewMode: SpreadPublicationViewMode.MODE.MARK_READ
                });
                break;
            default:
                throw new Error("Unbekannter Modus: " + modus.key);
        }
    };

    return (
        <Col xs={12} md={6} lg={12}>
            <Toolbox
                items={toolboxModus}
                activeKey={activeModus ? activeModus.key : null}
                toolboxItemSelected={toolboxModusSelected}
            />
        </Col>
    );
};

const SpreadPublicationSearchToolbox = forwardRef(_SpreadPublicationSearchToolbox);

export default SpreadPublicationSearchToolbox;