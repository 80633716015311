import React from "react";
import {NoResults, SpreadPublicationListView, SpreadPublicationTileView} from "components";

/**
 * Defaultmodus der verteilten Publikation.
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodelle der verteilten Publikationen
 * @param {View} view - Ansicht
 * @returns {JSX.Element}
 * @constructor
 */
const SpreadPublicationDefaultViewMode = ({spreadPublicationSearchPageRepresentationModels, view}) => {

    if (!Array.isArray(spreadPublicationSearchPageRepresentationModels) || spreadPublicationSearchPageRepresentationModels.length === 0) {
        return (
            <NoResults/>
        );
    }

    return (
        <>
            {view.isTileView() ?
                <SpreadPublicationTileView
                    spreadPublicationSearchPageRepresentationModels={spreadPublicationSearchPageRepresentationModels}
                /> :
                <SpreadPublicationListView
                    spreadPublicationSearchPageRepresentationModels={spreadPublicationSearchPageRepresentationModels}
                    listView={view}
                />
            }
        </>
    )
}

export default SpreadPublicationDefaultViewMode;