/**
 * Werte für Auswahl der Autors (Eigene/Andere)
 *
 * @type {{OWN: string, OTHERS: string}}
 */
const PublicationAuthor = {
    OWN: 'OWN',
    OTHERS: 'OTHERS'
}

export default PublicationAuthor;