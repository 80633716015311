import {
    AlertApiError,
    LoadingIndicator,
    TemplateInfo,
    useRepresentationModel
} from "components";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const TemplateDetails = () => {
    const {representationModel, isLoading, apiError} = useRepresentationModel();

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return <AlertApiError apiError={apiError}/>;
    }

    const productDetails = {
        designName: representationModel?.get('designName'),
        pubTypeName: representationModel?.get('pubTypeName'),
        paperSizeName: representationModel?.get('paperSizeName'),
        colorName: representationModel?.get('colorName'),
        contentType: representationModel?.get('contentType'),
        language: representationModel?.get('language')
    }

    const templateData = {
        templateId: representationModel?.get('templateId'),
        templateName: representationModel?.get('templateName'),
        description: representationModel?.get('templateDescription'),
        rootContainerSectionPageCountMin: representationModel?.get('rootContainerSectionPageCountMin'),
        rootContainerSectionPageCountMax: representationModel?.get('rootContainerSectionPageCountMax'),
        dynamic: representationModel?.get('dynamic'),
        nettoWidth: representationModel?.get('nettoWidth'),
        bestPracticePdfLink: representationModel?.getLink('best-practice'),
        templateThumbnailLink: representationModel?.getLink('template-thumbnail'),
        printingPriceLink: representationModel?.getLink('printing-price'),
        publicationCreateLink: representationModel?.getLink('create-publication'),
        possibleCurrentUserOrganizationsLink: representationModel?.getLink('possible-current-user-organizations'),
        contentLanguagesLink: representationModel?.getLink('content-languages'),
        productDetails: productDetails
    }

    return (
        <section>
            <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                <Row>
                    <Col>
                        <Row className={"template-detail"}>
                            <Col>
                                <Row className={"template-name"}>
                                    <Col>
                                        <h1>{templateData.templateName}</h1>
                                    </Col>
                                </Row>
                                <TemplateInfo templateData={templateData}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TemplateDetails;