import Api from "common/Api";
import {AlertApiError, LoadingIndicator, NewsResultPageCard} from "components";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

class NewsDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newsDetailsRepresentationModel: null,
            isLoading: true,
            apiError: null,
        };
    }

    componentDidMount() {
        Api.getRepresentationModel()
            .then(newsDetailsRepresentationModel =>
                this.setState({
                    newsDetailsRepresentationModel: newsDetailsRepresentationModel,
                    isLoading: false
                })
            )
            .catch(apiError =>
                this.setState({
                    apiError: apiError,
                    isLoading: false
                })
            );
    }

    render() {
        const {newsDetailsRepresentationModel, isLoading, apiError} = this.state;

        if (isLoading) {
            return <LoadingIndicator/>;
        }

        if (apiError) {
            return (<AlertApiError apiError={apiError}/>);
        }

        return (
            <section>
                <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                    <Row>
                        <Col>
                            <Row className={"template-detail"}>
                                <Col>
                                    <NewsResultPageCard newsPageRepresentationModel={newsDetailsRepresentationModel}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default NewsDetails;