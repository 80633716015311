import React from "react";
import {Badge, Card} from "react-bootstrap";

/**
 *
 * Link-Kachel mit Text und Bild.
 *
 * @param {String} name - Titel
 * @param {?String} badgeText - Badge Text (optional)
 * @param {String} image - URL des Hintergrundbilds
 * @param {String} href - URL des Links
 * @return {JSX.Element}
 * @constructor
 */
const LinksCard = ({name, badgeText, image, href}) => {
    return (
        <Card className={"card-bg"} style={{backgroundImage: `url(${image})`}}>
            <Card.Body>
                <Card.Text>
                    <span className={"h1 position-relative mb-0"}>
                        {name}
                        {typeof badgeText === 'string' &&
                            <Badge
                                pill bg={"new"}
                                className={"position-absolute top-0 start-100 translate-middle"}
                            >
                                {badgeText}
                            </Badge>

                        }
                    </span>
                </Card.Text>

                {/*als normaler Link statt als Router-Link,*/}
                {/*solange wir noch Links zu SmartClient Seiten haben*/}
                <a href={href} className={"stretched-link"}/>
            </Card.Body>
        </Card>
    );
};

export default LinksCard;