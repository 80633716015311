import axios from "axios";
import ApiError from "./ApiError";
import Constants from "./Constants";
import Link from "./Link";
import RepresentationModel from "./RepresentationModel";

/**
 * Implementiert die gängigen Request-Methoden für die Kommunikation mit dem Backend
 *
 * @author okilic <okilic@geneon.de>
 */
export default class Api {

    /**
     * Führt einen Get-Request zum Link und liefert das Ergebnis als {@link RepresentationModel}
     *
     * @param link Link zu dem der Request angestoßen wird
     * @throws {ApiError}
     * @return {Promise<RepresentationModel>}
     */
    static get = async (link) => {
        return this._do('GET', link, null);
    };

    /**
     * Führt einen Post-Request zum Link und liefert das Ergebnis als {@link RepresentationModel}
     *
     * @param link Link zu dem der Request angestoßen wird
     * @param data Daten die mit dem Post-Request mit geschickt werden
     * @throws {ApiError}
     * @return {Promise<RepresentationModel>}
     */
    static post = async (link, data) => {
        return this._do('POST', link, data);
    };

    /**
     * Führt einen Put-Request zum Link und liefert das Ergebnis als {@link RepresentationModel}
     *
     * @param link Link zu dem der Request angestoßen wird
     * @param data Daten die mit dem Put-Request mit geschickt werden
     * @throws {ApiError}
     * @return {Promise<RepresentationModel>}
     */
    static put = async (link, data) => {
        return this._do('PUT', link, data);
    };

    /**
     * Führt einen Delete-Request zum Link und liefert das Ergebnis als {@link RepresentationModel}
     *
     * @param link Link zu dem der Request angestoßen wird
     * @throws {ApiError}
     * @return {Promise<RepresentationModel>}
     */
    static delete = async (link) => {
        return this._do('DELETE', link, null);
    };

    /**
     * Liefert ein RepresentationModel-Promise
     *
     * @throws {ApiError}
     * @return {Promise<RepresentationModel>}
     */
    static getRepresentationModel() {
        const protocol = window.location.protocol ? window.location.protocol + '//' : '';
        const hostname = window.location.hostname;
        const port = window.location.port ? ':' + window.location.port : '';
        let pathName = window.location.pathname ? window.location.pathname : '';

        // Constants.HTML_SUFFIX wird entfernt, weil die HalJson-Controller ohne Suffix implementiert sind.
        pathName = pathName.replace(Constants.HTML_SUFFIX, '');

        const queryString = window.location.search ? window.location.search : '';

        // Constants.API_PATH_PREFIX wird hier hinzugefügt um im Backend es mappen zu können
        const href = protocol + hostname + port + Constants.API_PATH_PREFIX + pathName + queryString;
        return this.get(new Link(href));
    }

    /**
     * Liefert den IndexRepresentationModel-Promise
     *
     * @throws {ApiError}
     * @return {Promise<RepresentationModel>} IndexRepresentationModel-Promise
     */
    static getIndexRepresentationModel() {
        const protocol = window.location.protocol ? window.location.protocol + '//' : '';
        const hostname = window.location.hostname;
        const port = window.location.port ? ':' + window.location.port : '';

        const href = protocol + hostname + port + Constants.API_PATH_PREFIX + Constants.INDEX_API_PATH;
        return this.get(new Link(href));
    }

    static _do = async (method, link, body) => {
        if (!link) {
            const message = 'Link wurde nicht gesetzt.';
            console.error(message);
            return;
        }

        const headers = {
            'Accept': 'application/hal+json;charset=UTF-8',
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return await axios({
            method: method,
            url: link.toPathAndQueryString(),
            data: body,
            headers: headers
        })
            .then(response => {
                console.log('response', response);

                const responseURL = response.request.responseURL;
                if (responseURL.endsWith("/login.html")) {
                    window.location.href = responseURL;
                }

                return new RepresentationModel(response.data);
            })
            .catch(error => {
                if (error.response) {
                    throw new ApiError(error.response.data, error.response.status);
                } else {
                    // Ungewöhnlicher Fall, kann aber unter folgenden Umständen vorkommen:
                    // * wenn der Client keine Antwort erhält
                    // * wenn der Request vom Client nicht verschickt werden kann
                    // * oder aus irgendeinem anderen Grund
                    throw ApiError.unexpectedError();
                }
            });
    };
}