import EmbeddedRepresentationModel from "./EmbeddedRepresentationModel";
import Link from "./Link";

/**
 * Implementiert ein Modell, um haljson Daten zu beschreiben.
 *
 * <p> Beispiel:
 {
  "id": "1234",  // diese Eigenschaft wird in diesem Model zum "data" gepackt und der Wert kann über die Name "id" geholt werden
  "name": "test template", // diese Eigenschaft wird in diesem Model zum "data" gepackt und der Wert kann über die Name "name" geholt werden
  "_links": { // Diese Links werden mit den rels in diesem Model zu dem "relLinkMap" hinzugefügt: <self, http://genera5.de/api/template/test-template>
    "self": {
      "href": "http://genera5.de/api/template/test-template"
    }
  },
  "_embedded": { // Die eingebetteten Daten werden zur EmbeddedRepresentationModel-Liste hinzugefügt
    "publication": {
      "id": "4563",
      "name": "test-publication"
      "_links": {
        "self": {
          "href": "http://genera5.de/api/publication/test-publication"
        }
      }
    }
  }
}
 *
 * </p>
 *
 * @author okilic <okilic@geneon.de>
 */
export default class RepresentationModel {
    /**
     * Modeldaten
     *
     * @type {any}
     */
    data = null;

    /**
     * Ein Map von 'rels' und Links
     *
     * @type {Map}
     */
    relLinkMap = new Map();

    /**
     * Die eingebetteten RepresentationModelle
     *
     * @type {[EmbeddedRepresentationModel]}
     */
    embeddedRepresentationModels = [];

    constructor(data) {
        this.data = data;
        this._addToRelLinkMap(data);
        this._addToEmbeddedRepresentationModels(data);
    }

    /**
     * Liefert zu dem übergebenen Eigenschaftsnamen die Eigenschaft
     *
     * @param propertyName Eigenschaftsname
     * @return {*|null} Den Eigenschaft, wenn es existiert, sonst NULL
     */
    get(propertyName) {
        return this.data ? this.data[propertyName] : null;
    }

    /**
     * Überprüft, ob zu dem übergebenen <code>rel</code> ein Link existiert
     *
     * @param rel
     * @return {boolean} Liefert TRUE, wenn es existiert, sonst FALSE.
     */
    hasRel(rel) {
        return this.relLinkMap.has(rel);
    }

    /**
     * Liefert zu dem übergebenen <code>rel</code> den Link
     *
     * @param rel
     * @return {Link} Den Link, wenn es existiert sonst NULL
     */
    getLink(rel) {
        return this.relLinkMap.get(rel);
    }

    /**
     * Liefert zu dem übergebenen <code>rel</code> den Link als String
     *
     * @param rel
     * @return {string} Den Link als String, wenn es existiert sonst NULL
     */
    getHref(rel) {
        return this.relLinkMap.get(rel)?.getHref();
    }

    /**
     * Liefert zu dem übergebenen <code>rel</code> den URL-Pfad
     *
     * @param rel
     * @return {string} Den URL-Pfad, wenn es existiert sonst NULL
     */
    getLinkPath(rel) {
        return this.relLinkMap.get(rel)?.getLinkPath();
    }

    /**
     * Liefert zu dem übergebenen <code>rel</code> den URL-Pfad für die Routing
     *
     * @param rel
     * @return {String} Den URL-Pfad für die Routing, wenn es existiert sonst NULL
     */
    getLinkPathForRouting(rel) {
        return this.relLinkMap.get(rel)?.getLinkPathForRouting();
    }

    /**
     * Liefert zu dem übergebenen Namen die eingebetteten RepresentationModelle
     *
     * @param name Name des eingebetteten Objekts
     * @return {null|[RepresentationModel]} Liste der RepresentationModelle, wenn solche existieren, sonst NULL
     */
    getEmbeddedRepresentationModelsByName(name) {
        for (let i = 0; i < this.embeddedRepresentationModels.length; i++) {
            let embeddedRepresentationModel = this.embeddedRepresentationModels[i];
            if (name === embeddedRepresentationModel.getName()) {
                return embeddedRepresentationModel.getRepresentationModels();
            }
        }
        return null;
    }

    /**
     *
     * Liefert zu dem übergebenen Namen das erste vorhandene eingebettete RepresentationModel
     * <p>
     *      Diese Funktion sollte nur dann verwendet werden, wenn ein RepresentationModel mit dem übergebenen Namen eingebettet wurde
     * </p>
     *
     * @param name Name des eingebetteten Objekts
     * @return {null|RepresentationModel} den RepresentationModel, wenn solche existiert, sonst NULL
     */
    getEmbeddedRepresentationModelByName(name) {
        const embeddedResources = this.getEmbeddedRepresentationModelsByName(name);
        if (embeddedResources) {
            return embeddedResources[0];
        }
        return null;
    }

    _addToRelLinkMap(data) {
        const _links = data._links;
        if (_links) {
            Object.keys(_links).forEach(rel => {
                this.relLinkMap.set(rel, new Link(_links[rel]["href"]));
            });
        }
    }

    _addToEmbeddedRepresentationModels(data) {
        const _embedded = data._embedded;
        if (_embedded) {
            Object.keys(_embedded).forEach(embeddedRepresentationModelName => {
                if (Array.isArray(_embedded[embeddedRepresentationModelName])) {
                    _embedded[embeddedRepresentationModelName].forEach(data => {
                        this._handleEmbeddedRepresentationModelAdding(data, embeddedRepresentationModelName);
                    });
                } else {
                    const data = _embedded[embeddedRepresentationModelName];
                    this._handleEmbeddedRepresentationModelAdding(data, embeddedRepresentationModelName);
                }
            });
        }
    }

    _handleEmbeddedRepresentationModelAdding(data, embeddedRepresentationModelName) {
        let representationModel = new RepresentationModel(data);
        let representationModels = this.getEmbeddedRepresentationModelsByName(embeddedRepresentationModelName);
        if (representationModels) {
            representationModels.push(representationModel);
        } else {
            this.embeddedRepresentationModels.push(new EmbeddedRepresentationModel(embeddedRepresentationModelName, representationModel))
        }
    }
}