import React from "react";
import {Row} from "react-bootstrap";
import {SpreadPublicationTileViewCard} from "components";

/**
 * Implementiert eine Kachelansicht für die verteilte Publikationen.
 *
 * @param spreadPublicationSearchPageRepresentationModels - Darstellungsmodelle der verteilten Publikationen
 * @returns {JSX.Element}
 * @constructor
 */
const SpreadPublicationTileView = ({spreadPublicationSearchPageRepresentationModels}) => {

    return (
        <Row xs={{cols: 1}} md={{cols: 2}} xl={{cols: 3}} className={"g-5 px-4 py-5"}>
            {spreadPublicationSearchPageRepresentationModels.map(spreadPublicationSearchPageRepresentationModel => (
                <SpreadPublicationTileViewCard
                    key={spreadPublicationSearchPageRepresentationModel.get('spreadPublicationId').toString()}
                    spreadPublicationSearchPageRepresentationModel={spreadPublicationSearchPageRepresentationModel}
                />
            ))}
        </Row>
    );
}

export default SpreadPublicationTileView;