export default class PublicationStatus {

    /**
     * Neu
     */
    static NEW = new PublicationStatus('NEW', null);

    /**
     * In Bearbeitung
     */
    static EDIT = new PublicationStatus('EDIT', 'in-process');

    /**
     * Fertig
     */
    static CHECK = new PublicationStatus('CHECK', 'finished');

    /**
     * Freigegeben
     */
    static RELEASE = new PublicationStatus('RELEASE', 'released');

    /**
     * Archiviert
     */
    static HISTORY = new PublicationStatus('HISTORY', 'history');

    /**
     * Ungültig
     */
    static INVALID = new PublicationStatus('INVALID', 'invalid');

    constructor(statusName, statusCssClassName) {
        this.statusName = statusName;
        this.statusCssClassName = statusCssClassName;
    }

    /**
     * Name des {@link PublicationStatus}
     *
     * @return {string}
     */
     name() {
        return this.statusName;
    }

    /**
     * Liefert den CssClassName
     *
     * @return {string | null}
     */
    cssClassName() {
         return this.statusCssClassName;
    }

    /**
     * Liefert den Message-Key
     *
     * @return {string}
     */
     messageKey() {
        return 'publication.status.' + this.statusName;
    }

    /**
     * Überprüft, ob dieses Status in der Liste <code>statuses</code> vorkommt
     *
     * @param statuses {[PublicationStatus]}
     * @return TRUE, dieses Status kommt in der Liste <code>statuses</code> vor, sonst FALSE
     */
    isOneOfThem(statuses) {
         return Array.isArray(statuses) && statuses.includes(this);
    }

    /**
     * Ermittelt anhand der Name den {@link PublicationStatus}
     *
     * @param name Name
     * @return {PublicationStatus|null}
     */
    static getPublicationStatus(name) {
        switch (name) {
            case PublicationStatus.NEW.statusName:
                return PublicationStatus.NEW;
            case PublicationStatus.EDIT.statusName:
                return PublicationStatus.EDIT;
            case PublicationStatus.CHECK.statusName:
                return PublicationStatus.CHECK;
            case PublicationStatus.RELEASE.statusName:
                return PublicationStatus.RELEASE;
            case PublicationStatus.HISTORY.statusName:
                return PublicationStatus.HISTORY;
            case PublicationStatus.INVALID.statusName:
                return PublicationStatus.INVALID;
            default:
                return null;
        }
    }
}