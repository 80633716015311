import PublicationStatus from "common/publication/PublicationStatus";
import {PublicationDownloads, PublicationGeneralInformation, PublicationMetadata} from "components";
import React from "react";
import {Accordion, Alert, Button, Col, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const PublicationInfo = ({publicationData, onMetadataChange}) => {
    const {t} = useTranslation();

    const publicationStatus = PublicationStatus.getPublicationStatus(publicationData.publicationStatus);

    const _editPublication = () => {
        window.location.href = publicationData.editPublicationHref;
    }

    return (
        <Row className={"template-info px-4 py-4 gap-4"}>
            <Col xs={12}>
                <Row className={"g-5"}>
                    <Col xs={12} md={12} lg={4}>
                        <p className={"thumbnail-wrapper img-thumbnail"}>
                            {publicationStatus && publicationStatus.cssClassName() &&
                                <span className={publicationStatus.cssClassName()}>{t(publicationStatus.messageKey())}</span>
                            }
                            <Image
                                fluid
                                className={"image-shadow"}
                                src={publicationData.publicationThumbnailLink?.getLinkPath()}
                            />
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={5} className={"form-col"}>
                        {(publicationData.testPublication || publicationData.templateObsolete) &&
                            <Alert variant={"warning"} className={"mb-5"}>
                                {publicationData.testPublication &&
                                    <p>
                                        {t('publication.info.test.publication.alert.text')}
                                    </p>
                                }
                                {publicationData.templateObsolete &&
                                    <p>
                                        {t('publication.info.obsolete.publication.alert.text')}
                                    </p>
                                }
                            </Alert>
                        }
                        <PublicationMetadata publicationData={publicationData} onChange={onMetadataChange}/>
                        <div className={"mb-3"}>
                            <p>{publicationData.publicationDescription}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={3}>
                        <p className={"text-end"}>
                            <Button
                                variant={"primary"}
                                size={"lg"}
                                className={"create-publication"}
                                onClick={() => _editPublication()}
                            >
                                {t('publication.info.edit.publication.button.label')}
                            </Button>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                <Accordion id={"accordionPublicationDetails"} alwaysOpen={true} defaultActiveKey={['0']}>
                    <PublicationGeneralInformation
                        accordionEventKey={"0"}
                        publicationDetails={publicationData.publicationDetails}
                    />
                    <PublicationDownloads
                        accordionEventKey={"1"}
                        publicationDownloads={publicationData.publicationDownloads}
                    />
                </Accordion>
            </Col>
        </Row>
    );
};

export default PublicationInfo;