import Api from "common/Api";
import {AlertApiError, NewsRow} from "components";
import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";

/**
 * Komponente für die Anzeige von News-Kachel und gegebenenfalls von News-Teaser mit dem letzten Newsbeitrag.
 *
 * @param news - Beinhaltet Links zu News
 * @param onSameSectionWithLinks - Gibt an, ob die News mit den Links die gleiche Sektion teilen
 * @return {JSX.Element}
 * @constructor
 */
const NewsCol = ({newsModel, onSameSectionWithLinks}) => {

    const [lastNewsRepresentationModel, setLastNewsRepresentationModel] = useState(null);
    const [apiError, setApiError] = useState(null);

    const lastNewsLink = newsModel.lastNewsLink.withQueryParams({maxResult: 1});

    useEffect(() => {
        Api.get(lastNewsLink)
            .then(lastNewsRepresentationModel => setLastNewsRepresentationModel(lastNewsRepresentationModel))
            .catch(apiError => setApiError(apiError));
    }, [lastNewsLink]);

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const lastNews = lastNewsRepresentationModel?.getEmbeddedRepresentationModelsByName("lastNewsRepresentationModels");

    return (
        <Col className={"tile news"}>
            <NewsRow newsModel={newsModel}
                     lastNews={lastNews}
                     onSameSectionWithLinks={onSameSectionWithLinks}
            />
        </Col>
    );
};

export default NewsCol;