import Api from "common/Api";
import {AuthenticationProvider} from "common/Authentication";
import ProtectedRoute from "common/ProtectedRoute";
import RedirectRoute from "common/RedirectRoute";
import overrideCssStyles from "common/OverrideCssStyles";
import {
    AlertApiError,
    Footer,
    Header,
    HomePage,
    LoadingIndicator,
    LoginPage,
    MetaData,
    MyTasks,
    News,
    NewsDetails,
    PublicationDetails,
    PublicationSearch,
    RenewPasswordPage,
    SpreadPublicationDetails,
    SpreadPublicationSearch,
    TemplateDetails,
    TemplateSearch,
    TemplatesEventDetails
} from "components";
import React, {Suspense} from 'react';
import {HelmetProvider} from "react-helmet-async";
import {withTranslation} from "react-i18next";
import {Route, Routes} from 'react-router-dom';
import UserContext from "common/user/UserContext";
import UserProvider from "common/user/UserProvider";
import './styles/App.scss';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            indexRepresentationModel: null,
            isLoading: true,
            apiError: null
        };
    }

    componentDidMount() {
        this._loadIndexResource();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Nach einem Sprachwechsel müssen die Ressourcen nochmal geladen werden
        if (this.props.t !== prevProps.t) {
            this._loadIndexResource();
        }

        overrideCssStyles(this.state.indexRepresentationModel);
    }

    _loadIndexResource() {
        this.setState({
            isLoading: true,
            apiError: null
        });

        Api.getIndexRepresentationModel()
            .then(indexRepresentationModel => {
                console.log('indexRepresentationModel', indexRepresentationModel);
                this.setState({
                    indexRepresentationModel: indexRepresentationModel,
                    isLoading: false
                });
            })
            .catch(apiError => {
                this.setState({
                    apiError: apiError,
                    isLoading: false
                });
            });
    }

    render() {
        const {indexRepresentationModel, isLoading, apiError} = this.state;

        const mmpRedirectUrl = indexRepresentationModel?.get('mmpRedirectUrl');

        const maintenanceActive = indexRepresentationModel?.get('maintenanceActive');
        const headerModel = {
            hideUserRole: indexRepresentationModel?.get('systemSettingsCustomLayout')?.hideUserRole,
            myDataLink: indexRepresentationModel?.getLink('my-data'),
            myTasksLink: indexRepresentationModel?.getLink('my-tasks'),
            administrationLink: indexRepresentationModel?.getLink('administration'),
            logoutInfoLink: indexRepresentationModel?.getLink('logout-info'),
            guiLanguageLink: indexRepresentationModel?.getLink('gui-language'),
            switchLocaleLink: indexRepresentationModel?.getLink('switch-locale'),
            maintenanceWarning: indexRepresentationModel?.get('maintenanceWarning'),
            maintenanceActive: maintenanceActive,
            maintenanceMessage: indexRepresentationModel?.get('loggedIn') ? indexRepresentationModel?.get('maintenanceShortMessage') : indexRepresentationModel?.get('maintenanceLongMessage'),
            logoImageFile: indexRepresentationModel?.get('logoImageFile'),
            brandImageFile: indexRepresentationModel?.get('brandImageFile')
        };

        const footerModel = {
            helpLink: indexRepresentationModel?.getLink('help')
        };

        const homePageLink = indexRepresentationModel?.getLink('homepage');

        const metaDataModel = {
            projectFontCssRelativeUrl: indexRepresentationModel?.get('systemSettingsFontModel')?.fontCssRelativeUrl,
            projectIconFontCssRelativeUrl: indexRepresentationModel?.get('systemSettingsFontModel')?.iconFontCssRelativeUrl
        }

        const initialLoginStatus = indexRepresentationModel?.get('loggedIn') === true;
        const supportsSingleSignOn = indexRepresentationModel?.get('supportsSingleSignOn') === true;

        const currentUserLink = indexRepresentationModel?.getLink('current-user');

        if (isLoading) {
            return <LoadingIndicator/>;
        }

        if (apiError) {
            return <AlertApiError apiError={apiError}/>;
        }

        return (
            <AuthenticationProvider initialLoginStatus={initialLoginStatus}
                                    maintenanceActive={maintenanceActive}
                                    onLogInStatusChange={() => this._loadIndexResource()}>
                <UserProvider currentUserLink={currentUserLink}>
                    <HelmetProvider>
                        <Suspense fallback={<LoadingIndicator/>}>
                            <MetaData metaDataModel={metaDataModel}/>
                            <div className={"d-flex flex-column min-vh-100"}>
                                <Header headerModel={headerModel}/>
                                <Routes>
                                    {/*
                                Jeder Pfad, die hier definiert wird, sollte im Backend im SinglePageAppController auch ein entsprechendes Mapping haben. Da sonst
                                eine Aktualisierung der Seite über Browser nicht funktionieren würde.
                                Wichtig: Der Pfad, der definiert wird, sollte .html-Suffix haben, damit es im Backend zugeordnet werden kann
                                */}
                                    <Route path="/login.html"
                                           element={
                                               <RedirectRoute mmpRedirectUrl={mmpRedirectUrl}>
                                                   <LoginPage supportsSingleSignOn={supportsSingleSignOn}/>
                                               </RedirectRoute>
                                           }
                                    />
                                    <Route path="/renewPassword.html"
                                           element={
                                                <RedirectRoute mmpRedirectUrl={mmpRedirectUrl}>
                                                    <RenewPasswordPage/>
                                                </RedirectRoute>}
                                    />
                                    <Route path="/"
                                           element={
                                               <ProtectedRoute>
                                                   <HomePage homePageLink={homePageLink}/>
                                               </ProtectedRoute>
                                           }
                                    />
                                    <Route path="/index.html"
                                           element={
                                               <ProtectedRoute>
                                                   <HomePage homePageLink={homePageLink}/>
                                               </ProtectedRoute>
                                           }
                                    />
                                    <Route path="/my-tasks.html"
                                           element={<ProtectedRoute><MyTasks/></ProtectedRoute>}
                                    />
                                    <Route path="/template-search.html"
                                           element={<ProtectedRoute><TemplateSearch/></ProtectedRoute>}
                                    />
                                    <Route path="/template/:id/details.html"
                                           element={<ProtectedRoute><TemplateDetails/></ProtectedRoute>}
                                    />
                                    <Route path="/templates/event/details.html"
                                           element={<ProtectedRoute><TemplatesEventDetails/></ProtectedRoute>}
                                    />
                                    <Route path="/publication-search.html"
                                           element={
                                        <ProtectedRoute>
                                            <UserContext.Consumer>
                                                {currentUser => (
                                                    <PublicationSearch
                                                        currentUser={currentUser}
                                                    />
                                                )}
                                            </UserContext.Consumer>
                                        </ProtectedRoute>}
                                    />
                                    <Route path="/publication/:id/details.html"
                                           element={<ProtectedRoute><PublicationDetails/></ProtectedRoute>}
                                    />
                                    <Route path="/news.html"
                                           element={<ProtectedRoute><News/></ProtectedRoute>}
                                    />
                                    <Route path="/news/:id/details.html"
                                           element={<ProtectedRoute><NewsDetails/></ProtectedRoute>}
                                    />
                                    <Route path="/spread-publication-search.html"
                                           element={<ProtectedRoute><SpreadPublicationSearch/></ProtectedRoute>}
                                    />
                                    <Route path="/spread-publication/:id/details.html"
                                           element={<ProtectedRoute><SpreadPublicationDetails/></ProtectedRoute>}
                                    />
                                </Routes>
                                <Footer footerModel={footerModel}/>
                            </div>
                        </Suspense>
                    </HelmetProvider>
                </UserProvider>
            </AuthenticationProvider>
        );
    }
}

export default withTranslation()(App);
