import React, {useEffect, useState} from "react";
import Api from "common/Api";
import Constants from "common/Constants";
import PageRequest from "common/PageRequest";
import {Col, Row} from "react-bootstrap";
import {
    AlertApiError,
    LoadingIndicator,
    Pagination,
    SpreadPublicationDefaultViewMode,
    SpreadPublicationMarkReadViewMode
} from "components";
import SpreadPublicationViewMode from "common/publication/SpreadPublicationViewMode";

const SpreadPublicationPageView = ({
                                       spreadPublicationSearchPageModel,
                                       spreadPublicationFilters,
                                       sort,
                                       pageViewModel,
                                       exitModeButtonClicked
                                   }) => {
    const {pageSize, spreadPublicationViewMode, view} = pageViewModel;

    const [pageRepresentationModel, setPageRepresentationModel] = useState(null);
    const [currentPage, setCurrentPage] = useState(Constants.FIRST_PAGE_NUMBER); // Initial wird die erste Seite geladen

    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const loadSpreadPublications = () => {
        const queryParams = {};
        Object.assign(queryParams, spreadPublicationFilters);
        const pageRequest = new PageRequest(currentPage, pageSize, sort);
        Object.assign(queryParams, pageRequest.toModel());

        const spreadPublicationSearchPageLink = spreadPublicationSearchPageModel.spreadPublicationSearchPageLink.withQueryParams(queryParams);

        if (spreadPublicationSearchPageLink) {
            setIsLoading(true);
            Api.get(spreadPublicationSearchPageLink)
                .then(representationModel => setPageRepresentationModel(representationModel))
                .catch(apiError => setApiError(apiError))
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        setCurrentPage(Constants.FIRST_PAGE_NUMBER)
    }, [spreadPublicationFilters, sort]);

    useEffect(() => {
        loadSpreadPublications();
    }, [currentPage, pageSize, spreadPublicationFilters, spreadPublicationSearchPageModel.spreadPublicationSearchPageLink, sort]);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const searchResults = pageRepresentationModel?.getEmbeddedRepresentationModelsByName("spreadPublicationSearchPageRepresentationModels");
    const pageModel = pageRepresentationModel?.get('page');

    let unreadSearchResults = [];
    if (Array.isArray(searchResults)) {
        unreadSearchResults = searchResults.filter(searchResult => !searchResult.get('readByUser'));
    }

    return (
        <>
            {(() => {
                switch (spreadPublicationViewMode) {
                    case SpreadPublicationViewMode.MODE.MARK_READ:
                        return <SpreadPublicationMarkReadViewMode
                            spreadPublicationSearchPageRepresentationModels={unreadSearchResults}
                            view={view}
                            spreadPublicationsMarkAsReadLink={spreadPublicationSearchPageModel.spreadPublicationsMarkAsReadLink}
                            reloadSpreadPublications={loadSpreadPublications}
                            exitModeButtonClicked={exitModeButtonClicked}
                        />;
                    default:
                        return <SpreadPublicationDefaultViewMode
                            spreadPublicationSearchPageRepresentationModels={searchResults}
                            view={view}
                        />;
                }
            })()}

            <Row className={"px-4"}>
                <Col>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={pageModel?.totalPages}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </Col>
            </Row>
        </>
    );
};

export default SpreadPublicationPageView;