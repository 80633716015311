import {Publications, Templates} from "components"
import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";

/**
 * Sektion mit den zuletzt verwendeten Templates und Publikationen.
 *
 * @param {Object} templateModel - Links zur Template-Übersicht-Api und zuletzt verwendeten Templates-Api
 * @param {Object} publicationModel - Links zur Publikation-Übersicht-Api und zuletzt verwendeten Publikation-Api
 * @return {JSX.Element}
 * @constructor
 */
const TemplateAndPublicationSection = ({templateModel, publicationModel}) => {
    return (
        <section>
            <Container className={"px-3 px-lg-4 py-3"}>
                <Row className={"g-4"}>
                    <Col xl={6} className={"tile tile-group pub"}>
                        <Templates templateModel={templateModel}/>
                    </Col>
                    <Col xl={6} className={"tile tile-group pub"}>
                        <Publications publicationModel={publicationModel}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TemplateAndPublicationSection;