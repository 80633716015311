import React from "react";
import {PublicationSearchTileViewCard} from "components";
import {Row} from "react-bootstrap";

/**
 * Implementiert eine Kachelansicht für die Publikationsuche
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodell der Publikation-Suchseite
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchTileView = ({publicationSearchPageRepresentationModels}) => {

    return (
        <Row xs={{cols: 1}} md={{cols: 2}} xl={{cols: 3}} className={"g-5 px-4 py-5"}>
            {publicationSearchPageRepresentationModels.map(publicationSearchPageRepresentationModel => (
                <PublicationSearchTileViewCard
                    key={publicationSearchPageRepresentationModel.get('publicationId')}
                    publicationSearchPageRepresentationModel={publicationSearchPageRepresentationModel}
                />
            ))}
        </Row>
    );
}

export default PublicationSearchTileView;