import React from "react";
import {Form} from "react-bootstrap";
import SearchField from "./SearchField";


const SearchForm = ({onChange, onSubmit, searchText, searchFieldPlaceHolder}) => {
    const handleChange = (event) => {
        event.preventDefault();
        if (typeof onChange === 'function') {
            onChange(event.target.value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (typeof onSubmit === 'function') {
            onSubmit(event.target.searchText.value);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <SearchField id={"searchText"} placeholder={searchFieldPlaceHolder} searchText={searchText} onChange={handleChange}/>
        </Form>
    );
};

export default SearchForm;