export default class Constants {
    /**
     * API-Prefix
     * <p>
     *     Es ist der Prefix, der im Backend für die Mappings der HalJson-Controller verwendet wird.
     *     Siehe dazu im Backend die Klasse HalJsonDispatcherConfig
     * </p>
     * @type {string}
     */
    static API_PATH_PREFIX = '/api';

    static INDEX_API_PATH = '/index';

    /**
     * Nummer der ersten Ergebnisseite.
     *
     * @type {number}
     */
    static FIRST_PAGE_NUMBER = 1;

    /**
     * Anzahl der Einträge auf der Ergebnisseite.
     *
     * @type {number}
     */
    static DEFAULT_PAGE_SIZE = 9;

    /**
     * Die Routing-Pfade müssen mit diesem Suffix enden, damit diese im Backend zugeordnet werden können und die Anfragen
     * an SinglePageAppController weitergeleitet werden können
     *
     * @type {string}
     */
    static HTML_SUFFIX = '.html';

    /**
     * Maximale Integerwert
     */
    static INTEGER_MAX_VALUE = 2^31-1;
}