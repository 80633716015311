import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {forgotPassword} from "common/RenewPasswordApi";

/**
 * Form zum Zurücksetzen des Passworts
 *
 * @param {Function} onCancel - Zurück zum Login
 * @returns {JSX.Element}
 * @constructor
 */
const ResetPasswordForm = ({onCancel}) => {
    const {t} = useTranslation();

    const [username, setUsername] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [validationError, setValidationError] = useState(null);

    const validateValue = (value) => {
        if (!value) {
            return t('error.field.required');
        }

        return null;
    };

    const validateForm = () => {
        const errorMessage = validateValue(username);
        setValidationError(errorMessage)
        return errorMessage === null;
    };


    const handleChangeUsername = (event) => setUsername(event.target.value);

    const handleResetPassword = (event) => {
        event.preventDefault();

        const isValid = validateForm();

        if (isValid) {
            setIsSubmitDisabled(true);
            forgotPassword(username);
            onCancel();
        }
    };

    const handleCancel = (event) => {
        event.preventDefault();
        onCancel();
    };

    return (
        <>
            <h1>{t('reset.password.title')}</h1>
            <Form onSubmit={handleResetPassword}>
                <div>
                    <p>
                        {t('reset.password.content1')}<br/>
                        {t('reset.password.content2')}
                    </p>
                </div>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder={t('login')}
                        value={username}
                        onChange={handleChangeUsername}
                        isInvalid={!!validationError}
                    />
                    <Form.Control.Feedback type={"invalid"}>
                        {validationError}
                    </Form.Control.Feedback>
                </Form.Group>
                <Button
                    variant={"primary"}
                    type={"submit"}
                    disabled={isSubmitDisabled}
                >
                    {t('reset.password.submit')}
                </Button>&nbsp;
                <Button
                    variant={"outline-primary"}
                    type={"button"}
                    onClick={handleCancel}
                >
                    {t('reset.password.cancel')}
                </Button>
            </Form>
        </>
    );
};

export default ResetPasswordForm;