/**
 * Implementiert ein Modell, um Umgang mit der Ansichten zu vereinfachen
 */
export default class View {

    /**
     * Ansichtstyp
     */
    type;

    /**
     * Auswahlverhalten
     */
    selectionType;

    constructor(type, selectionType) {
        this.type = type;
        this.selectionType = selectionType;
    }

    /**
     * Überprüft, ob es sich um eine Kachelansicht handelt
     *
     * @returns {boolean} TRUE, wenn es sich um eine Kachelansicht handelt, sonst FALSE
     */
    isTileView() {
        return this.type === View.TYPE.TILE;
    }


    /**
     * Liefert die Auswahl-CSS-Klasse
     *
     * @returns {string} Auswahl-CSS-Klasse
     */
    getViewSelectionCssClass() {
        switch (this.selectionType) {
            case View.SELECTION_TYPE.NONE:
                return '';
            case View.SELECTION_TYPE.SIMPLE:
                return 'selectable';
            case View.SELECTION_TYPE.MULTI:
                return 'selectable multi-select';
            default:
                return '';
        }
    }

    /**
     * Ansichtstypen
     *
     * @type {{TILE: string, LIST: string}}
     */
    static TYPE = {
        TILE: 'TITLE',
        LIST: 'LIST'
    }

    /**
     * Auswahlverhalten
     *
     * @type {{SIMPLE: string, NONE: string, MULTI: string}}
     */
    static SELECTION_TYPE = {
        NONE: 'NONE',
        SIMPLE: 'SIMPLE',
        MULTI: 'MULTI'
    }

    static DEFAULT_TILE_VIEW = new View(View.TYPE.TILE, View.SELECTION_TYPE.NONE);
    static DEFAULT_LIST_VIEW = new View(View.TYPE.LIST, View.SELECTION_TYPE.NONE);

}