import * as React from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

/**
 *
 * Bootstrap-Card-Komponente mit der Css-Klasse 'teaser' wird in einem Anchor-Tag (Link) eingebettet. Es besteht aus zwei Texten.
 *
 * @param {String} link - Router-Link
 * @param {String} firstCardText - Titel
 * @param {String} secondCardText - zusätzlicher Text
 * @return {JSX.Element}
 * @constructor
 */
const NewsTeaserCard = ({link, firstCardText, secondCardText}) => {
    return (
        <Link to={link}>
            <Card>
                <Card.Body className={"teaser"}>
                    <p>{firstCardText}</p>
                    <p className={"h2"}>{secondCardText}</p>
                </Card.Body>
            </Card>
        </Link>
    );
};

export default NewsTeaserCard;