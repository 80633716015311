import Api from "common/Api";
import {activeFilterValues} from "common/FilterUtils";
import {AlertApiError, SearchAndFilterView} from "components";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const TemplateSearchAndFilter = ({onChange, templateSearchFiltersLink}) => {
    const {t} = useTranslation();

    const [searchText, setSearchText] = useState('');

    const [apiError, setApiError] = useState(null);
    const [contentTypeFilters, setContentTypeFilters] = useState([]);
    const [designFilters, setDesignFilters] = useState([]);
    const [localeFilters, setLocaleFilters] = useState([]);
    const [pubTypeFilters, setPubTypeFilters] = useState([]);

    useEffect(() => {
        if (templateSearchFiltersLink) {
            Api.get(templateSearchFiltersLink)
                .then(searchFilters => {
                    setContentTypeFilters(searchFilters.get("contentTypes"));
                    setDesignFilters(searchFilters.get("designs"));
                    setLocaleFilters(searchFilters.get("locales"))
                    setPubTypeFilters(searchFilters.get("pubTypes"));
                })
                .catch(apiError => setApiError(apiError));
        }

    }, [templateSearchFiltersLink]);

    const handleFiltersChange = (searchText, contentTypeFilters, localeFilters, pubTypeFilters, designFilters) => {
        onChange({
            searchText: searchText,
            contentTypes: activeFilterValues(contentTypeFilters),
            designIds: activeFilterValues(designFilters),
            locales: activeFilterValues(localeFilters),
            pubTypeIds: activeFilterValues(pubTypeFilters)
        });
    }

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
    };

    const handleSearchTextSubmitted = (searchText) => {
        setSearchText(searchText);
        handleFiltersChange(searchText, contentTypeFilters, localeFilters, pubTypeFilters, designFilters);
    };

    const handleContentTypeFiltersChange = (contentTypeFilters) => {
        setContentTypeFilters(contentTypeFilters);
        handleFiltersChange(searchText, contentTypeFilters, localeFilters, pubTypeFilters, designFilters);
    };

    const handleDesignFiltersChange = (designFilters) => {
        setDesignFilters(designFilters);
        handleFiltersChange(searchText, contentTypeFilters, localeFilters, pubTypeFilters, designFilters);
    };

    const handleLocaleFiltersChange = (localeFilters) => {
        setLocaleFilters(localeFilters);
        handleFiltersChange(searchText, contentTypeFilters, localeFilters, pubTypeFilters, designFilters);
    };

    const handlePubTypeFiltersChange = (pubTypeFilters) => {
        setPubTypeFilters(pubTypeFilters);
        handleFiltersChange(searchText, contentTypeFilters, localeFilters, pubTypeFilters, designFilters);
    };

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const search = {
        placeHolderText: t('template.search.field.place.holder'),
        searchText: searchText,
        onChange: handleSearchTextChange,
        onSubmit: handleSearchTextSubmitted
    };

    const filterGroups = [
        {
            title: t('template.search.filter.group.templatetyp.title'),
            items: contentTypeFilters,
            onChange: handleContentTypeFiltersChange
        },
        {
            title: t('template.search.filter.group.pubtype.title'),
            items: pubTypeFilters,
            onChange: handlePubTypeFiltersChange
        },
        {
            title: t('template.search.filter.group.design.linie.title'),
            items: designFilters,
            onChange: handleDesignFiltersChange
        },
        {
            title: t('template.search.filter.group.locale.title'),
            items: localeFilters,
            onChange: handleLocaleFiltersChange
        }
    ];

    return (
        <SearchAndFilterView
            search={search}
            filterGroups={filterGroups}
        />
    );
};

export default TemplateSearchAndFilter;