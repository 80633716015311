const activeFilterValues = (filters) =>
    filters
        .filter(filter => filter.status === 'active')
        .map(filter => filter.key);

const deactivatedFilters = (filters) => {
    const filtersWithInactiveState = [];
    for (const filter of filters) {
        filter.status = 'inactive';
        filter.disabled = false;
        filtersWithInactiveState.push(filter);
    }

    return filtersWithInactiveState;
};

const initialFilter = (filters, initialKey) => {
    const initialFilters = [];
    for (const filter of filters) {
        if (filter.key === initialKey) {
            filter.status = 'active';
        }
        initialFilters.push(filter);
    }

    return initialFilters;
};

const updatedFilterStatesAndDisableAll = (filters, activeFilterKey) => {
    return _updateFilterState(filters, activeFilterKey, true);
};

const updatedFilterStatesEnableAll =  (filters, activeFilterKey) => {
    return _updateFilterState(filters, activeFilterKey, false);
}

const _updateFilterState = (filters, activeFilterKey, disable) => {
    const newStatusFilters = [];
    for (const filter of filters) {
        filter.status = filter.key === activeFilterKey ? 'active' : 'inactive';
        filter.disabled = disable;
        newStatusFilters.push(filter);
    }

    return newStatusFilters
}

export {
    activeFilterValues,
    deactivatedFilters,
    initialFilter,
    updatedFilterStatesAndDisableAll,
    updatedFilterStatesEnableAll
};