import {Container, Row} from "react-bootstrap";
import * as React from "react";

const LinksSection = ({children}) => {
    return (
        <section className={"news"}>
            <Container className={"px-3 px-lg-4 py-3"}>
                <Row>
                    {children}
                </Row>
            </Container>
        </section>
    );
}

export default LinksSection;