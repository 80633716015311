import React from 'react';

/**
 * Implementiert ein Kontext für den aktuellen Anwender
 *
 * @type {React.Context<{currentUser: {}}>}
 */
const UserContext = React.createContext({currentUser: {}});

export default UserContext;
