import {useLocation, Navigate} from "react-router-dom";
import {useAuthentication} from "common/Authentication";
import {jumpToUrl} from "common/LocationHelper";

const RedirectRoute = ({children, mmpRedirectUrl}) => {
    const {isLoggedIn} = useAuthentication();
    const location = useLocation();

    // bereits eingeloggt: Redirect zur Startseite
    if (isLoggedIn) {
        return <Navigate to="/" state={{from: location}} replace/>
    }

    // Für Systeme mit vorgeschaltetem Mediaboard für Login zum Mediaboard redirecten.
    if (mmpRedirectUrl) {
        jumpToUrl(mmpRedirectUrl);
        return null;
    }

    // sonst: Loginseite bzw. "Password aktualisieren" anzeigen
    return children;
};

export default RedirectRoute;