import React from "react";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {PublicationSearchListViewItem} from "components";

/**
 * Implementiert eine Listenansicht für die Publikation-Suche.
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodelle der Publikation-Suchseite
 * @param {View} listView - Listenansicht
 * @param  selectedPublicationIds - Ausgewählte Publikation-IDs
 * @param  handleSelect - Auswahl behandeln, wenn notwendig
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchListView = ({
                                       publicationSearchPageRepresentationModels,
                                       listView,
                                       selectedPublicationIds = [],
                                       handleSelect
                                   }) => {
    const {t} = useTranslation();
    const selectionCssClass = listView.getViewSelectionCssClass();

    return (
        <Table responsive className={"table-fixed table-hover pub-list " + selectionCssClass}>
            <thead>
            <tr className={"clearfix"}>
                <th className={"col-1"}>{t('publication.list.id')}</th>
                <th className={"col-4"}>{t('publication.title')}</th>
                <th className={"col-4"}>{t('publication.list.template')}</th>
                <th className={"col-3"}>{t('publication.list.status')}</th>
            </tr>
            </thead>
            <tbody>
            {publicationSearchPageRepresentationModels.map(publicationSearchPageRepresentationModel => (
                <PublicationSearchListViewItem
                    key={publicationSearchPageRepresentationModel.get('publicationId')}
                    publicationSearchPageRepresentationModel={publicationSearchPageRepresentationModel}
                    selected={selectedPublicationIds.includes(publicationSearchPageRepresentationModel.get('publicationId'))}
                    onSelect={handleSelect}
                />
            ))}
            </tbody>
        </Table>
    )
}

export default PublicationSearchListView;