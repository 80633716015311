import Api from "common/Api";
import {
    activeFilterValues,
    deactivatedFilters,
    initialFilter,
    updatedFilterStatesAndDisableAll,
    updatedFilterStatesEnableAll
} from "common/FilterUtils";
import {AlertApiError, SearchAndFilterView} from "components";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import PublicationAuthor from "common/publication/PublicationAuthor";
import PublicationStatus from "common/publication/PublicationStatus";

const _PublicationSearchAndFilter = ({onChange, filterModel, publicationSearchFiltersLink, currentUser}, ref) => {
    const {t} = useTranslation();
    const {initialAuthor, searchFieldPlaceHolder} = filterModel;
    const userOrganizationId = currentUser?.userOrganizationId;

    const [apiError, setApiError] = useState(null);
    const [organizations, setOrganizations] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState({
        selectedOrganizationId: userOrganizationId,
        authorFilters: [],
        localeFilters: [],
        pubTypeFilters: [],
        statusFilters: []
    });

    useImperativeHandle(ref, () => ({
        clearStatusFilters() {
            const newFilters = {
                ...filters,
                statusFilters: deactivatedFilters(filters.statusFilters)
            };
            setFilters(newFilters);
            handleFiltersChange(searchText, newFilters);
        },

        toEditState() {
            const newFilters = {
                ...filters,
                statusFilters: updatedFilterStatesAndDisableAll(filters.statusFilters, PublicationStatus.EDIT.name())
            };
            setFilters(newFilters);
            handleFiltersChange(searchText, newFilters);
        },

        toReleaseState() {
            const newFilters = {
                ...filters,
                statusFilters: updatedFilterStatesAndDisableAll(filters.statusFilters, PublicationStatus.RELEASE.name())
            };
            setFilters(newFilters);
            handleFiltersChange(searchText, newFilters);
        }
    }));

    useEffect(() => {
        if (publicationSearchFiltersLink && userOrganizationId) {
            Api.get(publicationSearchFiltersLink)
                .then(searchFilters => {
                    setOrganizations(searchFilters.get("organizations").map(organizationFilterItem => {
                        organizationFilterItem.canBeDeselected = organizationFilterItem.key !== userOrganizationId;
                        return organizationFilterItem;
                    }));

                    setFilters(prevState => ({
                        ...prevState,
                        authorFilters: initialFilter(searchFilters.get("authors"), initialAuthor),
                        localeFilters: searchFilters.get("locales"),
                        pubTypeFilters: searchFilters.get("pubTypes"),
                        statusFilters: searchFilters.get("statuses")
                    }));
                })
                .catch(apiError => setApiError(apiError));
        }

    }, [initialAuthor, publicationSearchFiltersLink, userOrganizationId]);

    const handleFiltersChange = (searchText, filters) => {
        onChange({
            searchText: searchText,
            organizationId: filters.selectedOrganizationId,
            authors: activeFilterValues(filters.authorFilters),
            locales: activeFilterValues(filters.localeFilters),
            pubTypeIds: activeFilterValues(filters.pubTypeFilters),
            statuses: activeFilterValues(filters.statusFilters)
        });
    };

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
    };

    const handleSearchTextSubmitted = (searchText) => {
        setSearchText(searchText);
        handleFiltersChange(searchText, filters);
    };

    const handleOrganizationFilterChange = (selectedOrganizationId) => {
        if (!selectedOrganizationId) {
            selectedOrganizationId = userOrganizationId;
        }

        // Wenn es sich nicht um die Organisation des Anwenders handelt, dann muss der Author-Filter
        // auf "OTHER" gesetzt und deaktiviert werden
        let authorFilters;
        if (selectedOrganizationId !== userOrganizationId) {
            authorFilters = updatedFilterStatesAndDisableAll(filters.authorFilters, PublicationAuthor.OTHERS);
        } else {
            authorFilters = updatedFilterStatesEnableAll(filters.authorFilters, PublicationAuthor.OWN);
        }

        const newFilters = {
            ...filters,
            selectedOrganizationId: selectedOrganizationId,
            authorFilters: authorFilters
        };

        setFilters(newFilters);
        handleFiltersChange(searchText, newFilters);
    };

    const handleAuthorFiltersChange = (authorFilters) => {
        const newFilters = {
            ...filters,
            authorFilters: authorFilters
        };
        setFilters(newFilters);
        handleFiltersChange(searchText, newFilters);
    };

    const handleLocaleFiltersChange = (localeFilters) => {
        const newFilters = {
            ...filters,
            localeFilters: localeFilters
        };
        setFilters(newFilters);
        handleFiltersChange(searchText, newFilters);
    };

    const handlePubTypeFiltersChange = (pubTypeFilters) => {
        const newFilters = {
            ...filters,
            pubTypeFilters: pubTypeFilters
        };
        setFilters(newFilters);
        handleFiltersChange(searchText, newFilters);
    };

    const handleStatusFiltersChange = (statusFilters) => {
        const newFilters = {
            ...filters,
            statusFilters: statusFilters
        };
        setFilters(newFilters);
        handleFiltersChange(searchText, newFilters);
    };

    const selectedOrganization = () => {
        const organizationId = filters.selectedOrganizationId ? filters.selectedOrganizationId : userOrganizationId;
        return organizations.find(organisation => organisation.key === organizationId);
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const search = {
        placeHolderText: searchFieldPlaceHolder,
        searchText: searchText,
        onChange: handleSearchTextChange,
        onSubmit: handleSearchTextSubmitted
    };

    const organization = {
        items: organizations,
        selectedItem: selectedOrganization(),
        onChange: handleOrganizationFilterChange,
    };

    const filterGroups = [
        {
            title: t('publication.search.filter.group.author.title'),
            items: filters.authorFilters,
            onChange: handleAuthorFiltersChange
        },
        {
            title: t('publication.search.filter.group.pubtype.title'),
            items: filters.pubTypeFilters,
            onChange: handlePubTypeFiltersChange
        },
        {
            title: t('publication.search.filter.group.status.title'),
            items: filters.statusFilters,
            onChange: handleStatusFiltersChange
        },
        {
            title: t('publication.search.filter.group.locale.title'),
            items: filters.localeFilters,
            onChange: handleLocaleFiltersChange
        }
    ];

    return (
        <SearchAndFilterView
            search={search}
            organization={organization}
            filterGroups={filterGroups}
        />
    );
};

const PublicationSearchAndFilter = forwardRef(_PublicationSearchAndFilter);

export default PublicationSearchAndFilter;