import Api from "common/Api";
import {AlertApiError, LoadingIndicator, TemplatesEventInfo} from "components";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

class TemplatesEventDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            templatesEventDetailsRepresentationModel: null,
            isLoading: true,
            apiError: null,
        };
    }

    componentDidMount() {
        Api.getRepresentationModel()
            .then(representationModel =>
                this.setState({
                    templatesEventDetailsRepresentationModel: representationModel,
                    isLoading: false
                })
            )
            .catch(apiError =>
                this.setState({
                    apiError: apiError,
                    isLoading: false
                })
            );
    }

    render() {
        const {templatesEventDetailsRepresentationModel, isLoading, apiError} = this.state;

        if (isLoading) {
            return <LoadingIndicator/>;
        }

        if (apiError) {
            return <AlertApiError apiError={apiError}/>;
        }

        const templatesEventData = {
            templatesEventName: templatesEventDetailsRepresentationModel?.get('templatesEventName'),
            possibleCurrentUserOrganizationsLink: templatesEventDetailsRepresentationModel?.getLink('possible-current-user-organizations'),
            templateEventTypesLink: templatesEventDetailsRepresentationModel?.getLink('template-event-types'),
            eventParticipantsExampleLink: templatesEventDetailsRepresentationModel?.getLink('event-participants-example'),
            createEventPublicationsLink: templatesEventDetailsRepresentationModel?.getLink('create-event-publications')
        }

        return (
            <section>
                <Container className={"px-3 px-lg-4 py-0 py-lg-3"}>
                    <Row>
                        <Col>
                            <Row className={"template-detail"}>
                                <Col>
                                    <Row className={"template-name"}>
                                        <Col>
                                            <h1>{templatesEventData.templatesEventName}</h1>
                                        </Col>
                                    </Row>
                                    <TemplatesEventInfo templatesEventData={templatesEventData}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default TemplatesEventDetails;