import {Accordion, Button, Col, Row} from "react-bootstrap";
import Api from "common/Api";
import {AlertApiError, LoadingOverlayIndicator} from "components";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const PublicationDownloads = ({accordionEventKey, publicationDownloads}) => {
    const {t} = useTranslation();

    const publicationAssetTypes = [
        {
            key: 'PREVIEW-PDF',
            title: t('GePublicationEditor.menu.group.download.pdf.button.preview.all'),
            iconCss: 'fa-file-pdf',
            generateLink: publicationDownloads.generatePreviewPdfLink,
            downloadRel: 'download-preview-pdf',
            creatingMessage: t('GePublicationEditor.preview.pdf.creating.process')
        },
        {
            key: 'WEB-PDF',
            title: t('GePublicationEditor.menu.group.download.pdf.button.web.all'),
            iconCss: 'fa-file-pdf',
            generateLink: publicationDownloads.generateWebPdfLink,
            downloadRel: 'download-web-pdf',
            creatingMessage: t('GePublicationEditor.web.pdf.creating.process')
        },
        {
            key: 'PRINT-PDF',
            title: t('GePublicationEditor.menu.group.download.pdf.button.print.all'),
            iconCss: 'fa-file-pdf',
            generateLink: publicationDownloads.generatePrintPdfLink,
            downloadRel: 'download-print-pdf',
            creatingMessage: t('GePublicationEditor.print.pdf.creating.process'),
        },
        {
            key: 'OFFICE-PDF',
            title: t('GePublicationEditor.menu.group.download.pdf.button.office'),
            iconCss: 'fa-file-pdf',
            generateLink: publicationDownloads.generateOfficePdfLink,
            downloadRel: 'download-office-pdf',
            creatingMessage: t('GePublicationEditor.office.pdf.creating.process')
        },
        {
            key: 'BARRIER-FREE-PDF',
            title: t('GePublicationEditor.menu.group.download.pdf.button.tagged'),
            iconCss: 'fa-file-pdf',
            generateLink: publicationDownloads.generateBarrierFreePdfLink,
            downloadRel: 'download-barrier-free-pdf',
            creatingMessage: t('GePublicationEditor.barrier.free.pdf.creating.process')
        },
        {
            key: 'IDML-ARCHIVE',
            title: t('GePublicationEditor.menu.group.download.idml.tooltip'),
            // {/* TODO: Icon für IDML in Font Awesome erstellen   */}
            iconCss: 'fa-file-code',
            generateLink: publicationDownloads.generateIDMLArchiveLink,
            downloadRel: 'download-idml-archive',
            creatingMessage: t('GePublicationEditor.idml.creating.process')
        },
        {
            key: 'JPEG',
            title: t('publication.download.jpeg.button.title'),
            iconCss: 'fa-file-image',
            generateLink: publicationDownloads.generateJPEGLink,
            downloadRel: 'download-jpeg',
            creatingMessage: t('publication.download.jpeg.creating.process')
        },

    ];

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [apiError, setApiError] = useState('');


    const generateAsset = (assetType) => {
        if (assetType.generateLink) {
            setLoadingMessage(assetType.creatingMessage);
            setIsLoading(true);
            setApiError('');
            Api.get(assetType.generateLink)
                .then(publicationAssetGeneratorRepresentationModel => {
                    if (publicationAssetGeneratorRepresentationModel.getLink(assetType.downloadRel)) {
                        if ('IDML-ARCHIVE' === assetType.key) {
                            window.location.href = publicationAssetGeneratorRepresentationModel.getLink(assetType.downloadRel).getHref();
                        } else {
                            window.open(publicationAssetGeneratorRepresentationModel.getLink(assetType.downloadRel).getHref());
                        }
                    }
                    setIsLoading(false);
                    setLoadingMessage('');
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsLoading(false);
                    setLoadingMessage('');
                });
        }
    }


    return (
        <Accordion.Item eventKey={accordionEventKey}>
            <Accordion.Header>{t('publication.downloads.label')}</Accordion.Header>
            <Accordion.Body>

                {apiError &&
                    <AlertApiError apiError={apiError}/>
                }

                <LoadingOverlayIndicator
                    show={isLoading}
                    message={loadingMessage}
                />

                <Row>
                    {publicationAssetTypes.map((assetTypes) =>
                        assetTypes.generateLink &&
                        <Col xs={12} lg={6} className={"py-4"} key={assetTypes.key}>
                            <p>
                                <Button as={'a'} bsPrefix={'a'} onClick={() => generateAsset(assetTypes)}>
                                    <i className={`fal fa-3xl fa-fw ${assetTypes.iconCss}`}/>
                                    {assetTypes.title}
                                </Button>
                            </p>
                        </Col>
                    )}
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default PublicationDownloads;