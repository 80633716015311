import {useTranslation} from "react-i18next";
import UnitConstants from "common/UnitConstants";
import React from "react";

/**
 * Implementiert eine Zeile einer Listenansicht.
 *
 * @param spreadPublicationSearchPageRepresentationModel - Darstellungsmodell der verteilten Publikation
 * @param selected - Bestimmt, ob der Datensatz als "ausgewählt" markiert werden soll
 * @param onSelect - Hochreichen der Aktion, die ausgelöst wurde, als der Datensatz angeklickt wurde
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchListViewItem = ({spreadPublicationSearchPageRepresentationModel, selected, onSelect}) => {
    const {t} = useTranslation();

    return (
        <tr
            className={"clearfix " + (selected ? "active" : "")}
            onClick={() => onSelect(spreadPublicationSearchPageRepresentationModel)}
        >
            <td className={"col-2"}>
                {UnitConstants.DATE_FORMAT.format(spreadPublicationSearchPageRepresentationModel.get('spreadPublicationCreateDate'))}
            </td>
            <td className={"col-5"}>
                {spreadPublicationSearchPageRepresentationModel.get('publicationName')}
            </td>
            <td className={"col-4"}>
                {spreadPublicationSearchPageRepresentationModel.get('templateName')}
            </td>
            <td className={"col-1"}>
                {!spreadPublicationSearchPageRepresentationModel.get('readByUser') ? t('spread.publication.result.unread') : t('spread.publication.result.read')}
            </td>
        </tr>
    )
}

export default PublicationSearchListViewItem;