import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const SpreadPublicationMetaData = ({spreadPublicationMetaData, onSpreadPublicationMetaDataChange}) => {
    const {t, i18n} = useTranslation();

    const [formValues, setFormValues] = useState({
        publicationName: spreadPublicationMetaData.publicationName,
    });

    const [formValidationErrors, setFormValidationErrors] = useState({});

    const _validateFieldValue = (field, value) => {
        if (field === 'publicationName' && (!value || !value.trim())) {
            return t('publication.metadata.validation.publication.name.empty');
        }
        return null;
    }

    const _handleChange = (event) => {
        setFormValues(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));

        const validation = _validateFieldValue(event.target.name, event.target.value);

        setFormValidationErrors(prevState => ({
            ...prevState,
            [event.target.name]: validation
        }));

        onSpreadPublicationMetaDataChange({
            hasError: validation != null,
            form: {
                ...formValues,
                [event.target.name]: event.target.value
            }
        });
    }

    return (
        <Form.Group controlId={"publicationName"} className={"mb-3"}>
            <Form.Label>
                {t('publication.metadata.publication.name')}
            </Form.Label>
            <Form.Control
                type={"text"}
                name={"publicationName"}
                value={formValues.publicationName}
                isInvalid={!!formValidationErrors.publicationName}
                onChange={event => {
                    _handleChange(event)
                }}
            />
            <Form.Control.Feedback type={"invalid"}>
                {formValidationErrors.publicationName}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default SpreadPublicationMetaData;