import Api from "common/Api";
import UnitConverter from "common/UnitConverter";
import {
    AlertApiError,
    LoadingOverlayIndicator,
    OrganizationSelect,
    TemplateDynamicMetaData,
    TemplateGeneralInformation,
    TemplatePrintingPriceCalculator,
    TemplatePublicationMetaData
} from "components";
import React, {useState} from "react";
import {Accordion, Button, Col, Form, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const TemplateInfo = ({templateData}) => {

    const {t} = useTranslation();

    const [isCreatingPublication, setIsCreatingPublication] = useState(false);
    const [canCreatePublication, setCanCreatePublication] = useState(true);
    const [publicationCreateSpecification, setPublicationCreateSpecification] = useState(
        {
            templateId: templateData.templateId,
            publicationName: templateData.templateName,
            organizationId: null,
            contentLocale: null,
            dynamicWidth: templateData.dynamic ? templateData.nettoWidth : 0.0
        }
    );

    const [apiError, setApiError] = useState(null);

    const templatePrintPrice = {
        templateId: templateData.templateId,
        printingPriceLink: templateData.printingPriceLink,
        rootContainerSectionPageCountMin: templateData.rootContainerSectionPageCountMin,
        rootContainerSectionPageCountMax: templateData.rootContainerSectionPageCountMax
    }

    const templateDynamicMetaData = {
        nettoWidth: UnitConverter.pointToMm(templateData.nettoWidth, 0)
    }

    const templatePublicationMetaData = {
        publicationName: templateData.templateName,
        contentLanguagesLink: templateData.contentLanguagesLink
    }

    const _createPublication = () => {
        if (templateData.publicationCreateLink && canCreatePublication) {
            setIsCreatingPublication(true);
            Api.post(templateData.publicationCreateLink, publicationCreateSpecification)
                .then(publicationRepresentationModel => {
                    window.location.href = publicationRepresentationModel.getHref('edit-publication');
                    setTimeout(() => {
                        setIsCreatingPublication(false);
                    }, 2000);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsCreatingPublication(false);
                });
        }
    }

    const _onDynamicMetaDataChange = (dynamicMetaData) => {
        setCanCreatePublication(!dynamicMetaData.hasError);
        setPublicationCreateSpecification(prevState => ({
            ...prevState,
            dynamicWidth: UnitConverter.mmToPoint(dynamicMetaData.form.publicationWidth, 0)
        }));
    };

    const _onOrganizationChange = (organizationId) => {
        setPublicationCreateSpecification(prevState => ({
            ...prevState,
            organizationId: organizationId
        }));
    };

    const _onTemplatePublicationMetaDataChange = (templatePublicationMetaData) => {
        setCanCreatePublication(!templatePublicationMetaData.hasError);
        setPublicationCreateSpecification({
                ...publicationCreateSpecification,
                publicationName: templatePublicationMetaData.form.publicationName,
                contentLocale: templatePublicationMetaData.form.contentLang
            }
        );
    };

    return (
        <>
            {apiError &&
                <AlertApiError apiError={apiError}/>
            }

            <LoadingOverlayIndicator
                show={isCreatingPublication}
                message={t('GeCreatePublicationWizard.creating.process')}
            />

            <Row className={"template-info px-4 py-4 gap-4"}>
                <Col xs={12}>
                    <Form onSubmit={event => event.preventDefault()}>
                        <Row className={"g-5"}>
                            <Col xs={12} md={12} lg={4}>
                                <p className={"thumbnail-wrapper img-thumbnail"}>
                                    <Image
                                        fluid
                                        className={"image-shadow"}
                                        src={templateData.templateThumbnailLink?.getLinkPath()}
                                    />
                                </p>
                                {templateData.bestPracticePdfLink?.getHref() &&
                                    <p>
                                        <a href={templateData.bestPracticePdfLink.getHref()}>
                                            <i className={"fal fa-lg fa-fw fa-download"}/>&nbsp;
                                            {t('template.details.open.best.practice.pdf')}
                                        </a>
                                    </p>
                                }
                            </Col>
                            <Col xs={12} md={12} lg={5} className={"form-col"}>
                                <p>{templateData.description}</p>
                                <TemplatePublicationMetaData
                                    templatePublicationMetaData={templatePublicationMetaData}
                                    onTemplatePublicationMetaDataChange={_onTemplatePublicationMetaDataChange}
                                />
                                {templateData.dynamic &&
                                    <>
                                        <OrganizationSelect
                                            organizationsLink={templateData.possibleCurrentUserOrganizationsLink}
                                            onChange={_onOrganizationChange}
                                        />
                                        <TemplateDynamicMetaData
                                            templateDynamicMetaData={templateDynamicMetaData}
                                            onDynamicMetaDataChange={_onDynamicMetaDataChange}
                                        />
                                    </>
                                }
                            </Col>
                            <Col xs={12} md={12} lg={3}>
                                <p className={"text-end"}>
                                    <Button
                                        variant={"primary"}
                                        size={"lg"}
                                        className={"create-publication"}
                                        disabled={isCreatingPublication || !canCreatePublication}
                                        onClick={() => _createPublication()}
                                    >
                                        {t('template.details.create.publication.button.label')}
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xs={12}>
                    <Accordion id={"accordionTemplateDetails"} alwaysOpen={true} defaultActiveKey={['0']}>
                        <TemplateGeneralInformation
                            accordionEventKey={"0"}
                            templateDescription={templateData.description}
                            productDetails={templateData.productDetails}
                        />
                        {templatePrintPrice.printingPriceLink &&
                            <TemplatePrintingPriceCalculator
                                accordionEventKey={"1"}
                                templatePrintPrice={templatePrintPrice}
                            />
                        }
                    </Accordion>
                </Col>
            </Row>
        </>
    );
};

export default TemplateInfo;