import * as React from "react";
import {Row} from "react-bootstrap";

/**
 * Komponente für die Anzeige von Link-Kacheln auf eine Zeile.
 * Es werden je nach Breakpoint bis zu 4 Kacheln nebeneinander angezeigt.
 * Für 2 Kacheln werden auf dem größten Breakpoint nur 2 Kacheln nebeneinander angezeigt,
 * diese nutzen dann die doppelte Breite.
 *
 * @param children
 * @returns {JSX.Element|null}
 * @constructor
 */
const LinksRow = ({children}) => {
    if (!Array.isArray(children) || children.length === 0) {
        return null;
    }

    if (children.length === 1) {
        return (
            <Row xs={{cols: 1}} md={{cols: 1}} lg={{cols: 1}} xl={{cols: 1}} className={"g-4 g-md-4"}>
                {children}
            </Row>
        );
    }

    if (children.length === 2) {
        return (
            <Row xs={{cols: 1}} md={{cols: 2}} lg={{cols: 2}} xl={{cols: 2}} className={"g-4 g-md-4"}>
                {children}
            </Row>
        );
    }

    return (
        <Row xs={{cols: 1}} md={{cols: 2}} lg={{cols: 2}} xl={{cols: 4}} className={"g-4 g-md-4"}>
            {children}
        </Row>
    );
};

export default LinksRow;