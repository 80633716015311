import UnitConstants from "./UnitConstants";

export default class UnitConverter {

    /**
     * Konvertiert Punkte (DPI) nach Millimeter (mm).
     *
     * @param {number} point Größe in Punkten (DPI)
     * @param {number} digits Anzahl der Nachkommastellen
     * @returns {number||null} Größe in Millimetern (mm)
     */
    static pointToMm(point, digits) {
        if (!point) {
            return null;
        }
        const mm = point * UnitConstants.MM_PER_POINT;
        return mm.toFixed(digits) * 1;
    }

    /**
     * Konvertiert Millimeter (mm) nach Punkte (DPI).
     *
     * @param {number} mm Größe in Millimetern (mm)
     * @param {number} digits Anzahl der Nachkommastellen
     * @returns {number||null} Größe in Punkten (DPI)
     */
    static mmToPoint(mm, digits) {
        if (!mm) {
            return null;
        }
        const point = mm * UnitConstants.POINT_PER_MM;
        return point.toFixed(digits) * 1;
    }

    static fromLocalDate(localDate, dateSeparator) {
        return UnitConstants.NUMBER_FORMAT.format(localDate.dayOfMonth) +
            dateSeparator +
            UnitConstants.NUMBER_FORMAT.format(localDate.monthValue) +
            dateSeparator +
            localDate.year;
    }
}