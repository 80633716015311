import * as React from "react";
import {Alert} from "react-bootstrap";

/**
 * Komponente zum Anzeigen Fehler -oder Warnung-Meldungen
 *
 * @param apiError {ApiError}
 * @return {JSX.Element}
 * @constructor
 */
const AlertApiError = ({apiError}) => {

    console.error('apiError=', apiError)

    return (
        <Alert variant={apiError.warning ? "warning" : "danger"} className={"text-center"}>
            {apiError.getMessage()}
        </Alert>
    );
};

export default AlertApiError;