import React from "react";
import {Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";

/**
 * Filtereintrag für die OrganizationFilterGroup.
 * Eintrag ist immer aktiv, wenn eine Organisation übergeben wurde.
 * Sonst wird ein Platzhaltertext angezeigt.
 *
 * @param {{key: Number, label: String, internalNumber: String, canBeDeselected: boolean}} organization - ausgewählte Organisation
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationFilterItem = ({organization}) => {
    const {t} = useTranslation();

    if (organization === null) {
        return (
            <p className="no-result">{t('organization.select.none')}</p>
        );
    }

    return (
        <Nav.Item
            as={"li"}
            bsPrefix={`nav-item active ${organization.canBeDeselected ? '' : 'no-pseudo'}`}
        >
            {organization.label}
        </Nav.Item>
    );
};

export default OrganizationFilterItem;