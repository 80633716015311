import {CSVLink} from "react-csv";
import {Col, Row} from "react-bootstrap";
import PublicationStatus from "common/publication/PublicationStatus";
import {NoResults, PublicationSearchListView, PublicationSearchTileView, ToolboxExit} from "components";
import React from "react";
import {useTranslation} from "react-i18next";

/**
 * Download-Ansichtsmodus
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodelle der Publikation-Suchseite
 * @param {View} view - Ansicht
 * @param {Function} exitModeButtonClicked - Callback zum Verlassen dem aktuellen Ansichtsmodus
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchDownloadViewMode = ({
                                               publicationSearchPageRepresentationModels,
                                               view,
                                               exitModeButtonClicked
                                           }) => {
    const {t} = useTranslation();

    const csvHeaders = [
        {label: t('publication.list.id'), key: 'publicationId'},
        {label: t('publication.title'), key: 'publicationName'},
        {label: t('publication.list.template'), key: 'templateName'},
        {label: t('publication.list.status'), key: 'status'},
    ];

    const toCsvData = (model) => {
        const status = model.get('status');
        const publicationStatus = PublicationStatus.getPublicationStatus(status);

        return {
            publicationId: model.get('publicationId'),
            publicationName: model.get('publicationName'),
            templateName: model.get('templateName'),
            status: t(publicationStatus.messageKey())
        };
    };

    const getCsvFileName = () => {
        const now = new Date();
        return `publications_${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;
    };

    return (
        <Row className={"px-4 py-5"}>
            <ToolboxExit exitButtonClicked={exitModeButtonClicked}/>
            {(!Array.isArray(publicationSearchPageRepresentationModels) || publicationSearchPageRepresentationModels.length === 0) ?
                <NoResults/> :
                <Col xs={12}>
                    {view.isTileView() ?
                        <PublicationSearchTileView
                            publicationSearchPageRepresentationModels={publicationSearchPageRepresentationModels}
                        /> :
                        <PublicationSearchListView
                            publicationSearchPageRepresentationModels={publicationSearchPageRepresentationModels}
                            listView={view}
                        />
                    }
                </Col>
            }
            <Col xs={12}>
                <Row className={"g-0"}>
                    <ToolboxExit exitButtonClicked={exitModeButtonClicked}/>
                    {Array.isArray(publicationSearchPageRepresentationModels) && publicationSearchPageRepresentationModels.length > 0 &&
                        <Col className={"text-end"}>
                            <CSVLink headers={csvHeaders}
                                     data={publicationSearchPageRepresentationModels.map(toCsvData)}
                                     separator={";"}
                                     filename={getCsvFileName()}
                                     className={"btn btn-primary mb-4"}>
                                {t('csv.download')}
                            </CSVLink>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
export default PublicationSearchDownloadViewMode;