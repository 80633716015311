import React from "react";
import {Card, Col, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

/**
 * Implementiert eine Kachel einer Kachelansicht von verteilten Publikation.
 *
 * @param spreadPublicationSearchPageRepresentationModel - Darstellungsmodell der verteilten Publikationen
 * @return {JSX.Element}
 * @constructor
 */
const SpreadPublicationTileViewCard = ({spreadPublicationSearchPageRepresentationModel}) => {
    const {t} = useTranslation();
    const readByUser = spreadPublicationSearchPageRepresentationModel.get('readByUser');

    return (
        <Col className={"tile template"}>
            <Link
                to={spreadPublicationSearchPageRepresentationModel.getLinkPathForRouting('spread-publication-details')}>
                <Card>
                    <Card.Body bsPrefix={"card-body-image"}>
                        {!readByUser &&
                            <span className={"bg-new"}>
                                {t('spread.publication.result.unread')}
                            </span>
                        }
                        <Image
                            fluid
                            src={spreadPublicationSearchPageRepresentationModel.getLinkPath('publication-thumbnail')}
                            className={"image-shadow"}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {spreadPublicationSearchPageRepresentationModel.get('publicationName')}
                    </Card.Footer>
                </Card>
            </Link>
        </Col>
    );
};

export default SpreadPublicationTileViewCard;