import React from "react";
import {Accordion, Col, Table, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const TemplateGeneralInformation = ({accordionEventKey, templateDescription, productDetails}) => {
    const {t} = useTranslation();

    return (
        <Accordion.Item eventKey={accordionEventKey}>
            <Accordion.Header>{t('template.details.description.title')}</Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col xs={12} md={12} lg={6}>
                        <p>{templateDescription}</p>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        <Table>
                            <thead>
                            <tr>
                                <th colSpan={4}>{t('template.details.product.details.title')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>{t('sprache')}</th>
                                <td>{productDetails.language}</td>
                                <th>{t('template.contentType')}</th>
                                <td>{productDetails.contentType}</td>
                            </tr>
                            <tr>
                                <th>{t('template.pubType')}</th>
                                <td>{productDetails.pubTypeName}</td>
                                <th>{t('template.paperSize')}</th>
                                <td>{productDetails.paperSizeName}</td>
                            </tr>
                            <tr>
                                <th>{t('template.design')}</th>
                                <td>{productDetails.designName}</td>
                                <th>{t('template.color')}</th>
                                <td>{productDetails.colorName}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );

};

export default TemplateGeneralInformation;