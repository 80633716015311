import * as React from "react";
import {Row, Spinner} from "react-bootstrap";

/**
 * Ladeanzeige zum Überlagern einer Seite, um Anwender-Aktionen (z.B. Klick auf einem Button) zu verhindern
 *
 * Diese sollte in der Regel verwendet werden, wenn eine Anfrage sehr lange dauert (z.B. Das Erstellen einer Publikation)
 *
 * @param show {boolean}
 * @param message {String}
 * @return {JSX.Element|null}
 */
const LoadingOverlayIndicator = ({show, message}) => {

    if (!show) {
        return null;
    }

    return (
        <div className={"loader-wrapper"}>
            <Row>
                <Spinner animation={"border"}
                         role={"status"}
                         style={{width: '5rem', height: '5rem'}}
                         variant={"primary"}
                />
            </Row>
            {message &&
                <Row>
                    <strong>{message}</strong>
                </Row>
            }
        </div>
    );
}

export default LoadingOverlayIndicator;