import {Navigate, useLocation} from "react-router-dom";
import {useAuthentication} from "common/Authentication";

const ProtectedRoute = ({children}) => {
    const {isLoggedIn} = useAuthentication();
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to="/login.html" state={{from: location}} replace/>
    }

    return children;
};

export default ProtectedRoute;