import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {setNewPassword} from "common/RenewPasswordApi";
import {AlertApiError} from "components";

/**
 * Form zum Aktualisieren des Passworts
 *
 * @param {String} token - Token mit dem die Passwortänderung authentifiziert wird
 * @returns {JSX.Element}
 * @constructor
 */
const RenewPasswordForm = ({token}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [fields, setFields] = useState({
        newPassword: '',
        newPasswordRepeated: ''
    });
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [apiError, setApiError] = useState(null);

    const validateValue = (value) => {
        if (!value) {
            return t('error.field.required');
        }

        return null;
    };

    const validateForm = () => {
        const errorMessages = {};

        for (const [key, value] of Object.entries(fields)) {
            const errorMessage = validateValue(value);
            if (errorMessage) {
                errorMessages[key] = errorMessage;
            }
        }

        setValidationErrors({...errorMessages});

        if (Object.keys(errorMessages).length > 0) {
            return false;
        }

        if (fields.newPassword !== fields.newPasswordRepeated) {
            setValidationErrors({
                newPassword: t('RequestNewPassword.passwords.noMatch')
            });

            return false;
        }

        if (fields.newPassword.length < 8) {
            setValidationErrors({
                newPassword: t('Min.password.length')
            });

            return false;
        }

        return true;
    };

    const changeField = (event) => {
        setFields(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleRenewPassword = (event) => {
        event.preventDefault();

        const isValid = validateForm();

        if (isValid) {
            setIsSubmitDisabled(true);
            setNewPassword(token, fields.newPassword)
                .then(success => {
                    console.log('success:', success);

                    setIsSubmitDisabled(false);

                    if (success) {
                        navigate('/login.html');
                    } else {
                        setValidationErrors({
                            newPassword: t('RequestNewPassword.error')
                        })
                    }
                })
                .catch(apiError => setApiError(apiError));
        }
    };

    return (
        <>
            {apiError &&
                <AlertApiError apiError={apiError}/>
            }

            <h1>{t('RequestNewPassword.newPassword')}</h1>
            <Form onSubmit={handleRenewPassword}>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="password"
                        placeholder={t('RequestNewPassword.newPassword.set')}
                        name="newPassword"
                        value={fields.newPassword}
                        onChange={changeField}
                        isInvalid={!!validationErrors.newPassword}
                    />
                    <Form.Control.Feedback type={"invalid"}>
                        {validationErrors.newPassword}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="password"
                        placeholder={t('RequestNewPassword.newPassword.repeat')}
                        name="newPasswordRepeated"
                        value={fields.newPasswordRepeated}
                        onChange={changeField}
                        isInvalid={!!validationErrors.newPasswordRepeated}
                    />
                    <Form.Control.Feedback type={"invalid"}>
                        {validationErrors.newPasswordRepeated}
                    </Form.Control.Feedback>
                </Form.Group>
                <Button
                    variant={"primary"}
                    type={"submit"}
                    disabled={isSubmitDisabled}
                >
                    {t('RequestNewPassword.change.password')}
                </Button>&nbsp;
                <Link to={"/login.html"}>
                    <Button
                        variant={"outline-primary"}
                        type={"button"}
                    >
                        {t('RequestNewPassword.cancel')}
                    </Button>
                </Link>
            </Form>
        </>
    );
};

export default RenewPasswordForm;