import {Col, Row} from "react-bootstrap";
import * as React from "react";
import {NewsOverviewLinkCard, NewsTeaserCard} from "components";
import {NewsImg} from "image";
import UnitConverter from "common/UnitConverter";
import {useTranslation} from "react-i18next";

const NewsRow = ({newsModel, lastNews, onSameSectionWithLinks}) => {
    const {t} = useTranslation();

    const newsOverviewLinkCard = <NewsOverviewLinkCard
        link={newsModel.newsLink.getLinkPathForRouting()}
        image={newsModel.homepageNewsImageFile || NewsImg}/>;

    const xlColsValueWithNewsTeaser = onSameSectionWithLinks ? 2 : 4;
    const colsValue = onSameSectionWithLinks ? 1 : 2;

    if (Array.isArray(lastNews) && lastNews.length > 0) {
        return (
            <Row xs={{cols: 1}} md={{cols: 2}} lg={{cols: 2}} xl={{cols: xlColsValueWithNewsTeaser}} className={"g-0"}>
                <Col>
                    {newsOverviewLinkCard}
                </Col>
                {lastNews.map(news => (
                    <Col key={news.get('newsId')}>
                        <NewsTeaserCard
                            link={news.getLinkPathForRouting('news-details')}
                            firstCardText={UnitConverter.fromLocalDate(news.get('newsValidFrom'), t('date.separator'))}
                            secondCardText={news.get('newsHead')}
                        />
                    </Col>
                ))}
            </Row>
        );
    }

    return (
        <Row xs={{cols: 1}} md={{cols: colsValue}} lg={{cols: colsValue}} xl={{cols: colsValue}} className={"g-4 g-md-4"}>
            <Col>
                {newsOverviewLinkCard}
            </Col>
        </Row>
    );
};

export default NewsRow;