/**
 *
 * Implementiert ein Modell, um die eingebetteten haljson Daten zu beschreiben
 *
 * <p> Beispiel:
 *
 "_embedded": {
    "publication": { // "publication" ist der Name des eingebetteten RepresentationModells
      "id": "4563", // Ab hier werden die Daten als RepresentationModel behandelt
      "name": "test-publication"
      "_links": {
        "self": {
          "href": "http://genera5.de/api/publication/test-publication"
        }
      }
    }
  }
 *
 *</p>
 *
 * @author okilic <okilic@geneon.de>
 */
export default class EmbeddedRepresentationModel {
    /**
     * Name der eingebetteten RepresentationModelle
     * @type {String}
     */
    name = null;

    /**
     * Die eingebetteten {@link RepresentationModel}'le
     *
     * @type {[RepresentationModel]}
     */
    representationModels = [];

    constructor(name, representationModel) {
        this.name = name;
        this.representationModels.push(representationModel);
    }

    /**
     * Liefert den Namen des eingebetteten Objekts
     * @return {String}
     */
    getName() {
        return this.name;
    }

    /**
     * Liefert die {@link RepresentationModel}'le
     * @return {RepresentationModel[]}
     */
    getRepresentationModels() {
        return this.representationModels;
    }
}