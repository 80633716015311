import Api from "common/Api";
import PublicationStatus from "common/publication/PublicationStatus";
import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import {AlertApiError} from "components";
import {useTranslation} from "react-i18next";

const PublicationMetadata = ({publicationData, onChange}) => {
    const {t} = useTranslation();

    const [apiError, setApiError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState({
        publicationName: publicationData.publicationName
    });
    const [validationErrors, setValidationErrors] = useState({})

    const publicationStatus = PublicationStatus.getPublicationStatus(publicationData.publicationStatus);
    const isEditable =  publicationStatus && publicationStatus === PublicationStatus.EDIT;

    const _validateFieldValue = (field, value) => {
        if (field === 'publicationName' && (!value || !value.trim())) {
            return t('publication.metadata.validation.publication.name.empty');
        }
        return null;
    }

    const _setField = (field, value) => {
        setValues({
            ...values,
            [field]: value
        });

        setValidationErrors({
            ...validationErrors,
            [field]: _validateFieldValue(field, value)
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const data = JSON.stringify({
            publicationId: publicationData.publicationDetails.publicationId,
            publicationName: values.publicationName
        });

        Api.put(publicationData.updatePublicationMetadataLink, data)
            .then(() => {
                setIsSubmitting(false);
                if (typeof onChange === 'function') {
                    onChange({ publicationName: values.publicationName });
                }
            })
            .catch(apiError => setApiError(apiError));
    }

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const isSubmitDisabled = () => {
        const hasValidationErrors = Object.values(validationErrors).some(error => error !== null);

        return isSubmitting || hasValidationErrors;
    }

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Form.Group controlId={"publicationName"} className={"mb-3"}>
                <Form.Label>
                    {t('publication.metadata.publication.name')}
                </Form.Label>
                <Form.Control
                    type={"text"}
                    value={values.publicationName}
                    isInvalid={!!validationErrors.publicationName}
                    disabled={!isEditable}
                    onChange={e => _setField("publicationName", e.target.value)}
                />
                <Form.Control.Feedback type={"invalid"}>
                    {validationErrors.publicationName}
                </Form.Control.Feedback>
            </Form.Group>
            {isEditable && (
                <div className={"mb-3"}>
                    <Button variant={"primary"} type={"submit"} disabled={isSubmitDisabled()}>
                        {t('publication.metadata.button.save')}
                    </Button>{' '}
                </div>
            )}
        </Form>
    );
};

export default PublicationMetadata;