import PublicationStatus from "common/publication/PublicationStatus";
import {useTranslation} from "react-i18next";

/**
 * Implementiert eine Zeile einer Listenansicht
 *
 * @param publicationSearchPageRepresentationModel - Darstellungsmodell der Publikation-Suchseite
 * @param selected - Bestimmt, ob der Datensatz als "ausgewählt" markiert werden soll
 * @param onSelect - Hochreichen der Aktion, die ausgelöst wurde, als der Datensatz angeklickt wurde
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchListViewItem = ({publicationSearchPageRepresentationModel, selected, onSelect}) => {
    const {t} = useTranslation();
    const status = publicationSearchPageRepresentationModel.get('status');
    const publicationStatus = PublicationStatus.getPublicationStatus(status);

    return (
        <tr
            className={"clearfix " + (selected ? "active" : "")}
            onClick={() => onSelect(publicationSearchPageRepresentationModel)}
        >
            <td className={"col-1"}>{publicationSearchPageRepresentationModel.get('publicationId')}</td>
            <td className={"col-4"}>{publicationSearchPageRepresentationModel.get('publicationName')}</td>
            <td className={"col-4"}>{publicationSearchPageRepresentationModel.get('templateName')}</td>
            <td className={"col-3"}>{t(publicationStatus.messageKey())}</td>
        </tr>
    )
}

export default PublicationSearchListViewItem;