import React from "react";
import {NoResults, PublicationSearchListView, PublicationSearchTileView} from "components";

/**
 * Defaultmodus
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodelle der Publikation-Suchseite
 * @param {View} view - Ansicht
 * @returns {JSX.Element}
 * @constructor
 */
const PublicationSearchDefaultViewMode = ({publicationSearchPageRepresentationModels, view}) => {

    if (!Array.isArray(publicationSearchPageRepresentationModels) || publicationSearchPageRepresentationModels.length === 0) {
        return (
            <NoResults/>
        );
    }

    return (
        <>
            {view.isTileView() ?
                <PublicationSearchTileView
                    publicationSearchPageRepresentationModels={publicationSearchPageRepresentationModels}
                /> :
                <PublicationSearchListView
                    publicationSearchPageRepresentationModels={publicationSearchPageRepresentationModels}
                    listView={view}
                />
            }
        </>
    )
}

export default PublicationSearchDefaultViewMode;