import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Constants from "common/Constants";
import PublicationViewMode from "common/publication/PublicationViewMode";
import View from "common/View";
import {Toolbox} from "components";

/**
 * Publikationen-Toolbox
 *
 * @param {Function} onModusSelected - Callback um Modus zu aktivieren
 * @param {Object} ref Reference
 * @returns {JSX.Element}
 * @constructor
 */
const _PublicationSearchToolbox = ({onModusSelected}, ref) => {

    const {t} = useTranslation();
    const [activeModus, setActiveModus] = useState(null);

    useImperativeHandle(ref, () => ({
        exitToolBox() {
            setActiveModus(null);
        }
    }));

    const toolboxModus = [
        {
            title: t('publication.search.tool.box.archivier.mode'),
            iconCssClass: 'fa-archive',
            key: PublicationViewMode.MODE.ARCHIVE
        },
        {
            title: t('publication.search.tool.box.delete.mode'),
            iconCssClass: 'fa-trash-alt',
            key: PublicationViewMode.MODE.DELETE

        },
        {
            title: t('publication.search.tool.box.download.mode'),
            iconCssClass: 'fa-download',
            key: PublicationViewMode.MODE.DOWNLOAD
        }
    ];

    const toolboxModusSelected = (modus) => {
        setActiveModus(modus);

        switch (modus.key) {
            case PublicationViewMode.MODE.ARCHIVE:
                onModusSelected({
                    pageSize: Constants.INTEGER_MAX_VALUE,
                    view: new View(View.TYPE.LIST, View.SELECTION_TYPE.MULTI),
                    publicationViewMode: PublicationViewMode.MODE.ARCHIVE
                });
                break;
            case PublicationViewMode.MODE.DELETE:
                onModusSelected({
                    pageSize: Constants.INTEGER_MAX_VALUE,
                    view: new View(View.TYPE.LIST, View.SELECTION_TYPE.MULTI),
                    publicationViewMode: PublicationViewMode.MODE.DELETE
                });
                break;
            case PublicationViewMode.MODE.DOWNLOAD:
                onModusSelected({
                    pageSize: Constants.INTEGER_MAX_VALUE,
                    view: View.DEFAULT_LIST_VIEW,
                    publicationViewMode: PublicationViewMode.MODE.DOWNLOAD
                });
                break;
            default:
                throw new Error("Unbekannter Modus: " + modus.key);
        }
    };

    return (
        <Col xs={12} md={6} lg={12}>
            <Toolbox
                items={toolboxModus}
                activeKey={activeModus ? activeModus.key : null}
                toolboxItemSelected={toolboxModusSelected}
            />
        </Col>
    );
}

const PublicationSearchToolbox = forwardRef(_PublicationSearchToolbox);

export default PublicationSearchToolbox;