import {Help} from "components";
import * as React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from 'react-i18next';

const Footer = ({footerModel}) => {
    const {t} = useTranslation();
    const helpHref = footerModel.helpLink?.getHref();

    return (
        <>
            <Help helpHref={helpHref}/>
            <footer className={"mt-lg-0 mt-auto"}>
                <Container className={"px-4"}>
                    <Row>
                        <Col className={"lower-footer"}>
                            <Row>
                                <Col>
                                    <a href={t('imprintLink')}>{t('impressum')}</a>
                                </Col>
                                <Col className={"text-end"}>
                                    <p>
                                        v.&nbsp;{t('info.build.version')}&nbsp;|&nbsp;Powered by&nbsp;
                                        <a href="https://www.geneon.de">
                                            Geneon GmbH
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;