import Api from "common/Api";
import {
    AlertApiError,
    FilterGroup,
    LoadingOverlayIndicator,
    OrganizationSelect,
    TemplatePublicationMetaData
} from "components";
import {TemplateDefaultImg} from "image";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Image, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const TemplatesEventInfo = ({templatesEventData}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isCreatingEventPublications, setIsCreatingEventPublications] = useState(false);
    const [createEventPublicationsButtonState, setCreateEventPublicationsButtonState] = useState({
        hasPublicationName: true,
        hasOrganizationId: true,
        hasTemplateEventTypes: false,
        hasEventParticipantsFile: false
    });
    const [canCreateEventPublications, setCanCreateEventPublications] = useState(false);
    const [eventPublicationCreateSpecification, setEventPublicationCreateSpecification] = useState(
        {
            publicationName: templatesEventData.templatesEventName,
            organizationId: null,
            templateEventTypes: [],
            eventParticipantsFile: null
        }
    );
    const [eventTypes, setEventTypes] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        if (templatesEventData.templateEventTypesLink) {
            Api.get(templatesEventData.templateEventTypesLink)
                .then(representationModel => {
                    setEventTypes(representationModel.get("templateEventTypes"));
                })
                .catch(apiError => setApiError(apiError));
        }

    }, [templatesEventData.templateEventTypesLink]);

    const templatePublicationMetaData = {
        publicationName: templatesEventData.templatesEventName
    }

    const _changeCreateEventPublicationsButtonState = (hasPublicationName, hasOrganizationId, hasTemplateEventTypes, hasEventParticipantsFile) => {
        setCreateEventPublicationsButtonState({
            hasPublicationName: hasPublicationName,
            hasOrganizationId: hasOrganizationId,
            hasTemplateEventTypes: hasTemplateEventTypes,
            hasEventParticipantsFile: hasEventParticipantsFile
        })
        setCanCreateEventPublications(hasPublicationName && hasOrganizationId && hasTemplateEventTypes && hasEventParticipantsFile);
    }

    const _onTemplatePublicationMetaDataChange = (templatePublicationMetaData) => {
        setEventPublicationCreateSpecification({
                ...eventPublicationCreateSpecification,
                publicationName: templatePublicationMetaData.form.publicationName
            }
        );
        _changeCreateEventPublicationsButtonState(
            !templatePublicationMetaData.hasError,
            createEventPublicationsButtonState.hasOrganizationId,
            createEventPublicationsButtonState.hasTemplateEventTypes,
            createEventPublicationsButtonState.hasEventParticipantsFile
        );
    };

    const _onOrganizationChange = (organizationId) => {
        setEventPublicationCreateSpecification(prevState => ({
            ...prevState,
            organizationId: organizationId
        }));
        _changeCreateEventPublicationsButtonState(
            createEventPublicationsButtonState.hasPublicationName,
            organizationId !== null,
            createEventPublicationsButtonState.hasTemplateEventTypes,
            createEventPublicationsButtonState.hasEventParticipantsFile
        );
    };

    const _activeOptionValues = (options) => {
        return options
            .filter(option => option.status === 'active')
            .map(option => option.key);
    };

    const _onEventTypeChange = (eventTypes) => {
        setEventTypes(eventTypes);
        const selectedEventTypeKeys = _activeOptionValues(eventTypes);
        if (Array.isArray(selectedEventTypeKeys) || selectedEventTypeKeys.length > 0) {
            setEventPublicationCreateSpecification({
                    ...eventPublicationCreateSpecification,
                    templateEventTypes: selectedEventTypeKeys
                }
            );
        }
        _changeCreateEventPublicationsButtonState(
            createEventPublicationsButtonState.hasPublicationName,
            createEventPublicationsButtonState.hasOrganizationId,
            Array.isArray(selectedEventTypeKeys) && selectedEventTypeKeys.length > 0,
            createEventPublicationsButtonState.hasEventParticipantsFile
        );
    };

    const _onFileChange = (event) => {
        if (event.target.files.length === 1) {
            setSelectedFile(event.target.files[0]);
            _toBase64(event.target.files[0], (base64String) => {
                setEventPublicationCreateSpecification({
                        ...eventPublicationCreateSpecification,
                        // UploadedFileResource
                        eventParticipantsFile: {
                            filename: event.target.files[0].name,
                            dataBase64: base64String
                        }
                    }
                );
            });
        }
        _changeCreateEventPublicationsButtonState(
            createEventPublicationsButtonState.hasPublicationName,
            createEventPublicationsButtonState.hasOrganizationId,
            createEventPublicationsButtonState.hasTemplateEventTypes,
            event.target.files.length === 1
        );
    };

    const _toBase64 = (file, callback) => {
        let arrayBufferToBase64 = function (buffer) {
            let binary = '';
            let bytes = new Uint8Array(buffer);
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return btoa(binary);
        };

        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function () {
            let base64String = arrayBufferToBase64(reader.result);
            callback(base64String)
        };
        reader.onerror = function (error) {
            setApiError(error);
        };
    }

    const _getInvalidMessage = () => {
        if (selectedFile) {
            if (selectedFile.size <= 0) {
                return (
                    t('error.publication.event.xlsFilenameIsBlank')
                );
            }
            if (selectedFile.type !== "application/vnd.ms-excel") {
                return (
                    t('error.publication.event.xlsContentTypeInvalid')
                );
            }
        } else {
            return (
                t('error.publication.event.no.file.selected')
            );
        }
    };

    const _createEventPublications = () => {
        if (templatesEventData.createEventPublicationsLink && canCreateEventPublications) {
            setIsCreatingEventPublications(true);
            Api.post(templatesEventData.createEventPublicationsLink, eventPublicationCreateSpecification)
                .then(representationModel => {
                    let searchText = representationModel.get("publicationIds").toString();
                    console.log('publicationIds', searchText)
                    // TODO Publikationen anzeigen (filtern), die grade angelegt wurden
                    navigate('/publication-search.html');
                    setTimeout(() => {
                        setIsCreatingEventPublications(false);
                    }, 2000);
                })
                .catch(apiError => {
                    setApiError(apiError);
                    setIsCreatingEventPublications(false);
                });
        }
    }

    return (
        <>
            {apiError &&
                <AlertApiError apiError={apiError}/>
            }

            <LoadingOverlayIndicator
                show={isCreatingEventPublications}
                message={t('Template.event.creating.process')}
            />

            <Row className={"template-info px-4 py-4 gap-4"}>
                <Col xs={12}>
                    <Form onSubmit={event => event.preventDefault()}>
                        <Row className={"g-5"}>
                            <Col xs={12} md={12} lg={4}>
                                <p className={"thumbnail-wrapper img-thumbnail"}>
                                    <Image
                                        fluid
                                        className={"image-shadow"}
                                        src={TemplateDefaultImg /* TODO BAMEDSC-3970 */}
                                    />
                                </p>
                            </Col>
                            <Col xs={12} md={12} lg={5} className={"form-col"}>
                                <TemplatePublicationMetaData
                                    templatePublicationMetaData={templatePublicationMetaData}
                                    onTemplatePublicationMetaDataChange={_onTemplatePublicationMetaDataChange}
                                />
                                <OrganizationSelect
                                    organizationsLink={templatesEventData.possibleCurrentUserOrganizationsLink}
                                    onChange={_onOrganizationChange}
                                />
                                {eventTypes &&
                                    <FilterGroup
                                        title={t('Template.event.type')}
                                        items={eventTypes}
                                        onChange={_onEventTypeChange}
                                    />
                                }
                                <Form.Group className={"mb-3"}>
                                    <Form.Label htmlFor={"fileUpload"}>
                                        {t('Template.event.address')}
                                    </Form.Label>
                                    <Form.Control
                                        type={"file"}
                                        id={"fileUpload"}
                                        name={"fileUpload"}
                                        onChange={event => _onFileChange(event)}
                                        isInvalid={!selectedFile || selectedFile.size <= 0 || selectedFile.type !== "application/vnd.ms-excel"}
                                    />
                                    <Form.Control.Feedback type={"invalid"}>
                                        {_getInvalidMessage()}
                                    </Form.Control.Feedback>
                                    <Form.Text>
                                        {templatesEventData.eventParticipantsExampleLink?.getHref() &&
                                            <a href={templatesEventData.eventParticipantsExampleLink.getHref()}
                                               target={"_blank"}>
                                                {t('Template.event.address.example.list')}
                                            </a>
                                        }
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={3}>
                                <p className={"text-end"}>
                                    <Button
                                        variant={"primary"}
                                        size={"lg"}
                                        className={"create-publication"}
                                        disabled={isCreatingEventPublications || !canCreateEventPublications}
                                        onClick={() => _createEventPublications()}
                                    >
                                        {t('template.details.create.publication.button.label')}
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default TemplatesEventInfo;