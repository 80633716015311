export default class Sort {
    /**
     * Zur Verfügung stehende Sortierungen.
     * Zur Auswahl der Sortierung mittels {@link SortSelect} muss für jeden Key auch ein entsprechender
     * message.properties Eintrag vorhanden sein.
     *
     * @type {Object}
     */
    static OPTIONS = {
        'sort.by.date.desc': {changeDate: {direction: 'DESC'}},
        'sort.by.name.asc': {name: {direction: 'ASC'}},
        'sort.by.name.desc': {name: {direction: 'DESC'}}
    };

    /**
     * Initiale Sortierung für {@link PublicationSearch}
     *
     * @type {string}
     */
    static PUBLICATION_DEFAULT = 'sort.by.date.desc';

    /**
     * Initiale Sortierung für {@link TemplateSearch}
     *
     * @type {string}
     */
    static TEMPLATE_DEFAULT = 'sort.by.name.asc';

    /**
     * Keys aller zur Verfügung stehenden Sortierungen zurückgeben.
     *
     * @returns {string[]}
     */
    static sortKeys() {
        return Object.keys(Sort.OPTIONS);
    }

    /**
     * Ermittelt die Sortierung zu einem Key.
     *
     * @param key Key der gesuchten Sortierung
     * @returns {Object} Sortierung
     */
    static sortValueOf(key) {
        return Sort.OPTIONS[key];
    }
}