import Api from "common/Api";
import {activeFilterValues, initialFilter} from "common/FilterUtils";
import {AlertApiError, SearchAndFilterView} from "components";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const NewsSearchAndFilter = ({newsSearchFiltersLink, initialNewsPostState, onChange}) => {
    const {t} = useTranslation();

    const [searchText, setSearchText] = useState('');

    const [apiError, setApiError] = useState(null);
    const [newsPostStateFilters, setNewsPostStateFilters] = useState([]);

    useEffect(() => {
        if (newsSearchFiltersLink) {
            Api.get(newsSearchFiltersLink)
                .then(searchFilters => {
                    setNewsPostStateFilters(initialFilter(searchFilters.get("newsPostStates"), initialNewsPostState));
                })
                .catch(apiError => setApiError(apiError));
        }

    }, [newsSearchFiltersLink, initialNewsPostState]);

    const handleFiltersChange = (searchText, newsPostStateFilters) => {
        onChange({
            searchText: searchText,
            newsPostStates: activeFilterValues(newsPostStateFilters)
        });
    }

    const handleSearchTextChange = (searchText) => {
        setSearchText(searchText);
    };

    const handleSearchTextSubmitted = (searchText) => {
        setSearchText(searchText);
        handleFiltersChange(searchText, newsPostStateFilters);
    };

    const handleNewsPostStateFiltersChange = (newsPostStateFilters) => {
        setNewsPostStateFilters(newsPostStateFilters);
        handleFiltersChange(searchText, newsPostStateFilters);
    };

    if (apiError) {
        return (<AlertApiError apiError={apiError}/>);
    }

    const search = {
        placeHolderText: t('news.search.field.place.holder'),
        searchText: searchText,
        onChange: handleSearchTextChange,
        onSubmit: handleSearchTextSubmitted
    };

    const filterGroups = [
        {
            title: t('news.search.filter.group.news.post.state.title'),
            items: newsPostStateFilters,
            onChange: handleNewsPostStateFiltersChange
        }
    ];

    return (
        <SearchAndFilterView
            search={search}
            filterGroups={filterGroups}
        />
    );
};

export default NewsSearchAndFilter;