import {
    BusinessCardsImg,
    EditorialStaffImg,
    EmployerNewsletterImg,
    EventsImg,
    ImagePoolImg,
    SpreadPublicationsImg
} from "image";
import * as React from "react";
import {Col} from "react-bootstrap";
import {LinksCard, LinksRow} from "components";
import {useTranslation} from "react-i18next";

/**
 * Komponente für die Anzeige von Link-Kacheln in Spalten
 *
 * @param {Object} linksModel - Links zu Newsletter, Redaktion, Visitenkarten, Bilderpool und verteilten Werbemitteln
 * @return {JSX.Element}
 * @constructor
 */
const LinksCol = ({linksModel}) => {

    const {t} = useTranslation();

    const links = [];
    if (linksModel.links.newsletterLink) {
        links.push({
            name: t('indexLayout.newsletterBox.prompt'),
            href: linksModel.links.newsletterLink.getHref(),
            image: (linksModel.systemSettingsHomepageImagesModel ? linksModel.systemSettingsHomepageImagesModel.homepageNewsletterImageFile : null) || EmployerNewsletterImg
        });
    }

    if (linksModel.links.editorialLink) {
        links.push({
            name: t('redaktion'),
            href: linksModel.links.editorialLink.getHref(),
            image: (linksModel.systemSettingsHomepageImagesModel ? linksModel.systemSettingsHomepageImagesModel.homepageEditorialImageFile : null) || EditorialStaffImg
        });
    }

    if (linksModel.links.visitingCardLink) {
        links.push({
            name: t('indexLayout.visitingCardBox.prompt'),
            href: linksModel.links.visitingCardLink.getHref(),
            image: (linksModel.systemSettingsHomepageImagesModel ? linksModel.systemSettingsHomepageImagesModel.homepageVisitingCardsImageFile : null) || BusinessCardsImg
        });
    }

    if (linksModel.links.templatesEventDetailsLink) {
        links.push({
            name: t('publication.list.events'),
            href: linksModel.links.templatesEventDetailsLink.getLinkPathForRouting(),
            image: (linksModel.systemSettingsHomepageImagesModel ? linksModel.systemSettingsHomepageImagesModel.homepageEventsImageFile : null) || EventsImg
        });
    }

    if (linksModel.links.imagePoolLink) {
        links.push({
            name: t('Index.organization.image'),
            href: linksModel.links.imagePoolLink.getHref(),
            image: (linksModel.systemSettingsHomepageImagesModel ? linksModel.systemSettingsHomepageImagesModel.homepageImagesImageFile : null) || ImagePoolImg
        });
    }

    if (linksModel.links.spreadPublicationSearchLink) {
        let badgeText;
        if (linksModel.unreadSpreadPublicationsCount > 0) {
            badgeText = linksModel.unreadSpreadPublicationsCount.toString();
        }

        links.push({
            name: t('Index.spread.publication'),
            badgeText: badgeText,
            href: linksModel.links.spreadPublicationSearchLink.getLinkPathForRouting(),
            image: (linksModel.systemSettingsHomepageImagesModel ? linksModel.systemSettingsHomepageImagesModel.homepageSpreadPublicationsImageFile : null) || SpreadPublicationsImg
        });
    }

    return (
        <Col className={"tile single"}>
            <LinksRow>
                {links.map((link, index) => (
                    <Col key={index}>
                        <LinksCard {...link}/>
                    </Col>
                ))}
            </LinksRow>
        </Col>
    );
};

export default LinksCol;