import React from "react";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SpreadPublicationListViewItem} from "components";

/**
 * Implementiert eine Listenansicht für die verteilte Publikationen.
 *
 * @param publicationSearchPageRepresentationModels - Darstellungsmodelle der verteilten Publikationen
 * @param {View} listView - Listenansicht
 * @param  selectedSpreadPublicationIds - Ausgewählte Ids der Verteilung der Publikation
 * @param  handleSelect - Auswahl behandeln, wenn notwendig
 * @returns {JSX.Element}
 * @constructor
 */
const SpreadPublicationListView = ({
                                       spreadPublicationSearchPageRepresentationModels,
                                       listView,
                                       selectedSpreadPublicationIds = [],
                                       handleSelect
                                   }) => {
    const {t} = useTranslation();
    const selectionCssClass = listView.getViewSelectionCssClass();

    return (
        <Table responsive className={"table-fixed table-hover pub-list " + selectionCssClass}>
            <thead>
            <tr className={"clearfix"}>
                <th className={"col-2"}>{t('Index.spread.publication.list.created.date')}</th>
                <th className={"col-5"}>{t('publication.title')}</th>
                <th className={"col-4"}>{t('publication.list.template')}</th>
                <th className={"col-1"}>{t('publication.list.status')}</th>
            </tr>
            </thead>
            <tbody>
            {spreadPublicationSearchPageRepresentationModels.map(spreadPublicationSearchPageRepresentationModel => (
                <SpreadPublicationListViewItem
                    key={spreadPublicationSearchPageRepresentationModel.get('spreadPublicationId')}
                    spreadPublicationSearchPageRepresentationModel={spreadPublicationSearchPageRepresentationModel}
                    selected={selectedSpreadPublicationIds.includes(spreadPublicationSearchPageRepresentationModel.get('spreadPublicationId'))}
                    onSelect={handleSelect}
                />
            ))}
            </tbody>
        </Table>
    )
}

export default SpreadPublicationListView;